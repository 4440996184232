import {
	ArticleStatus,
	MapType,
	ModelTypes,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { InternalLinkFragment } from './InternalLinkFragment'
import { LinkFragment } from './LinkFragment'

export const ContentReferenceNewsItemFragment = () =>
	ZeusSelect<ValueTypes['ContentReferenceNewsItem']>()({
		id: true,
		item: [
			{},
			{
				id: true,
				type: true,
				title: true,
				publishedAt: true,
				link: [{}, LinkFragment()],
				linkLabel: true,
				article: [
					{
						filter: {
							status: {
								eq: ArticleStatus.published,
							},
						},
					},
					{
						link: [{}, InternalLinkFragment()],
						status: true,
					},
				],
			},
		],
	})

export type ContentReferenceNewsItemResult = MapType<
	ModelTypes['ContentReferenceNewsItem'],
	ReturnType<typeof ContentReferenceNewsItemFragment>
>
