import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { GridCardListResult } from '../data/content/GridCardListFragment'
import styles from './GridCardListTestemonial.module.sass'
import { ImageSizeByArea } from './ImageSizeByArea'

export type GridCardListTestemonialsProps = GridCardListResult

export const GridCardListTestemonials: FunctionComponent<GridCardListTestemonialsProps> = ({
	items,
}) => {
	const columns: GridCardListResult['items'][number][][] = [[], [], [], []]

	items.forEach((item, index) => {
		columns[index % columns.length].push(item)
	})
	const iconMaxArea = 50
	return (
		<div className={styles.gridCardList}>
			{columns.map((column, i) => (
				<div key={i} className={styles.column}>
					{column.map((item) => (
						<div
							className={clsx(styles.gridCard, item.isMobileHidden && styles.is_mobileHidden)}
							key={item.id}>
							{item.image && (
								<div
									style={{
										'--GridCardTestemonial-icon-size': `${iconMaxArea}px`,
									}}>
									<ImageSizeByArea image={item.image} imageMaxArea={iconMaxArea} />
								</div>
							)}
							<p className={styles.quote}>“{item.quote}“</p>

							<p className={styles.info}>{item.name}</p>
							<p className={clsx(styles.info, styles.opacity)}>{item.position}</p>
							<p className={clsx(styles.info, styles.opacity)}>{item.company}</p>
						</div>
					))}
				</div>
			))}
		</div>
	)
}
