import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent, ReactNode } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { lazyBoundary } from '../images/configuration'
import styles from './AuthorBadge.module.sass'

export type AuthorBadgeProps = {
	type: 'banner' | 'default'
	image?: ImageResult
	name?: ReactNode
	companyName?: string
}

export const AuthorBadge: FunctionComponent<AuthorBadgeProps> = ({
	type,
	image,
	name,
	companyName,
}) => {
	if (type === 'banner') {
		return (
			<div className={styles.wrapper}>
				<div className={styles.main}>
					{image && (
						<div className={styles.image}>
							<Image
								lazyBoundary={lazyBoundary}
								src={image.url}
								layout="fill"
								objectFit="cover"
								alt={image.alt ?? ''}
								sizes="3.125rem"
							/>
						</div>
					)}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{name && <span className={styles.name}>{name}</span>}
						{companyName && <span className={clsx(styles.companyName)}>{companyName}</span>}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				{image && (
					<div className={styles.image}>
						<Image
							lazyBoundary={lazyBoundary}
							src={image.url}
							layout="fill"
							objectFit="cover"
							alt={image.alt ?? ''}
							sizes="3.125rem"
						/>
					</div>
				)}
				<div>
					{name && <span className={styles.name}>{name}</span>}
					{companyName && <span className={styles.companyName}>{companyName}</span>}
				</div>
			</div>
		</div>
	)
}
