import { FunctionComponent, useState } from 'react'
import type { AccordionResult } from '../data/content/AccordionFragment'
import styles from './AsideContentAccordion.module.sass'
import { AsideContentAccordionItem } from './AsideContentAccordionItem'

export type AsideContentAccordionProps = AccordionResult

export const AsideContentAccordion: FunctionComponent<AsideContentAccordionProps> = ({ items }) => {
	const [isItemOpenId, setItemOpenId] = useState<AccordionResult['items'][number]['id'] | null>(
		items[0].id
	)
	return (
		<div className={styles.wrapper}>
			<div className={styles.list}>
				{items.map((item) => (
					<div key={item.id} className={styles.item}>
						<AsideContentAccordionItem
							{...item}
							isItemOpenId={isItemOpenId}
							setItemOpenId={setItemOpenId}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
