import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { PricingFAQTileItemFragment } from './PricingFAQTileItemFragment'

export const PricingFAQListFragment = () =>
	ZeusSelect<ValueTypes['FAQPricingList']>()({
		items: [{}, PricingFAQTileItemFragment()],
	})

export type PricingFAQListResult = MapType<
	ModelTypes['FAQPricingList'],
	ReturnType<typeof PricingFAQListFragment>
>
