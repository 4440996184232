import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { LinkFragment } from './LinkFragment'

export const LinkListFragment = () =>
	ZeusSelect<ValueTypes['LinkList']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			{
				id: true,
				link: [{}, LinkFragment()],
			},
		],
	})

export type LinkListResult = MapType<ModelTypes['LinkList'], ReturnType<typeof LinkListFragment>>
