import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const VideoFragment = () =>
	ZeusSelect<ValueTypes['Video']>()({
		src: true,
		width: true,
		height: true,
		size: true,
		type: true,
		duration: true,
		poster: [{}, ImageFragment()],
	})

export type VideoResult = MapType<ModelTypes['Video'], ReturnType<typeof VideoFragment>>
