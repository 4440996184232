import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { IconFragment } from './IconFragment'

export const BannerFeaturesFragment = () =>
	ZeusSelect<ValueTypes['BannerFeatures']>()({
		items: [
			{ orderBy: [{ order: OrderDirection.asc }] },
			{
				id: true,
				mainTitle: true,
				title: true,
				icon: IconFragment(),
			},
		],
	})

export type BannerFeaturesResult = MapType<
	ModelTypes['BannerFeatures'],
	ReturnType<typeof BannerFeaturesFragment>
>
