import Image from 'next/legacy/image'
import { Fragment, FunctionComponent } from 'react'
import { MediumType } from '../../generated/content'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { lazyBoundary } from '../images/configuration'
import styles from './AsideContentMediumList.module.sass'
import { WistiaEmbedPlayer } from './WistiaEmbedPlayer'

export type AsideContentMediumListProps = MediumListResult

export const AsideContentMediumList: FunctionComponent<AsideContentMediumListProps> = ({
	items,
}) => {
	return (
		<div className={styles.wrapper}>
			{items.length > 0 && (
				<div className={styles.list}>
					{items.map((item) => (
						<Fragment key={item.id}>
							{item.medium?.type === MediumType.image && item.medium.image ? (
								<div
									className={styles.item}
									style={{
										'--AsideContentMediumList-media-width': item.medium.image.width,
										'--AsideContentMediumList-media-height': item.medium.image.height,
									}}>
									<Image
										lazyBoundary={lazyBoundary}
										src={item.medium.image.url}
										layout="fill"
										objectFit="cover"
										alt={item.medium.image.alt ?? ''}
										sizes="(min-width: 75rem) 35rem, (min-width: 38rem) 50vw, 100vw"
									/>
								</div>
							) : item.medium?.type === MediumType.video && item.medium.wistiaID ? (
								<div
									className={styles.item}
									style={{
										'--AsideContentMediumList-media-width': item?.medium?.image?.width,
										'--AsideContentMediumList-media-height': item?.medium?.image?.height,
									}}>
									<WistiaEmbedPlayer
										videoId={item.medium.wistiaID}
										wrapper={`wistia-${item.medium.wistiaID}`}
									/>
								</div>
							) : null}
						</Fragment>
					))}
				</div>
			)}
		</div>
	)
}
