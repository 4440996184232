import {
	ArticleStatus,
	MapType,
	ModelTypes,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { InternalLinkFragment } from './InternalLinkFragment'
import { LinkFragment } from './LinkFragment'

export const NewsItemFragment = () =>
	ZeusSelect<ValueTypes['NewsItem']>()({
		type: true,
		title: true,
		link: [{}, LinkFragment()],
		publishedAt: true,
		linkLabel: true,
		article: [
			{
				filter: {
					status: {
						eq: ArticleStatus.published,
					},
				},
			},
			{
				status: true,
				link: [{}, InternalLinkFragment()],
			},
		],
	})

export type NewsItemResult = MapType<ModelTypes['NewsItem'], ReturnType<typeof NewsItemFragment>>
