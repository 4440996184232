import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './Cursor.module.sass'
import { Icon } from './Icon'

export type CursorProps = {
	isResting: boolean
}

export const Cursor: FunctionComponent<CursorProps> = ({ isResting }) => {
	return (
		<div className={clsx(styles.wrapper, isResting && styles.is_resting)}>
			<Icon name="cursor" />
		</div>
	)
}
