import type { FunctionComponent, ReactNode } from 'react'
import styles from './GemmaFrame.module.sass'

export interface GemmaFrameProps {
	children: ReactNode
	backgroundProgress: number
}

export const GemmaFrame: FunctionComponent<GemmaFrameProps> = ({
	children,
	backgroundProgress,
}) => {
	return (
		<div
			className={styles.wrapper}
			style={{
				'--GemmaFrame-background-progress': backgroundProgress,
			}}>
			<div className={styles.in}>{children}</div>
		</div>
	)
}
