import { FunctionComponent, useMemo } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { InspirationLayout, InspirationLayoutProps } from './InspirationLayout'
import { Inspirations } from './Inspirations'

export type OriginalsPageProps = {
	inspirationLinks: InspirationLayoutProps
	data: NonNullable<CommonPageProps['page']['originalsPage']>
}

export const OriginalsPage: FunctionComponent<OriginalsPageProps> = ({
	inspirationLinks,
	data: { inspirations, featuredInspiration },
}) => {
	const items = useMemo(
		() => inspirations.map((inspiration) => ({ data: inspiration })),
		[inspirations]
	)

	return (
		<InspirationLayout {...inspirationLinks}>
			<Inspirations items={items} featuredItem={featuredInspiration} />
		</InspirationLayout>
	)
}
