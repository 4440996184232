import { useCallback } from 'react'
import { useLocalLoading } from 'shared-loading-indicator'

export const useAddLike = (inspirationId: string) => {
	const [isLoading, setIsLoading] = useLocalLoading()

	const addLike = useCallback(async () => {
		setIsLoading(true)
		await Promise.all([
			fetch('/api/v1/add-like', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ inspirationId }),
			}),
			new Promise((resolve) => setTimeout(resolve, 1000)), // Trottle
		])
		setIsLoading(false)
	}, [inspirationId, setIsLoading])

	return { isLoading, addLike }
}
