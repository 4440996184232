import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'next-share'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { useWindowSize } from 'react-use'
import { Container } from './Container'
import styles from './ReadingTime.module.sass'

export interface ReadingTimeProps {
	primaryText?: string
	secondaryText?: string
}

export const ReadingTime: FunctionComponent<ReadingTimeProps> = ({ ...props }) => {
	const router = useRouter()
	const { width } = useWindowSize()

	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				{width > 608 && (
					<div className={styles.infoBox}>
						{props.primaryText && <p className={styles.title}>{props.primaryText}</p>}
						{props.secondaryText && (
							<p className={styles.readingTime}>Author: {props.secondaryText} </p>
						)}
					</div>
				)}
				<div className={styles.socialShare}>
					<FacebookShareButton url={`https://ceros.com${router.asPath}`}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none">
							<rect width="40" height="40" rx="8" fill="black" />
							<circle cx="20" cy="18" r="12" fill="white" />
							<path
								d="M19.9998 6C13.3725 6 8 11.3924 8 18.0441C8 23.6923 11.8745 28.4319 17.1011 29.7336V21.7248H14.6267V18.0441H17.1011V16.4581C17.1011 12.3588 18.9495 10.4587 22.9594 10.4587C23.7197 10.4587 25.0315 10.6085 25.5681 10.7579V14.0941C25.2849 14.0642 24.7929 14.0493 24.1819 14.0493C22.2144 14.0493 21.4541 14.7975 21.4541 16.7424V18.0441H25.3737L24.7003 21.7248H21.4541V30C27.3959 29.2798 32 24.202 32 18.0441C31.9995 11.3924 26.627 6 19.9998 6Z"
								fill="#0A0A0E"
							/>
						</svg>
					</FacebookShareButton>
					<LinkedinShareButton url={`https://ceros.com${router.asPath}`}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none">
							<rect width="40" height="40" rx="8" fill="black" />
							<path
								d="M20.9723 23.5389C20.9723 19.4028 26.2921 19.057 26.2921 23.5389V30.9866H30.2819V21.9962C30.2819 15.0139 22.8342 15.2666 20.9723 18.6979V16.3439H16.9824V30.9733H20.9723V23.5256V23.5389Z"
								fill="white"
							/>
							<path d="M14.3219 16.3705H10.332V31H14.3219V16.3705Z" fill="white" />
							<path
								d="M12.3274 14.6814C13.6175 14.6814 14.6548 13.6308 14.6548 12.3407C14.6548 11.0507 13.6175 10 12.3274 10C11.0374 10 10 11.0374 10 12.3274C10 13.6175 11.0374 14.6681 12.3274 14.6681V14.6814Z"
								fill="white"
							/>
						</svg>
					</LinkedinShareButton>
					<TwitterShareButton url={`https://ceros.com${router.asPath}`}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none">
							<rect width="40" height="40" rx="8" fill="black" />
							<path
								d="M21.9027 18.4686L29.3482 10H27.5838L21.119 17.3532L15.9555 10H10L17.8082 21.1193L10 30H11.7644L18.5915 22.2348L24.0445 30H30L21.9023 18.4686H21.9027ZM19.4861 21.2173L18.695 20.1101L12.4002 11.2997H15.1102L20.1902 18.4099L20.9813 19.5172L27.5847 28.7594H24.8746L19.4861 21.2177V21.2173Z"
								fill="white"
							/>
						</svg>
					</TwitterShareButton>
				</div>
			</div>
		</Container>
	)
}
