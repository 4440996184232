import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import { LinkType } from '../../generated/content'
import type { ResourceResult } from '../data/content/ResourceFragment'
import { lazyBoundary } from '../images/configuration'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { CardTile } from './CardTile'
import { CardTileContent, CardTileContentLink } from './CardTileContent'
import { Container } from './Container'
import styles from './ResourceBox.module.sass'
import { ResourceCardTileTags } from './ResourceCardTile'

export type ResourceBoxProps = ResourceResult

export const ResourceBox: FunctionComponent<ResourceBoxProps> = ({
	title,
	link,
	leadParagraph,
	cardBigImage,
	categories,
}) => {
	return (
		<Container>
			<div className={styles.box}>
				<CardTileContentLink link={link} type="resourceBox">
					<div className={styles.card}>
						<CardTile>
							<CardTileContent
								title={title}
								image={cardBigImage}
								text={leadParagraph}
								subtitleChildren={<ResourceCardTileTags categories={categories} />}
								footerChildren={
									link && (
										<div className={styles.button}>
											<Button
												type="link"
												href={contemberLinkToHref(link)}
												fill="blue"
												target={
													link.type !== LinkType.scrollToTarget && link.isTargetBlank
														? '_blank'
														: undefined
												}>
												{link.title || 'link title'}
											</Button>
										</div>
									)
								}
								isImageVisibleOnlyOnMobile={true}
							/>
						</CardTile>
					</div>
					<div className={styles.background}>
						{cardBigImage && (
							<Image
								lazyBoundary={lazyBoundary}
								src={cardBigImage.url}
								layout="fill"
								objectFit="cover"
								alt={cardBigImage.alt ?? ''}
							/>
						)}
					</div>
				</CardTileContentLink>
			</div>
		</Container>
	)
}
