import clsx from 'clsx'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useWindowScroll, useWindowSize } from 'react-use'
import { useIsInView } from '../utilities/useIsInView'
import { Container } from './Container'
import styles from './HumblebragsFour.module.sass'
import { Modal } from './Modal'

interface IframeModalProps {
	isModalOpen: boolean
	setIsModalOpen: Dispatch<SetStateAction<boolean>>
	screenWidth: number
	frameSrc: string
}

export const HumblebragsFour = () => {
	const { width } = useWindowSize()

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [frameSrc, setFrameSrc] = useState<string>('')

	const { y: scrollY } = useWindowScroll()
	const [rotationDegrees, setRotationDegrees] = useState<number>(0)

	const starRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(starRef, {
		threshold: 0.1,
	})

	// Iframe Trigger
	const openModal = (iframeId: number) => {
		setIsModalOpen(true)
		const url = 'https://view.ceros.com/ceros-collateral/before-and-after-ceros-content-1/p/'
		const urlToDisplay = url + iframeId
		setFrameSrc(urlToDisplay)
	}

	// Effects
	useEffect(() => {
		setRotationDegrees(scrollY * 0.75)
	}, [scrollY])

	return (
		<Container size={'normal'}>
			<IframeModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				screenWidth={width}
				frameSrc={frameSrc}
			/>
			<div className={styles.parent}>
				<div style={card1Styles} className={styles.div1} onClick={() => openModal(5)}>
					<div className={styles.textContainer}>
						<p style={{ color: 'white' }} className={styles.cardDescription}>
							Gum Gum
							<br /> Machine Learning 101
						</p>
					</div>
				</div>
				<div className={styles.svg1}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="300"
						height="150"
						viewBox="0 0 300 150"
						fill="none">
						<path
							d="M150 70.4639C193.699 70.4639 229.265 105.881 229.535 149.5L299.499 149.5C299.23 67.1558 232.39 0.500006 150 0.500013C67.6103 0.50002 0.788079 67.1557 0.500916 149.5L70.4655 149.5C70.7354 105.863 106.319 70.4639 150 70.4639Z"
							fill="#CEF272"
							// stroke="black"
						/>
					</svg>
				</div>
				<div style={card2Styles} className={styles.div2} onClick={() => openModal(3)}>
					<div className={styles.textContainer}>
						<p className={styles.cardDescription} style={{ color: '#0A0A0E' }}>
							BetterCloud <br />
							State of SaaSOps Report
						</p>
					</div>
				</div>
				<div style={card3Styles} className={styles.div3} onClick={() => openModal(6)}>
					<div className={styles.textContainer}>
						<p className={styles.cardDescription} style={{ color: '#fff' }}>
							Coldwell Banker <br />
							Luxury Listing Presentation
						</p>
					</div>
				</div>

				<div style={card4Styles} className={styles.div4} onClick={() => openModal(4)}>
					<div className={styles.textContainer}>
						<p className={styles.cardDescription} style={{ color: '#fff' }}>
							Tenable <br />
							Halloween Game
						</p>
					</div>
				</div>
				<div style={card5Styles} className={styles.div5} onClick={() => openModal(2)}>
					<div className={styles.textContainer}>
						<p className={styles.cardDescription}>
							Food52 <br />
							Stir & Sip Gift Generator
						</p>
					</div>
				</div>
				<div style={card6Styles} className={styles.div6} onClick={() => openModal(7)}>
					<div className={styles.textContainer}>
						<p className={styles.cardDescription} style={{ color: '#0A0A0E' }}>
							HubSpot <br /> Marketing Secrets
						</p>
					</div>
				</div>
				<div className={styles.svg2}>
					<svg
						className={clsx(isInView && styles.starAnimated)}
						style={{
							transition: 'transform 0.2s linear',
							transform: `rotate(${rotationDegrees}deg)`,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 246 246"
						fill="none">
						<path
							d="M83.4676 41.2141C84.3836 32.4807 94.9862 28.6741 101.248 34.8304L141.698 74.5982C143.39 76.2616 145.596 77.302 147.955 77.5495L204.37 83.4669C213.103 84.383 216.91 94.9856 210.754 101.247L170.986 141.697C169.323 143.389 168.282 145.595 168.035 147.955L162.117 204.369C161.201 213.103 150.599 216.909 144.337 210.753L103.887 170.985C102.195 169.322 99.9893 168.282 97.6296 168.034L41.2147 162.117C32.4814 161.201 28.6748 150.598 34.8311 144.336L74.5989 103.887C76.2622 102.195 77.3027 99.9886 77.5502 97.6289L83.4676 41.2141Z"
							fill="#008FF5"
						/>
					</svg>
				</div>
				<div className={styles.svg3}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="208"
						height="195"
						viewBox="0 0 208 195"
						fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M199.473 105.286C198.771 113.458 178.46 118.507 152.869 117.307C171.583 134.385 182.028 152.122 176.514 158.308C171 164.493 151.458 156.969 131.408 141.395C136.267 165.842 134.015 186.098 125.796 187.935C117.576 189.773 106.26 172.548 99.4044 148.545C88.5566 171.028 74.4703 186.071 66.6841 182.859C58.8976 179.647 60.1326 159.301 69.0909 136.037C46.6744 147.97 26.1287 152.055 21.7495 145.019C17.3705 137.983 30.687 122.286 52.0404 108.649C26.624 105.467 7.4755 97.0298 8.17714 88.859C8.87883 80.6876 29.1879 75.6384 54.777 76.8374C36.0598 59.7581 25.6133 42.0194 31.1279 35.8329C36.6421 29.6469 56.188 37.1733 76.2402 52.751C71.381 28.3036 73.6332 8.04833 81.8526 6.21077C90.073 4.37297 101.389 21.5999 108.245 45.6058C119.093 23.1195 133.181 8.07345 140.968 11.2859C148.754 14.498 147.52 34.8421 138.563 58.1043C160.973 46.1782 181.509 42.0969 185.887 49.1313C190.265 56.166 176.954 71.8593 155.606 85.4958C181.024 88.677 200.175 97.1147 199.473 105.286Z"
							fill="#CFC3FF"
						/>
					</svg>
				</div>
			</div>
		</Container>
	)
}

const IframeModal = ({ isModalOpen, setIsModalOpen, screenWidth, frameSrc }: IframeModalProps) => {
	return (
		<Modal
			isOpen={isModalOpen}
			onRequestClose={() => setIsModalOpen(false)}
			className={styles.modal}
			overlayClassName={styles.overlay}>
			<div
				style={{
					maxWidth: '1440px',
					width: screenWidth < 680 ? '90vw' : '80vw',
					maxHeight: '90vh',
					aspectRatio: screenWidth < 680 ? '0.614' : '1.8',
				}}>
				<iframe
					width={'100%'}
					height={'100%'}
					frameBorder={0}
					src={frameSrc}
					title="W3Schools Free Online Web Tutorials"></iframe>
			</div>
		</Modal>
	)
}

const card1Styles = {
	backgroundImage: 'url("/images/brain.png")',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundColor: '#031C2E',
}
const card2Styles = {
	backgroundImage: 'url("/images/truck.png")',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
}

const card3Styles = {
	backgroundImage: 'url("/images/mountain.png")',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
}
const card4Styles = {
	backgroundImage: 'url("/images/ghosts.png")',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
}
const card5Styles = {
	backgroundImage: 'url("/images/dinner.png")',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
}
const card6Styles = {
	backgroundImage: 'url("/images/graph.png")',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
}
