import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FeatureTileItemFragment } from './FeatureTileItemFragment'

export const FeatureTileListFragment = () =>
	ZeusSelect<ValueTypes['FeatureTileList']>()({
		items: [{}, FeatureTileItemFragment()],
	})

export type FeatureTileListResult = MapType<
	ModelTypes['FeatureTileList'],
	ReturnType<typeof FeatureTileListFragment>
>
