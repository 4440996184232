import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { LinkFragment } from './LinkFragment'

export const PricingPlansFragment = () =>
	ZeusSelect<ValueTypes['PricingPlansBlock']>()({
		items: [
			{ orderBy: [{ order: OrderDirection.asc }] },
			{
				id: true,
				title: true,
				mainTitle: true,
				description: true,
				backgroundColor: true,
				checkmarkOne: true,
				checkmarkTwo: true,
				checkmarkThree: true,
				checkmarkFour: true,
				link: [{}, LinkFragment()],
			},
		],
	})

export type PricingPlansResult = MapType<
	ModelTypes['PricingPlansBlock'],
	ReturnType<typeof PricingPlansFragment>
>
