import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export function ImageFragment() {
	return ZeusSelect<ValueTypes['Image']>()({
		id: true,
		url: true,
		width: true,
		height: true,
		alt: true,
		fileName: true,
	})
}

export type ImageResult = MapType<ModelTypes['Image'], ReturnType<typeof ImageFragment>>
