import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FormFragment } from './FormFragment'
import { ImageFragment } from './ImageFragment'

export const TabsWithMediaItemFragment = () =>
	ZeusSelect<ValueTypes['TabsWithMediaItem']>()({
		id: true,
		tabTitle: true,
		title: true,
		text: true,
		image: [{}, ImageFragment()],
		isFormWaitlist: true,
		form: [{}, FormFragment()],
	})

export type TabsWithMediaItemResult = MapType<
	ModelTypes['TabsWithMediaItem'],
	ReturnType<typeof TabsWithMediaItemFragment>
>
