import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const FancyTitleImageFragment = () =>
	ZeusSelect<ValueTypes['FancyTitleImage']>()({
		image: [{}, ImageFragment()],
		locationX: true,
		verticalLocation: true,
	})

export type FancyTitleImageResult = MapType<
	ModelTypes['FancyTitleImage'],
	ReturnType<typeof FancyTitleImageFragment>
>
