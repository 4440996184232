import type { FunctionComponent } from 'react'
import type { AppTimelineResult } from '../data/content/AppTimelineFragment'
import styles from './AppTimeline.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

export type AppTimelineProps = {
	apptimeline: AppTimelineResult
}

export const AppTimeline: FunctionComponent<AppTimelineProps> = ({ apptimeline }) => {
	return (
		<Container size="wide">
			<div className={styles.appTimeline}>
				{apptimeline.logo && (
					<div className={styles.image}>
						<ContemberImage image={apptimeline.logo} layout="fill" objectFit="contain" />
					</div>
				)}
				<div className={styles.groups}>
					{apptimeline.groups.map((group) => (
						<div className={styles.group} key={group.id}>
							<div className={styles.groupImages}>
								{group.items.map((item) => (
									<div className={styles.image} key={item.id}>
										{item.image && (
											<ContemberImage image={item.image} layout="fill" objectFit="contain" />
										)}
									</div>
								))}
							</div>
							<p className={styles.title}>{group.title}</p>
						</div>
					))}
				</div>
			</div>
		</Container>
	)
}
