import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { InitiativesFragment } from './InitiativesFragment'

export const InitiativesTileItemFragment = () =>
	ZeusSelect<ValueTypes['InitiativesItem']>()({
		id: true,
		tile: [{}, InitiativesFragment()],
	})

export type InitiativesTileItem = MapType<
	ModelTypes['InitiativesItem'],
	ReturnType<typeof InitiativesTileItemFragment>
>
