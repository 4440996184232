import type { FunctionComponent } from 'react'
import type { ProductGroupResult } from '../data/content/ProductGroupFragment'
import styles from './ProductGroup.module.sass'
import { ProductTile } from './ProductTile'

export type ProductGroupProps = {
	data: ProductGroupResult
}

export const ProductGroup: FunctionComponent<ProductGroupProps> = ({ data: { name, items } }) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.name}>{name}</h2>
			<div className={styles.items}>
				{items.map(
					(item) =>
						item.product && (
							<div className={styles.item} key={item.id}>
								<ProductTile product={item.product} />
							</div>
						)
				)}
			</div>
		</div>
	)
}
