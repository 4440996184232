import clsx from 'clsx'
import Image from 'next/legacy/image'
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { HeroStyle, LinkType } from '../../generated/content'
import type { HeroResult } from '../data/content/HeroFragment'
import { lazyBoundary } from '../images/configuration'
import bubble1 from '../images/dynamicHero/bubble1.png'
import bubble10 from '../images/dynamicHero/bubble10.png'
import bubble4 from '../images/dynamicHero/bubble4.png'
import bubble5 from '../images/dynamicHero/bubble5.png'
import bubble6 from '../images/dynamicHero/bubble6.png'
import bubble7 from '../images/dynamicHero/bubble7.png'
import bubble8 from '../images/dynamicHero/bubble8.png'
import bubble9 from '../images/dynamicHero/bubble9.png'
import circle1 from '../images/dynamicHero/circle1.png'
import circle2 from '../images/dynamicHero/circle2.png'
import circle3 from '../images/dynamicHero/circle3.png'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useParallax } from '../utilities/useParallax'
import { useReducedMotion } from '../utilities/useReducedMotion'
import { Button } from './Button'
import { Container } from './Container'
import styles from './Hero.module.sass'
import { HeroDynamic } from './HeroDynamic'

export type HeroProps = HeroResult

export const Hero: FunctionComponent<HeroProps> = ({ background, title, text, link, video }) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
	const { height: windowHeight } = useWindowSize()
	const offset = useParallax(wrapper, 0, 192 /* navigation height */ / windowHeight)
	const videoRef = useRef<HTMLVideoElement>(null)
	const reducedMotion = useReducedMotion()

	useEffect(() => {
		const video = videoRef.current
		if (video === null) {
			return
		}
		if (reducedMotion) {
			video.pause()
		} else {
			video.play()
		}
	}, [reducedMotion, title])

	const image = useMemo(() => {
		if (background === HeroStyle.lessBubbles) {
			return [[bubble4], [bubble9, circle1], [circle2, bubble8]]
		}
		if (background === HeroStyle.moreBubbles) {
			return [
				[bubble10, bubble4],
				[bubble9, circle1],
				[bubble6, circle2, bubble5],
				[circle3, bubble1, bubble7],
			]
		}
		return []
	}, [background])

	return (
		<>
			{background === HeroStyle.dynamic ? (
				<HeroDynamic title={title} text={text} link={link} />
			) : (
				<div
					className={styles.wrapper}
					ref={setWrapper}
					style={{
						'--Hero-offset': `${offset}`,
					}}>
					<Container size="normal">
						<div
							className={clsx(
								styles.hero,
								background === HeroStyle.moreBubbles && styles.is_moreBubbles,
								background === HeroStyle.lessBubbles && styles.is_lessBubbles,
								background === HeroStyle.video && styles.is_video
							)}>
							<div className={styles.in}>
								<h2 className={styles.title}>{title}</h2>
								<p className={styles.text}>{text}</p>
								{link && (
									<Button
										type="link"
										href={contemberLinkToHref(link)}
										fill="blue"
										target={
											link.type === LinkType.scrollToTarget && link.type === LinkType.scrollToTarget
												? '_blank'
												: undefined
										}>
										{link.title}
									</Button>
								)}
							</div>

							<div className={styles.background}>
								{background === HeroStyle.video && video && (
									<div
										className={styles.video}
										style={{
											'--Hero-video-width': video.width,
											'--Hero-video-height': video.height,
										}}>
										<video
											ref={videoRef}
											className={styles.video}
											poster={video.poster?.url}
											muted
											loop>
											<source src={video.src} type={video.type} />
										</video>
									</div>
								)}
								{background !== HeroStyle.video && (
									<div className={styles.image}>
										<div className={styles.rows}>
											{image.map((row, i) => (
												<div className={styles.row} key={i}>
													{row.map((item, i) => (
														<div
															key={i}
															data-width={item.width}
															data-height={item.height}
															style={
																{
																	'--Hero-item-width': item.width,
																	'--Hero-item-height': item.height,
																} as React.CSSProperties
															}
															className={styles.row_item}>
															<Image
																lazyBoundary={lazyBoundary}
																src={item}
																alt=""
																layout="responsive"
															/>
														</div>
													))}
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						</div>
					</Container>
				</div>
			)}
		</>
	)
}
