import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { AccordionFragment } from './AccordionFragment'

export const AsideContentFragment = () =>
	ZeusSelect<ValueTypes['AsideContent']>()({
		isAccordion: true,
		accordion: [{}, AccordionFragment()],
		isAsideMediaOutOfLayout: true,
		isShapeInBackground: true,
		isFormBox: true,
		formAlignment: true,
	})

export type AsideContentResult = MapType<
	ModelTypes['AsideContent'],
	ReturnType<typeof AsideContentFragment>
>
