import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes, useState } from 'react'
import styles from './TextArea.module.sass'

export type TextAreaProps = Pick<
	DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
	| 'name'
	| 'autoComplete'
	| 'value'
	| 'onChange'
	| 'disabled'
	| 'readOnly'
	| 'placeholder'
	| 'required'
	| 'hidden'
> & {
	compact?: boolean
	isInputHighlighted?: boolean
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			name,
			autoComplete,
			value,
			onChange,
			disabled,
			readOnly,
			placeholder,
			required,
			hidden,
			compact,
			isInputHighlighted = false,
		},
		ref
	) => {
		const [isHighlighted, setHighlighted] = useState(isInputHighlighted)
		return (
			<textarea
				className={clsx(
					styles.wrapper,
					compact && styles.view_compact,
					isHighlighted && styles.is_highlighted
				)}
				name={name}
				autoComplete={autoComplete}
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				required={required}
				hidden={hidden}
				ref={ref}
				onFocus={() => setHighlighted(false)}
			/>
		)
	}
)

TextArea.displayName = 'TextArea'
