import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { assertNever } from '../utilities/assertNever'
import styles from './CloseButton.module.sass'
import { Icon } from './Icon'

type ButtonCloseButtonProps = {
	type: 'button'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type LinkCloseButtonProps = {
	type: 'link'
	link: string
}

export type CloseButtonProps = ButtonCloseButtonProps | LinkCloseButtonProps

export const CloseButton: FunctionComponent<CloseButtonProps> = (props) => {
	const label = 'Close'
	const content = <Icon name="cross" />

	if (props.type === 'button') {
		return (
			<button type="button" onClick={props.onClick} className={styles.wrapper} aria-label={label}>
				{content}
			</button>
		)
	} else if (props.type === 'link') {
		return (
			<Link href={props.link} className={styles.wrapper} aria-label={label}>
				{content}
			</Link>
		)
	} else {
		assertNever(props)
		return null
	}
}
