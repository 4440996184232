import { Container } from './Container'
import styles from './HumblebragsMobile.module.sass'
import { Icon } from './Icon'

export const HumblebragsMobile = () => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				{/* <div className={styles.header}>
					<p className={styles.title}>Warning:</p>
					<p className={styles.titleSecondary}>
						Humblebrags <br /> incoming
					</p>
				</div> */}
				<div className={styles.cardContainer}>
					<p className={styles.cardDescription}>
						"Over the course of a year, sites that had Ceros attached to it were{' '}
						<span style={{ color: '#1B00FB' }}>16% more likely to close</span> ... It was millions
						of dollars in revenue."
					</p>
					<p className={styles.cardAuthor}>
						Jeff Holmes, MX Technologies <br /> Head of Sales Design
					</p>
				</div>
				<div className={styles.coloredBoxContainer} style={{ background: '#FE5621' }}>
					<div className={styles.damnWrapper}>
						<Icon name="damn" />
					</div>
					<p className={styles.percentage} style={{ color: '#fff' }}>
						96%
					</p>
					<p style={{ color: '#fff' }} className={styles.message}>
						would recommend Ceros <br /> to a friend or colleague
					</p>
				</div>
				<div className={styles.cardContainer}>
					<p className={styles.cardDescription}>
						“Using Ceros, it took us 3 weeks, instead of the 8-12 week timeline,{' '}
						<span style={{ color: '#E21075' }}>for a designer, web developer, and QA.</span>”
					</p>
					<p className={styles.cardAuthor}>
						{' '}
						Jonathan Harrop, AdColony <br /> VP, Global Marketing & Communications
					</p>
				</div>
				<div className={styles.coloredBoxContainer} style={{ background: '#FFCDE5' }}>
					<div className={styles.shoulderBrushingWrapper}>
						<Icon name="shoulderBrushing" />
					</div>
					<p style={{ color: '#0A0A0E' }} className={styles.percentage}>
						100%
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						describe our support and <br /> training as unparalleled and <br /> exceptional
					</p>
				</div>
				<div className={styles.cardContainer}>
					<p className={styles.cardDescription}>
						"The key for our Ceros workflow has been{' '}
						<span style={{ color: '#FE5621' }}>creating templates that we can repurpose</span>.
						Ceros allows us to do that really easily."
					</p>
					<p className={styles.cardAuthor}>
						{' '}
						Colton Pond, Truv
						<br />
						VP, Head of Marketing
					</p>
				</div>
				<div className={styles.coloredBoxContainer} style={{ background: '#BFF130' }}>
					<p className={styles.percentage} style={{ color: '#0A0A0E' }}>
						98%
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						Ceros users rated us as <br /> having highly innovative <br /> products and services
					</p>
				</div>
			</div>
		</Container>
	)
}
