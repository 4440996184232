import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { LinkFragment } from './LinkFragment'

export const KeywordsTileFragment = () =>
	ZeusSelect<ValueTypes['KeywordsTile']>()({
		firstKeyword: true,
		secondKeyword: true,
		thirdKeyword: true,
		text: true,
		link: [{}, LinkFragment()],
	})

export type KeywordsTileResult = MapType<
	ModelTypes['KeywordsTile'],
	ReturnType<typeof KeywordsTileFragment>
>
