import Image from 'next/image'
import Link from 'next/link'
import { useWindowSize } from 'react-use'
import type { LoginPageBlockResult } from '../data/content/LoginPageFragment'
import { BreakableContent } from './BreakableContent'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { ContemberImage } from './ContemberImage'
import styles from './LoginPageBlock.module.sass'

interface LinkProps {
	title: string
	description: string
	url: string
	icon: any
}

export type LoginPageBlockProps = LoginPageBlockResult

export const LoginPageBlock = ({
	title,
	backgroundColor,
	content,
	logo,
	items,
}: LoginPageBlockProps) => {
	return (
		<main className={styles.wrapper}>
			<div className={styles.content_wrapper}>
				<AnimationOnTop />
				<div className={styles.left_side}>
					{logo && (
						<a className={styles.logo} href="/">
							<ContemberImage image={{ ...logo, alt: logo.alt || 'logo' }} />
						</a>
					)}
					<div>
						{title && (
							<BreakableContent breakableFrom="768">
								<h2 className={styles.title}>
									<CerosRichTextRenderer source={title} />
								</h2>
							</BreakableContent>
						)}
						<div className={styles.links_section}>
							{items &&
								items.map((l: any, i: number) => {
									return (
										<CustomLinks
											key={i}
											title={l.link.title}
											description={l.link.description}
											url={l.link.url}
											icon={l.link.icon}
										/>
									)
								})}
						</div>
						<div className={styles.bottomWrapper}>
							<p className={styles.bottomText}> Don't have a login? </p>
							<Link className={styles.bottomLink} href="/demo-request">
								Request a demo
							</Link>
						</div>
					</div>
				</div>
				<div className={styles.right_side}>
					<div
						className={styles.wrapper}
						style={{ backgroundColor: backgroundColor || 'transparent' }}>
						{content && (
							<BreakableContent breakableFrom="768">
								<h2 className={styles.title}>
									<CerosRichTextRenderer source={content} />
								</h2>
							</BreakableContent>
						)}
					</div>
				</div>
			</div>
		</main>
	)
}

const CustomLinks = ({ title, description, url, icon }: LinkProps) => {
	return (
		<a className={styles.link_wrapper} href={url}>
			<Image
				src={icon?.icon?.url}
				alt={icon?.icon?.alt || 'Icon Image'}
				width={50}
				height={50}
				style={{
					objectFit: 'contain',
					maxHeight: '37.76px',
					maxWidth: '37.76px',
				}}
			/>
			{/* <ContemberImage image={icon.icon} /> */}
			<div className={styles.content}>
				{title && <span>{title}</span>}
				{description && <p>{description}</p>}
			</div>
			<div className={styles.icon_wrapper}>
				<ChevronRight />
			</div>
		</a>
	)
}

const ChevronRight = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
			<path
				d="M6.38965 3.7196L11.2694 8.45037C11.3074 8.4849 11.3376 8.52661 11.3583 8.57292C11.379 8.61922 11.3896 8.66915 11.3896 8.7196C11.3896 8.77006 11.379 8.81999 11.3583 8.86629C11.3376 8.9126 11.3074 8.9543 11.2694 8.98884L6.38965 13.7196"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const AnimationOnTop = () => {
	const { width } = useWindowSize()

	return (
		<div className={styles.animationOnTop}>
			{width < 768 ? (
				<div className={styles.gradientCircle}></div>
			) : (
				<svg
					preserveAspectRatio="xMidYMid meet"
					viewBox="0 0 1000 1000"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					width={'100%'}
					height={'100%'}
					style={{ transform: 'translate3d(0, 0, 0)' }}>
					<g id="Ellipse 749" opacity="0.15" filter="url(#filter0_f_5457_1813)">
						<circle cx="500" cy="500" r="400" fill="url(#paint0_radial_5457_1813)"></circle>
					</g>
					<defs>
						<filter
							id="filter0_f_5457_1813"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="BackgroundImageFix"
								result="shape"></feBlend>
							<feGaussianBlur
								stdDeviation="50"
								result="effect1_foregroundBlur_5457_1813"></feGaussianBlur>
						</filter>
						<radialGradient
							id="paint0_radial_5457_1813"
							cx="275"
							cy="500"
							r="500"
							gradientUnits="userSpaceOnUse">
							<stop offset="0.25" stopColor="#FE5621"></stop>
							<stop offset="1" stopColor="#1B00FE"></stop>
						</radialGradient>
					</defs>
				</svg>
			)}
		</div>
	)
}
