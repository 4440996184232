import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './CardTile.module.sass'

export type CardTileProps = {
	children: ReactNode
	borderRadius?: 'default' | 'big'
	isOverflowPrevented?: boolean
}

export const CardTile: FunctionComponent<CardTileProps> = ({
	children,
	borderRadius = 'default',
	isOverflowPrevented = true,
}) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_borderRadius_${borderRadius}`],
				isOverflowPrevented && styles.is_overflowPrevented
			)}>
			{children}
		</div>
	)
}
