import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const CaseStudyListFragment = () =>
	ZeusSelect<ValueTypes['CaseStudyList']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			{
				id: true,
				image: [{}, ImageFragment()],
				text: true,
				caseStudy: [
					{},
					{
						page: [
							{},
							{
								id: true,
								name: true,
								link: [
									{},
									{
										url: true,
									},
								],
							},
						],
					},
				],
			},
		],
	})

export type CaseStudyListResult = MapType<
	ModelTypes['CaseStudyList'],
	ReturnType<typeof CaseStudyListFragment>
>
