import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { FancyTitleResult } from '../data/content/FancyTitleFragment'
import type { FancyTitleImageItemResult } from '../data/content/FancyTitleImageItemFragment'
import { useIsInView } from '../utilities/useIsInView'
import { BreakableContent } from './BreakableContent'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import styles from './FancyTitle.module.sass'

export type FancyTitleProps = FancyTitleResult

export const FancyTitle: FunctionComponent<FancyTitleProps> = ({ text, imageList }) => {
	const [isInitialInView, setIsInitialInView] = useState(false)
	const wrapperRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(wrapperRef, {
		threshold: 0.9,
	})

	useEffect(() => {
		if (isInView) {
			setIsInitialInView(isInView)
		}
	}, [isInView])

	return (
		<div
			className={clsx(
				styles.wrapper,
				isInitialInView && styles.is_initialInView,
				isInView && styles.is_inView
			)}
			ref={wrapperRef}>
			{text && (
				<div className={styles.text}>
					<BreakableContent breakableFrom="768">
						<CerosRichTextRenderer source={text} />
					</BreakableContent>
				</div>
			)}
			{imageList && (
				<div className={styles.imageList}>
					{imageList.items.map((item) => item.item && <FancyItem key={item.id} {...item.item} />)}
				</div>
			)}
		</div>
	)
}

type FancyItemProps = NonNullable<FancyTitleImageItemResult['item']>

const FancyItem: FunctionComponent<FancyItemProps> = ({ locationX, verticalLocation, image }) => {
	return (
		<div
			className={clsx(styles.imageItem, styles[`is_verticalLocation_${verticalLocation}`])}
			style={{
				'--FancyTitle-imageItem-locationX': `${locationX}%`,
			}}>
			<div className={styles.imageItemIn}>
				{image && (
					<Image
						className={styles.image}
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.alt ?? image.fileName ?? ''}
					/>
				)}
			</div>
		</div>
	)
}
