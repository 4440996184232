import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { CarouselCards } from './CarouselCards'
import styles from './ComparasionTable.module.sass'
import { Container } from './Container'
import { Icon } from './Icon'

type ComparisonItem = {
	question: string
	value: string | boolean
}

type ComparisonCategory = {
	key: string
	items: ComparisonItem[]
}

const data = {
	table: {
		headings: ['', 'Gemma', 'ChatGPT', 'Midjourney', 'UIzard', 'NotionAI', 'Copy.AI'],
		data: [
			{
				title: 'Expert copywriting',
				Gemma: true,
				ChatGPT: true,
				Midjourney: false,
				UIzard: true,
				NotionAI: true,
				'Copy.AI': true,
			},
			{
				title: 'Summarize tasks, content, transcripts & more',
				Gemma: true,
				ChatGPT: true,
				Midjourney: false,
				UIzard: false,
				NotionAI: true,
				'Copy.AI': true,
			},
			{
				title: 'Tuned for creativity',
				Gemma: true,
				ChatGPT: false,
				Midjourney: true,
				UIzard: true,
				NotionAI: false,
				'Copy.AI': false,
			},
			{
				title: 'Innovative integrations',
				Gemma: true,
				ChatGPT: false,
				Midjourney: false,
				UIzard: false,
				NotionAI: true,
				'Copy.AI': false,
			},
			{
				title: 'Advanced visual output & editing',
				Gemma: true,
				ChatGPT: false,
				Midjourney: true,
				UIzard: true,
				NotionAI: false,
				'Copy.AI': false,
			},
			{
				title: 'Pre-loaded prompts knock out your to-do list',
				Gemma: true,
				ChatGPT: false,
				Midjourney: false,
				UIzard: false,
				NotionAI: true,
				'Copy.AI': true,
			},
			{
				title: 'Enterprise-grade security',
				Gemma: true,
				ChatGPT: true,
				Midjourney: false,
				UIzard: false,
				NotionAI: true,
				'Copy.AI': false,
			},
		],
	},
}

const renderValue = (value: boolean, key: string) => {
	if (key === 'Gemma' && value) return <Icon name="checkGemma" />
	if (value) return <Icon name="checkTrue" />
	return <Icon name="checkFalse" />
}

export const ComparasionTableBlock = () => {
	const { width } = useWindowSize()
	const [comparisonData, setComparisonData] = useState<ComparisonCategory[]>([])

	// Effects
	useEffect(() => {
		const headings: string[] = data.table.headings.slice(1)
		const result: ComparisonCategory[] = []

		headings.forEach((heading: string) => {
			const items: ComparisonItem[] = data.table.data.map((item) => ({
				question: item.title,
				value: item[heading as keyof typeof item],
			}))

			result.push({
				key: heading,
				items,
			})
		})

		setComparisonData(result)
	}, [data])

	if (width < 850) return <MobileVersion items={comparisonData} />

	return (
		<Container size="normal">
			<table className={styles.comparison_table}>
				<thead>
					{data.table.headings.map((h, i) => {
						return (
							<th colSpan={i === 0 ? 2 : 1} className={clsx(i === 1 && styles.is_popOut)}>
								{h === 'Gemma' ? (
									<Image
										src="/gemma-product.svg"
										width={100}
										height={50}
										style={{ width: '100%', height: '100%', objectFit: 'contain' }}
										alt="Gemma Product Logo"
										priority
									/>
								) : (
									h
								)}
							</th>
						)
					})}
				</thead>
				<tbody>
					{data.table.data.map((d) => {
						return (
							<tr>
								<td className={styles.data_title} colSpan={2}>
									{d.title}
								</td>
								<td className={clsx(d.ChatGPT && styles.is_popOut)}>
									{renderValue(d.Gemma, 'Gemma')}
								</td>
								<td>{renderValue(d.ChatGPT, 'ChatGPT')}</td>
								<td>{renderValue(d.Midjourney, 'Midjourney')}</td>
								<td>{renderValue(d.UIzard, 'UIzard')}</td>
								<td>{renderValue(d.NotionAI, 'NotionAI')}</td>
								<td>{renderValue(d['Copy.AI'], 'Copy.AI')}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</Container>
	)
}

const MobileVersion = (items: any) => {
	return (
		<div className={styles.carousel_wrapper}>
			<div className={styles.main}>
				<div className={styles.carousel}>
					<CarouselCards
						options={{
							slidesPerView: 1,
							gapSize: 'large',
							displayPagination: true,
						}}
						cards={{
							type: 'default',
							list: items.items.map((item: any, i: number) => {
								return {
									id: i,
									children: <MobileCard item={item} />,
								}
							}),
						}}
					/>
				</div>
			</div>
		</div>
	)
}

const MobileCard = (item: any) => {
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.main, item.item.key === 'Gemma' && styles.is_Gemma)}>
				<h6>
					{item.item.key !== 'Gemma' ? (
						item.item.key
					) : (
						<Image
							src="/gemma-product.svg"
							width={100}
							height={50}
							alt="Gemma Product Logo"
							priority
						/>
					)}{' '}
				</h6>

				<div className={styles.content_wrapper}>
					{item.item.items.map((d: any) => {
						return (
							<div className={styles.content}>
								<span>{renderValue(d.value, item.item.key)}</span>
								<p>{d.question}</p>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
