import type { FunctionComponent, ReactNode } from 'react'
import styles from './GemmaContent.module.sass'

export interface GemmaContentProps {
	title: ReactNode
	text: ReactNode
	iconA: ReactNode
	iconB: ReactNode
	iconC: ReactNode
	image: ReactNode
}

export const GemmaContent: FunctionComponent<GemmaContentProps> = ({
	title,
	text,
	iconA,
	iconB,
	iconC,
	image,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.title}>{title}</div>
				<div className={styles.text}>{text}</div>
				<div className={styles.icon}>{iconA}</div>
				<div className={styles.icon}>{iconB}</div>
				<div className={styles.icon}>{iconC}</div>
			</div>
			<div className={styles.image}>{image}</div>
		</div>
	)
}
