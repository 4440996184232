import type { FunctionComponent } from 'react'
import type { LogoListResult } from '../data/content/LogoListFragment'
import { BreakableContent } from './BreakableContent'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { LogoList } from './LogoList'
import styles from './StripWithLogos.module.sass'

export type StripWithLogosProps = LogoListResult & {
	title?: string
}

export const StripWithLogos: FunctionComponent<StripWithLogosProps> = ({
	title,
	items,
	labelType,
	maxLogosInRow,
}) => {
	return (
		// <Container size="small">
		<div className={styles.wrapper}>
			{title && (
				<h2 className={styles.title}>
					<BreakableContent>
						<CerosRichTextRenderer source={title as string} />
					</BreakableContent>
				</h2>
			)}
			{items && items.length > 0 && (
				<div className={styles.logos}>
					<LogoList
						items={items}
						maxLogosInRow={maxLogosInRow ?? 5}
						labelType={labelType}
						view="strip"
					/>
				</div>
			)}
		</div>
		// </Container>
	)
}
