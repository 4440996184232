import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { GridBoxFragment } from './GridBoxFragment'

export const GridBoxListFragment = () =>
	ZeusSelect<ValueTypes['GridBoxList']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			GridBoxFragment(),
		],
	})

export type GridBoxListResult = MapType<
	ModelTypes['GridBoxList'],
	ReturnType<typeof GridBoxListFragment>
>
