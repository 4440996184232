import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useState } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { RevealableMediaContentResult } from '../data/content/RevealableMediaContentFragment'
import type { VideoSelectResult } from '../data/content/VideoSelectFragment'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Icon } from './Icon'
import { VideoModal } from './Modal'
import styles from './RevealableMediaContentItem.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type RevealableMediaContentProps = RevealableMediaContentResult

export const RevealableMediaContent: FunctionComponent<RevealableMediaContentProps> = ({
	title,
	text,
	icon,
	isIconAtTheEnd,
	footerInfo,
	videoSelect,
}) => {
	const iconWrapper = (icon: ImageResult) => (
		<span className={styles.iconWrapper}>
			<Image
				src={icon.url}
				width={icon.width}
				height={icon.height}
				alt={icon.alt ?? icon.fileName ?? ''}
				className={styles.icon}
			/>
			<Image
				src={icon.url}
				width={icon.width}
				height={icon.height}
				alt={icon.alt ?? icon.fileName ?? ''}
				className={clsx(styles.icon, styles.is_hovered)}
			/>
		</span>
	)

	return (
		<div className={styles.wrapper}>
			<span className={styles.titleWrapper}>
				{/* {!isIconAtTheEnd && icon && iconWrapper(icon)} */}
				{/* <span className={styles.title}>{title}</span> */}
				{/* <BreakableContent breakableFrom="768"> */}
				<span className={styles.title}>
					<CerosRichTextRenderer source={title as string} />
				</span>
				{/* </BreakableContent> */}
				{/* {isIconAtTheEnd && icon && iconWrapper(icon)} */}
			</span>
			{(videoSelect || text || footerInfo) && (
				<div className={styles.mediaContent}>
					{videoSelect && (
						<>
							<PosterPreview {...videoSelect} />
						</>
					)}
					{(text || footerInfo) && (
						<div className={styles.content}>
							<div className={styles.text}>
								<Wysiwyg source={text} />
							</div>
							{footerInfo && (
								<div className={styles.footer}>
									<Wysiwyg source={footerInfo} />
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	)
}

type PosterPreviewProps = VideoSelectResult

const PosterPreview: FunctionComponent<PosterPreviewProps> = ({ ...props }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<>
			<button
				type="button"
				className={styles.posterPreview}
				onClick={() => setIsModalOpen(true)}
				style={{
					'--RevealableMediaContentItem-poster-width': props.poster?.width,
					'--RevealableMediaContentItem-poster-height': props.poster?.height,
				}}>
				{props.poster && (
					<Image
						className={styles.posterPreviewIn}
						src={props.poster.url}
						fill
						alt={props.poster.alt ?? props.poster.fileName ?? ''}
					/>
				)}
				<div className={styles.playButton}>
					<Icon name="playButton" />
				</div>
			</button>
			<VideoModal
				isOpen={isModalOpen}
				onRequestClose={() => {
					setIsModalOpen(false)
				}}
				video={props}
			/>
		</>
	)
}
