import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const InitiativesFragment = () =>
	ZeusSelect<ValueTypes['FeatureTile']>()({
		title: true,
		text: true,
	})

export type InitiativesFragmentResult = MapType<
	ModelTypes['FeatureTile'],
	ReturnType<typeof InitiativesFragment>
>
