import { CSSProperties, useEffect, useRef, useState, type FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { useIsInView } from '../utilities/useIsInView'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import styles from './ImageWithCursors.module.sass'

export type ImageWithCursorsProps = {
	image: ImageResult
	alt?: string
	maxWidth?: number | null
}

export const ImageWithCursors: FunctionComponent<ImageWithCursorsProps> = ({ image, maxWidth }) => {
	const wrapperRef = useRef(null)
	const [scrollPosition, setScrollPosition] = useState(0)
	const isInView = useIsInView(wrapperRef, {
		threshold: 0.5,
	})

	const handleScroll = () => {
		const position = window.scrollY
		setScrollPosition(position)
	}

	useEffect(() => {
		if (isInView) {
			window.addEventListener('scroll', handleScroll)
		} else {
			window.removeEventListener('scroll', handleScroll)
		}

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [isInView])

	const dojaDogCursorStyle: CSSProperties = {
		transform: `translateX(${scrollPosition * 0.2}px)`, // Moves horizontally with scroll
		position: 'absolute',
		bottom: '20%',
		left: '10%',
	}

	const dwightCursorStyle: CSSProperties = {
		transform: `translateY(-${scrollPosition * 0.2}px)`, // Moves vertically with scroll
		position: 'absolute',
		bottom: '-5%',
		right: '35%',
	}

	const pinkPantherCursorStyle: CSSProperties = {
		transform: `translate(-${scrollPosition * 0.25}px, -${scrollPosition * 0.25}px)`, // Moves diagonally with scroll
		position: 'absolute',
		top: '65%',
		right: '7.5%',
	}

	return (
		<section className={styles.mainWrapper}>
			<div
				ref={wrapperRef}
				style={
					maxWidth
						? {
								'--Image-imageWrapper-maxWidth': `${maxWidth}px`,
						  }
						: undefined
				}
				className={styles.imageWrapper}>
				<ContemberImage
					image={image}
					layout="responsive"
					sizes={maxWidth ? `${maxWidth}px` : '(min-width: 75rem) 69rem, 100vw'}
				/>
			</div>
			<div className={styles.iconWrapper} style={dojaDogCursorStyle}>
				<Icon cursorPointers name="dojaDogCursos" />
			</div>
			<div className={styles.iconWrapper} style={dwightCursorStyle}>
				<Icon cursorPointers name="dwightCursor" />
			</div>
			<div className={styles.iconWrapper} style={pinkPantherCursorStyle}>
				<Icon cursorPointers name="pinkPantherCursor" />
			</div>
		</section>
	)
}
