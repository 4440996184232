import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FancyTitleImageFragment } from './FancyTitleImageFragment'

export const FancyTitleImageItemFragment = () =>
	ZeusSelect<ValueTypes['FancyTitleImageItem']>()({
		id: true,
		item: [{}, FancyTitleImageFragment()],
	})

export type FancyTitleImageItemResult = MapType<
	ModelTypes['FancyTitleImageItem'],
	ReturnType<typeof FancyTitleImageItemFragment>
>
