import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo } from 'react'
import type { ContentReferenceResourceCategoryResult } from '../data/content/ContentReferenceResourceCategoryFragment'
import { getStringParameterFromQuery } from '../utilities/getStringParameterFromQuery'
import { isDefined } from '../utilities/isDefined'
import { resourceCategorySlugParameterName } from './ResourceGridCardList'
import styles from './ResourceGridCardListCategories.module.sass'

export type ResourceGridCardListCategoriesProps = {
	categories: ContentReferenceResourceCategoryResult[]
}

type Item = {
	id: string
	slug: null | string
	name?: string
}

export const ResourceGridCardListCategories: FunctionComponent<
	ResourceGridCardListCategoriesProps
> = ({ categories }) => {
	const urlKey = resourceCategorySlugParameterName

	const categoriesArray = useMemo<Array<Item>>(() => {
		return [
			{
				id: 'all',
				slug: null,
				name: 'All',
			},
			...categories.map((it) => it.item).filter(isDefined),
		]
	}, [categories])

	return (
		<ul className={styles.list}>
			{categoriesArray.map((item) => (
				<Item key={item.id} item={item} urlKey={urlKey} />
			))}
		</ul>
	)
}

const Item: FunctionComponent<{
	urlKey: NonNullable<ResourceGridCardListCategoriesProps['categories'][number]['item']>['slug']
	item: Item
}> = ({ urlKey, item }) => {
	const { query, pathname } = useRouter()
	const currentCategorySlug = getStringParameterFromQuery(query, urlKey)

	const newQuery = useMemo(
		() =>
			Object.fromEntries(
				Object.entries({
					...query,
					[urlKey]: item.slug === currentCategorySlug ? null : item.slug,
				})
					.filter(([_, value]) => value !== null)
					.sort(([a], [b]) => (a > b ? 1 : -1))
			),
		[currentCategorySlug, item.slug, query, urlKey]
	)

	return (
		<li
			key={item.id}
			className={clsx(styles.item, item.slug === currentCategorySlug && styles.is_active)}>
			<Link
				href={{
					pathname,
					query: newQuery,
				}}
				scroll={false}
				className={styles.itemLink}>
				{item.name}
			</Link>
		</li>
	)
}
