import type { FunctionComponent } from 'react'
import type { ProductListResult } from '../data/content/ProductListFragment'
import { CarouselCards } from './CarouselCards'
import { Container } from './Container'
import { PricingProductCard } from './PricingProductCard'
import styles from './PricingProductList.module.sass'
import { PricingProductTabs } from './PricingProductTabs'

export type PricingProductListSectionProps = ProductListResult & {
	title?: string
}

export const PricingProductListSection: FunctionComponent<PricingProductListSectionProps> = ({
	title,
	items,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.view_mobile}>
				<Container disableGutters>
					<PricingProductTabs items={items} title={title} />
				</Container>
			</div>
			<div className={styles.view_desktop}>
				<Container size="wide" disableGutters>
					<CarouselCards
						title={title}
						cards={{
							type: 'product',
							list: items.map((item) => {
								return {
									children: item.product && <PricingProductCard {...item.product} />,
									id: item.id,
								}
							}),
						}}
					/>
				</Container>
			</div>
		</div>
	)
}
