import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const ImageCarouselItemFragment = () =>
	ZeusSelect<ValueTypes['ImageCarouselItem']>()({
		id: true,
		image: ImageFragment(),
		primaryText: true,
	})

export type ImageCarouselItemResult = MapType<
	ModelTypes['ImageCarouselItem'],
	ReturnType<typeof ImageCarouselItemFragment>
>
