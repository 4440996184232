import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { RevealableMediaContentFragment } from './RevealableMediaContentFragment'

export const RevealableMediaContentItemFragment = () =>
	ZeusSelect<ValueTypes['RevealableMediaContentItem']>()({
		id: true,
		item: [{}, RevealableMediaContentFragment()],
	})

export type RevealableMediaContentItemResult = MapType<
	ModelTypes['RevealableMediaContentItem'],
	ReturnType<typeof RevealableMediaContentItemFragment>
>
