import clsx from 'clsx'
import Image from 'next/legacy/image'
import type React from 'react'
import { FunctionComponent, useState } from 'react'
import { LinkType } from '../../generated/content'
import type { HeroResult } from '../data/content/HeroFragment'
import { lazyBoundary } from '../images/configuration'
import bubble1 from '../images/dynamicHero/bubble1.png'
import bubble2 from '../images/dynamicHero/bubble2.png'
import bubble3 from '../images/dynamicHero/bubble3.png'
import bubble4 from '../images/dynamicHero/bubble4.png'
import bubble5 from '../images/dynamicHero/bubble5.png'
import bubble6 from '../images/dynamicHero/bubble6.png'
import bubble7 from '../images/dynamicHero/bubble7.png'
import circle1 from '../images/dynamicHero/circle1.png'
import circle2 from '../images/dynamicHero/circle2.png'
import circle3 from '../images/dynamicHero/circle3.png'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useParallax } from '../utilities/useParallax'
import { Button } from './Button'
import { Container } from './Container'
import styles from './HeroDynamic.module.sass'

export type HeroDynamicProps = HeroResult

export const HeroDynamic: FunctionComponent<HeroDynamicProps> = ({ title, text, link }) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
	const offset = useParallax(wrapper, 0, 0)

	return (
		<div
			className={styles.wrapper}
			ref={setWrapper}
			style={{
				'--HeroDynamic-offset': `${offset}`,
			}}>
			<div className={styles.container}>
				<div className={styles.in}>
					<div className={styles.contentWrapper}>
						<Container size="normal">
							<h2 className={styles.title}>{title}</h2>
							<div className={styles.textLinkWrapper}>
								<p className={styles.text}>{text}</p>
								{link && (
									<div className={styles.link}>
										<Button
											type="link"
											href={contemberLinkToHref(link)}
											fill="blue"
											target={
												link.type !== LinkType.scrollToTarget && link.isTargetBlank
													? '_blank'
													: undefined
											}>
											{link.title}
										</Button>
									</div>
								)}
							</div>
						</Container>
					</div>

					<div className={styles.bubblesWrapper}>
						<div className={styles.bubbles}>
							<div
								data-width={bubble1.width}
								data-height={bubble1.height}
								style={
									{
										'--bubble-size': String(bubble1.width / bubble1.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_left, styles.bubble1)}>
								<Image lazyBoundary={lazyBoundary} src={bubble1} alt="" layout="responsive" />
							</div>
							<div
								data-width={bubble2.width}
								data-height={bubble2.height}
								style={
									{
										'--bubble-size': String(bubble2.width / bubble2.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_right)}>
								<Image lazyBoundary={lazyBoundary} src={bubble2} alt="" layout="responsive" />
							</div>
						</div>
						<div className={styles.bubbles}>
							<div
								data-width={bubble3.width}
								data-height={bubble3.height}
								style={
									{
										'--bubble-size': String(bubble3.width / bubble3.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_left, styles.bubble3)}>
								<Image lazyBoundary={lazyBoundary} src={bubble3} alt="" layout="responsive" />
							</div>
							<div
								data-width={bubble4.width}
								data-height={bubble4.height}
								style={
									{
										'--bubble-size': String(bubble4.width / bubble4.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_right, styles.bubble4)}>
								<Image lazyBoundary={lazyBoundary} src={bubble4} alt="" layout="responsive" />
							</div>
						</div>
						<div className={styles.bubbles}>
							<div
								data-width={circle1.width}
								data-height={circle1.height}
								style={
									{
										'--bubble-size': String(circle1.width / circle1.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.circle, styles.is_view_left)}>
								<Image lazyBoundary={lazyBoundary} src={circle1} alt="" layout="responsive" />
							</div>
						</div>
						<div className={styles.bubbles}>
							<div
								data-width={circle2.width}
								data-height={circle2.height}
								style={
									{
										'--bubble-size': String(circle2.width / circle2.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.circle, styles.is_view_left, styles.circle2)}>
								<Image lazyBoundary={lazyBoundary} src={circle2} alt="" layout="responsive" />
							</div>
							<div
								data-width={bubble5.width}
								data-height={bubble5.height}
								style={
									{
										'--bubble-size': String(bubble5.width / bubble5.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_left)}>
								<Image lazyBoundary={lazyBoundary} src={bubble5} alt="" layout="responsive" />
							</div>
						</div>
						<div className={styles.bubbles}>
							<div
								data-width={circle3.width}
								data-height={circle3.height}
								style={
									{
										'--bubble-size': String(circle3.width / circle3.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.circle, styles.is_view_left)}>
								<Image lazyBoundary={lazyBoundary} src={circle3} alt="" layout="responsive" />
							</div>
							<div
								data-width={bubble6.width}
								data-height={bubble6.height}
								style={
									{
										'--bubble-size': String(bubble6.width / bubble6.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_right, styles.bubble6)}>
								<Image lazyBoundary={lazyBoundary} src={bubble6} alt="" layout="responsive" />
							</div>
							<div
								data-width={bubble7.width}
								data-height={bubble7.height}
								style={
									{
										'--bubble-size': String(bubble7.width / bubble7.height) + 'em',
									} as React.CSSProperties
								}
								className={clsx(styles.bubble, styles.is_view_right, styles.bubble7)}>
								<Image lazyBoundary={lazyBoundary} src={bubble7} alt="" layout="responsive" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
