import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { TabWithRevealableMediaContentFragment } from './TabWithRevealableMediaContentFragment'

export const TabWithRevealableMediaContentItemFragment = () =>
	ZeusSelect<ValueTypes['TabWithRevealableMediaContentItem']>()({
		id: true,
		item: [{}, TabWithRevealableMediaContentFragment()],
	})

export type TabWithRevealableMediaContentItemResult = MapType<
	ModelTypes['TabWithRevealableMediaContentItem'],
	ReturnType<typeof TabWithRevealableMediaContentItemFragment>
>
