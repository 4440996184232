import { useEffect } from 'react'

export const useAddView = (inspirationId: string) => {
	useEffect(() => {
		fetch('/api/v1/add-view', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ inspirationId }),
		})
	})
}
