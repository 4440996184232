import type { FunctionComponent, ReactNode } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { InternalLinkResult } from '../data/content/InternalLinkFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { CardTile } from './CardTile'
import { CardTileContent } from './CardTileContent'
import styles from './CarouselCard.module.sass'

export type CarouselCardProps = {
	image?: ImageResult
	videoChildren?: ReactNode
	title?: string
	isTitleWithBackground?: boolean
	text?: string
	link?: LinkResult | InternalLinkResult
	footerChildren?: ReactNode
}

export const CarouselCard: FunctionComponent<CarouselCardProps> = ({
	image,
	videoChildren,
	title,
	isTitleWithBackground = false,
	text,
	link,
	footerChildren,
}) => {
	return (
		<CardTile>
			<div className={styles.main}>
				<CardTileContent
					videoChildren={videoChildren}
					link={link}
					image={image}
					title={title}
					text={text}
					footerChildren={
						footerChildren ? <div className={styles.footer}>{footerChildren}</div> : null
					}
					isTitleWithBackground={isTitleWithBackground}
				/>
			</div>
		</CardTile>
	)
}
