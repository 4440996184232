import clsx from 'clsx'
import { FunctionComponent, useState } from 'react'
import type { RevealableMediaContentListResult } from '../data/content/RevealableMediaContentListFragment'
import { RevealableMediaContent } from './RevealableMediaContentItem'
import styles from './RevealableMediaContentList.module.sass'
import { RevealableMediaContentMobile } from './RevealableMediaContentMobile'

export type RevealableMediaContentListProps = RevealableMediaContentListResult

export const RevealableMediaContentList: FunctionComponent<RevealableMediaContentListProps> = ({
	items,
}) => {
	const [visibleItemId, setVisibleItemId] = useState<string | null>(null)

	const handleTitleClick = (itemId: string) => {
		setVisibleItemId((prevVisibleItemId) => (prevVisibleItemId === itemId ? null : itemId))
	}

	return (
		<div className={styles.wrapper}>
			<ul className={clsx(styles.list, styles.is_desktop)}>
				{items.map(
					(item) =>
						item.item && (
							<li className={clsx(styles.item)} key={item.id}>
								<RevealableMediaContent {...item.item} />
							</li>
						)
				)}
			</ul>
			<div className={styles.is_mobile}>
				<ul className={clsx(styles.list)}>
					{items.map(
						(item) =>
							item.item && (
								<li key={item.id} className={clsx(styles.mobileItem)}>
									<RevealableMediaContentMobile
										isVisible={visibleItemId === item.id}
										onTitleClick={() => handleTitleClick(item.id)}
										media={item.item}
									/>
								</li>
							)
					)}
				</ul>
			</div>
		</div>
	)
}
