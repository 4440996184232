import { useEffect, useState } from 'react'

export const useReducedMotion = () => {
	const [reducedMotion, setReducedMotion] = useState(false)
	useEffect(() => {
		const reducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
		const callback = () => {
			setReducedMotion(reducedMotionMediaQuery.matches)
		}
		reducedMotionMediaQuery.addEventListener('change', callback)
		return () => {
			reducedMotionMediaQuery.removeEventListener('change', callback)
		}
	}, [])
	return reducedMotion
}
