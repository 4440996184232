import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { RevealableMediaContentItemFragment } from './RevealableMediaContentItemFragment'

export const RevealableMediaContentListFragment = () =>
	ZeusSelect<ValueTypes['RevealableMediaContentList']>()({
		items: [{}, RevealableMediaContentItemFragment()],
	})

export type RevealableMediaContentListResult = MapType<
	ModelTypes['RevealableMediaContentList'],
	ReturnType<typeof RevealableMediaContentListFragment>
>
