import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FeatureTileFragment } from './FeatureTileFragment'

export const FeatureTileItemFragment = () =>
	ZeusSelect<ValueTypes['FeatureTileItem']>()({
		id: true,
		tile: [{}, FeatureTileFragment()],
	})

export type FeatureTileItemResult = MapType<
	ModelTypes['FeatureTileItem'],
	ReturnType<typeof FeatureTileItemFragment>
>
