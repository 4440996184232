import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ProductFragment } from './ProductFragment'

export const ProductItemFragment = () =>
	ZeusSelect<ValueTypes['ProductItem']>()({
		id: true,
		product: [{}, ProductFragment()],
	})

export type ProductItemResult = MapType<
	ModelTypes['ProductItem'],
	ReturnType<typeof ProductItemFragment>
>
