import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { FancyTitleImageItemFragment } from './FancyTitleImageItemFragment'

export const FancyTitleImageListFragment = () =>
	ZeusSelect<ValueTypes['FancyTitleImageList']>()({
		items: [
			{
				orderBy: [
					{
						order: OrderDirection.asc,
					},
				],
			},
			FancyTitleImageItemFragment(),
		],
	})

export type FancyTitleImageListResult = MapType<
	ModelTypes['FancyTitleImageList'],
	ReturnType<typeof FancyTitleImageListFragment>
>
