import clsx from 'clsx'
import { Fragment, FunctionComponent } from 'react'
import { FormFieldType } from '../../generated/content'
import type { FormResult } from '../data/content/FormFragment'
import { Button } from './Button'
import styles from './CustomForm.module.sass'
import type { FormProps } from './Form'
import { Input } from './Input'
import { TextArea } from './TextArea'
import { Wysiwyg } from './Wysiwyg'

export type CustomFormProps = {
	form: FormResult
	place?: 'card'
	isFirstInputHighlighted?: FormProps['isFirstInputHighlighted']
	isButtonFullWidth?: FormProps['isButtonFullWidth']
}

export const CustomForm: FunctionComponent<CustomFormProps> = ({
	form,
	place,
	isFirstInputHighlighted,
	isButtonFullWidth,
}) => {
	return (
		<form
			className={clsx(styles.wrapper, place && styles[`view_place_${place}`])}
			onSubmit={() => {
				console.log('@TODO submit')
			}}>
			<div className={styles.inputs}>
				{form.fields.map(({ id, field }, index) => {
					return field ? (
						<Fragment key={id}>
							{field.type === FormFieldType.textarea ? (
								<TextArea
									name={field.name}
									placeholder={field.label}
									autoComplete={field.autocomplete}
									required={field.required}
									// @TODO: value
									//value={field.value || ''}
									compact
									isInputHighlighted={index === 0 && isFirstInputHighlighted}
								/>
							) : (
								<Input
									type={field.type}
									name={field.name}
									placeholder={field.label}
									autoComplete={field.autocomplete}
									required={field.required}
									// @TODO: value
									//value={field.value || ''}
									compact
									isInputHighlighted={index === 0 && isFirstInputHighlighted}
								/>
							)}
						</Fragment>
					) : null
				})}
			</div>
			<div className={styles.button}>
				<Button type="submit" fill="blue" isFullWidth={isButtonFullWidth}>
					{form.button}
				</Button>
			</div>
			{form.note && (
				<div className={styles.note}>
					<Wysiwyg source={form.note} />
				</div>
			)}
		</form>
	)
}
