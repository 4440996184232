import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ResourceResult } from '../data/content/ResourceFragment'
import { Container } from './Container'
import styles from './FeaturedBlogPosts.module.sass'
import { Link } from './Link'

export interface FeaturedBlogPostsProps {
	featuredBlogOne: ResourceResult
	featuredBlogTwo: ResourceResult
}

export const FeaturedBlogPosts: FunctionComponent<FeaturedBlogPostsProps> = ({ ...props }) => {
	return (
		<Container>
			<div className={styles.wrapper}>
				{props.featuredBlogOne && (
					<div className={styles.firstCard}>
						<div className={styles.cardContainer}>
							{props.featuredBlogOne?.cardSmallImage && (
								<div className={styles.imageContainer}>
									<Image
										className={styles.image}
										src={props.featuredBlogOne.cardSmallImage?.url as any}
										alt="aa"
										fill
									/>
								</div>
							)}
							<div className={styles.infoContainer}>
								{props.featuredBlogOne?.title && (
									<h4 className={styles.postTitle}>{props.featuredBlogOne.title}</h4>
								)}
								{props.featuredBlogOne?.link && (
									<Link className={styles.link} link={props.featuredBlogOne.link}>
										Learn more
									</Link>
								)}
							</div>
						</div>
					</div>
				)}
				{props.featuredBlogTwo && (
					<div className={styles.secondCard}>
						<div className={styles.cardContainer}>
							{props.featuredBlogTwo?.cardSmallImage && (
								<div className={styles.imageContainer}>
									<Image
										className={styles.image}
										src={props.featuredBlogTwo.cardSmallImage?.url as any}
										alt="aa"
										fill
									/>
								</div>
							)}
							<div className={styles.infoContainer}>
								{props.featuredBlogTwo?.title && (
									<h4 className={styles.postTitle}>{props.featuredBlogTwo.title}</h4>
								)}
								{props.featuredBlogTwo?.link && (
									<Link className={styles.link} link={props.featuredBlogTwo.link}>
										Learn more
									</Link>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			<div className={styles.backLinkContainer}>
				<Link className={styles.backLink} href="/blog">
					Back to Blog
				</Link>
			</div>
		</Container>
	)
}
