import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { TabWithRevealableMediaContentItemFragment } from './TabWithRevealableMediaContentItemFragment'

export const TabWithRevealableMediaContentListFragment = () =>
	ZeusSelect<ValueTypes['TabWithRevealableMediaContentList']>()({
		items: [{}, TabWithRevealableMediaContentItemFragment()],
	})

export type TabWithRevealableMediaContentListResult = MapType<
	ModelTypes['TabWithRevealableMediaContentList'],
	ReturnType<typeof TabWithRevealableMediaContentListFragment>
>
