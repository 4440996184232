import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'

export const FormFragment = () =>
	ZeusSelect<ValueTypes['Form']>()({
		formType: true,
		hubspotFormId: true,
		hubspotFormRegion: true,
		isHubspotFormEmbed: true,
		fields: [
			{ orderBy: [{ order: OrderDirection.asc }] },
			{
				id: true,
				field: [
					{},
					{
						type: true,
						name: true,
						label: true,
						value: true,
						required: true,
						autocomplete: true,
					},
				],
			},
		],
		button: true,
		note: true,
	})

export type FormResult = MapType<ModelTypes['Form'], ReturnType<typeof FormFragment>>
