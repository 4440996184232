import { HidingHeader } from 'hiding-header-react'
import type { FunctionComponent } from 'react'
import { SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import styles from './LayoutWithSidebar.module.sass'
import { Main } from './Main'
import type { NavigationProps } from './Navigation'
import { UserHeader } from './UserHeader'

export type LayoutWithSidebarProps = {
	navigation: NavigationProps
	darkMain?: boolean
	hideNavigation?: boolean
	children: React.ReactNode
}

export const LayoutWithSidebar: FunctionComponent<LayoutWithSidebarProps> = ({
	children,
	navigation,
	darkMain,
	hideNavigation,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<HidingHeader>
					{/* @TODO: navigation */}
					{/* @TODO: add layout to header | create another header component for generic layout */}
					<UserHeader />
				</HidingHeader>
				<SharedProgressLoadingIndicator />
				{/* @TODO: rename component ? */}
				<Main navigation={navigation} dark={darkMain} hideNavigation={hideNavigation}>
					{children}
				</Main>
			</div>
		</div>
	)
}
