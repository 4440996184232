import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import type { ProductResult } from '../data/content/ProductFragment'
import { lazyBoundary } from '../images/configuration'
import { CardTile } from './CardTile'
import { Link } from './Link'
import styles from './ProductCard.module.sass'

export type ProductCardProps = ProductResult

export const ProductCard: FunctionComponent<ProductCardProps> = ({
	icon,
	name,
	description,
	link,
	image,
	backgroundColor,
}) => {
	const colors: Record<any, any> = {
		green: '#DFFA9E',
		purple: '#F0E6FF',
		blue: '#E2F1FD',
		pink: '#FCE5EF',
	}

	return (
		<div className={styles.wrapper}>
			<CardTile>
				<Link link={link} className={styles.link}>
					<div className={styles.main} style={{ backgroundColor: colors[backgroundColor as any] }}>
						<div className={styles.content}>
							<div style={{ width: '100%', display: 'flex', gap: '10px' }}>
								{icon && (
									<div
										className={styles.icon}
										style={
											{
												'--ProductCard-icon-width': icon.width,
												'--ProductCard-icon-height': icon.height,
											} as React.CSSProperties
										}>
										<Image
											lazyBoundary={lazyBoundary}
											src={icon.url}
											layout="fill"
											objectFit="contain"
											alt={icon.alt ?? ''}
										/>
									</div>
								)}
								{name && <h3 className={styles.name}>{name}</h3>}
							</div>
							{description && <p className={styles.description}>{description}</p>}
						</div>
						<div className={styles.imageContainer}>
							{image?.url && (
								<Image layout="fill" objectFit="fill" src={image?.url} alt="dwqdqwdqw" />
							)}
						</div>
					</div>
				</Link>
			</CardTile>
		</div>
	)
}
