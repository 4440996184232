import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { VideoSelectFragment } from './VideoSelectFragment'

export const TabWithRevealableMediaContentFragment = () =>
	ZeusSelect<ValueTypes['TabWithRevealableMediaContent']>()({
		title: true,
		text: true,
		tabImage: [{}, ImageFragment()],
		tags: [
			{},
			{
				id: true,
				text: true,
			},
		],
		videoSelect: [{}, VideoSelectFragment()],
	})

export type TabWithRevealableMediaContentResult = MapType<
	ModelTypes['TabWithRevealableMediaContent'],
	ReturnType<typeof TabWithRevealableMediaContentFragment>
>
