import { useMutation } from '@tanstack/react-query'
import {
	Dispatch,
	FormEvent,
	FunctionComponent,
	SetStateAction,
	useCallback,
	useRef,
	useState,
} from 'react'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { FormResult } from '../data/content/FormFragment'
import { WaitlistFormInputs } from '../forms/WaitlistForm/WaitlistFormInputs'
import { validateZod } from '../validators/utils/validateZod'
import { Button, ButtonProps } from './Button'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import { Icon } from './Icon'
import { Modal } from './Modal'
import styles from './WaitlistForm.module.sass'

export type WaitlistFormProps = {
	formId: FormResult['hubspotFormId']
	submitButtonColor?: ButtonProps['fill']
}

export const WaitlistForm: FunctionComponent<WaitlistFormProps> = ({
	formId,
	submitButtonColor = 'blue',
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const formRef = useRef<HTMLFormElement>(null)

	const onSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault()

			setIsModalOpen(!isModalOpen)
		},
		[isModalOpen]
	)

	return (
		<div className={styles.wrapper}>
			<form ref={formRef} className={styles.form} onSubmit={(event) => onSubmit(event)}>
				<div className={styles.button}>
					<Button type="submit" fill={submitButtonColor}>
						Join the waitlist
					</Button>
				</div>
			</form>
			<ModalForm formId={formId} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
		</div>
	)
}

type ModalFormProps = {
	formId: WaitlistFormProps['formId']
	setIsModalOpen: Dispatch<SetStateAction<boolean>>
	isModalOpen: boolean
}

const ModalForm: FunctionComponent<ModalFormProps> = ({ formId, setIsModalOpen, isModalOpen }) => {
	const [isFormSubmitted, setIsFormSubmitted] = useState(false)

	const mutation = useMutation((formData: FormData) => {
		const validateData = validateZod(formData, WaitlistFormInputs())
		const api = createFetcher<API>('/api/v1/[handler]')

		return api('waitlistForm', validateData)
	})

	const onFormSubmit = useCallback(
		(form: HTMLFormElement) => {
			const formData = new FormData(form)
			mutation.mutate(formData)

			return null
		},
		[mutation]
	)

	const onFormSubmitted = useCallback(() => {
		setIsFormSubmitted(true)
	}, [])

	return (
		<Modal
			isOpen={isModalOpen}
			onRequestClose={() => {
				setIsModalOpen(false)
			}}>
			<div className={styles.modal}>
				<div className={styles.modalContent}>
					<button
						type="button"
						className={styles.modalCloseButton}
						aria-label="Close form"
						onClick={() => {
							setIsModalOpen(false)
							setIsFormSubmitted(false)
						}}>
						<Icon name="close" />
					</button>
					<h3 className={styles.modalTitle}>
						{isFormSubmitted ? (
							<>
								<div className={styles.submittedIcon}>✅</div> Submitted!
							</>
						) : (
							'Join the waitlist'
						)}
					</h3>
					<HubspotEmbedForm
						formId={formId}
						isWaitlist
						onFormSubmit={onFormSubmit}
						onFormSubmitted={onFormSubmitted}
					/>
				</div>
			</div>
		</Modal>
	)
}
