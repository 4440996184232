import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { ImageCarouselItemFragment } from './ImageCarouselItemFragment'

export const ImageCarouselFragment = () =>
	ZeusSelect<ValueTypes['ImageCarousel']>()({
		items: [{ orderBy: [{ order: OrderDirection.asc }] }, ImageCarouselItemFragment()],
	})

export type ImageCarouselResult = MapType<
	ModelTypes['ImageCarousel'],
	ReturnType<typeof ImageCarouselFragment>
>
