import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageCarouselResult } from '../data/content/ImageCarouselFragment'
import { CarouselCards } from './CarouselCards'
import styles from './ImageCarousel.module.sass'

export type ImageCarouselProps = ImageCarouselResult

export const ImageCarousel: FunctionComponent<ImageCarouselProps> = ({ items }) => {
	return (
		<CarouselCards
			options={{
				slidesPerView: 1,
				gapSize: 'small',
				displayPagination: true,
			}}
			cards={{
				type: 'bannerTestimonial',
				list: items.map((item: any) => {
					return {
						id: item.id,
						children: (
							<div className={styles.cardWrapper}>
								<div className={styles.cardMain}>
									<div className={styles.imageContainer}>
										<Image
											className={styles.image}
											src={item.image.url}
											alt={item.image.alt || item.image?.fileName}
											fill
										/>
									</div>
								</div>
								{item.primaryText && (
									<div className={styles.imageTitleContainer}>
										<span className={styles.imageTitle}>{item.primaryText}</span>
									</div>
								)}
							</div>
						),
					}
				}),
			}}
		/>
	)
}
