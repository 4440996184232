import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import type { ContentResult } from '../data/content/ContentFragment'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'

export type GenericPageProps = NonNullable<CommonPageProps['page']['genericPage']>

export const GenericPage: FunctionComponent<GenericPageProps> = ({ activeRevision }) => {
	if (!activeRevision?.content || !activeRevision.contentWidthType) {
		return null
	}

	return (
		<div
			className={clsx(
				styles.wrapper
				// activeRevision.contentWidthType && styles[`is_content_${activeRevision.contentWidthType}`]
			)}>
			<ContentRenderer
				contentWidthType={activeRevision.contentWidthType!}
				content={activeRevision.content as ContentResult}
			/>
		</div>
	)
}
