import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { VideoFragment } from './VideoFragment'

export const MediumFragment = () =>
	ZeusSelect<ValueTypes['Medium']>()({
		type: true,
		image: [{}, ImageFragment()],
		video: [{}, VideoFragment()],
		wistiaID: true,
	})

export type MediumResult = MapType<ModelTypes['Medium'], ReturnType<typeof MediumFragment>>
