import type { FunctionComponent } from 'react'
import type { ExtensionListResult } from '../data/content/ExtensionListFragment'
import { Container } from './Container'
import { ExtensionCardTile } from './ExtensionCardTile'
import styles from './ExtensionGridCards.module.sass'

export type ExtensionGridCardsProps = ExtensionListResult

export const ExtensionGridCards: FunctionComponent<ExtensionGridCardsProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.list}>
					{items.map(
						(item) =>
							item && (
								<div key={item.id} className={styles.item}>
									<ExtensionCardTile {...item} />
								</div>
							)
					)}
				</div>
			</Container>
		</div>
	)
}
