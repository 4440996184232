import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useState } from 'react'
import styles from './ChatBubble.module.sass'

export interface ChatBubbleProps {
	text: string
	sender: 'local' | 'remote'
	images?: Array<{
		src: string
		width: number
		height: number
	}>
	isActive: boolean
}

export const ChatBubble: FunctionComponent<ChatBubbleProps> = ({
	text,
	sender,
	images = [],
	isActive,
}) => {
	const [delayedIsActive, setdelayedIsActive] = useState(false)

	useEffect(() => {
		requestAnimationFrame(() => {
			setdelayedIsActive(isActive)
		})
	}, [isActive])

	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_sender_${sender}`],
				delayedIsActive && styles.is_active
			)}>
			<div className={styles.in}>
				<div className={styles.text}>{text}</div>
				{images.length > 0 && (
					<div className={styles.images}>
						{images.map(({ src }, i) => (
							<div className={styles.image} key={i}>
								<Image src={src} alt="" fill />
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	)
}
