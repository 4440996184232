import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Container } from './Container'
import type { ReactVideoProps } from './ReactVideo'
import { ReactVideoWithNoSSR } from './ReactVideoWithNoSSR'
import styles from './Video.module.sass'
import { WistiaEmbedPlayer } from './WistiaEmbedPlayer'

export type VideoProps = ReactVideoProps & {
	rounded?: boolean | undefined
	primaryText?: string
}

export const Video: FunctionComponent<VideoProps> = (props) => {
	function extractWistiaId(url?: string) {
		return url?.split('/medias/')[1]
	}

	const url: string | undefined = props.wistiaUrl
	const wistiaId: string | undefined = extractWistiaId(url)
	console.log(wistiaId)

	return (
		<div className={styles.wrapper}>
			<Container>
				{props.videoType === 'wistia' ? (
					<div className={clsx(styles.video, props.rounded && styles.roundedStyles)}>
						<WistiaEmbedPlayer videoId={wistiaId as string} wrapper={`wistia-${props.wistiaID}`} />
					</div>
				) : (
					<>
						<div className={clsx(styles.video, props.rounded && styles.roundedStyles)}>
							<ReactVideoWithNoSSR {...props} />
						</div>
						{props.videoSource && (
							<div className={styles.sourceContainer}>
								<span className={styles.videoSource}>{props.videoSource}</span>
							</div>
						)}
					</>
				)}
			</Container>
		</div>
	)
}
