import clsx from 'clsx'
import Image from 'next/legacy/image'
import React, { FunctionComponent, useState } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { ProductListResult } from '../data/content/ProductListFragment'

import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import { lazyBoundary } from '../images/configuration'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Icon } from './Icon'
import styles from './PricingProductTabs.module.sass'

export type PricingProductTabsProps = ProductListResult & {
	title?: string
}

export const PricingProductTabs: FunctionComponent<PricingProductTabsProps> = ({
	title,
	items,
}) => {
	const activePage = useActivePage()
	const [isActiveProductId, setActiveProductId] = useState(items[0].id)
	const colors: Record<any, any> = {
		green: '#DFFA9E',
		purple: '#F0E6FF',
		blue: '#E2F1FD',
		pink: '#FCE5EF',
	}

	return (
		<div className={styles.wrapper}>
			{items && items.length > 0 && (
				<div className={styles.main}>
					<div className={styles.header}>
						{title && (
							<h2 className={styles.title}>
								<CerosRichTextRenderer source={title} />
							</h2>
						)}
						{title && !activePage.startsWith('/pricing') && (
							<div className={styles.iconWrapper}>
								<Icon name={'exploreTheSuite'} />
							</div>
						)}
					</div>

					<div className={styles.contents}>
						{items.map((product: any) => (
							<div
								key={product.id}
								style={{ backgroundColor: colors[product.product.backgroundColor as any] }}
								className={clsx(styles.content)}>
								<div className={styles.textContent}>
									{product.product?.name && (
										<div className={styles.contentHeader}>
											{product.product.icon && (
												<IconElement image={product.product.icon} isInContentHeader />
											)}
											<h3 className={styles.name}>{product.product.name}</h3>
										</div>
									)}
									{product.product?.description && (
										<p className={styles.description}>{product.product.description}</p>
									)}
									{product.product.pricingLink && (
										<div style={{ width: '100%', padding: '2rem 0rem 0rem 0rem' }}>
											<Button
												style={{
													width: '100%',
												}}
												type="link"
												href={contemberLinkToHref(product.product.pricingLink!)}
												fill="blue"
												target={
													product.product.pricingLink?.type !== LinkType.scrollToTarget &&
													product.product.pricingLink?.isTargetBlank
														? '_blank'
														: undefined
												}>
												{product.product.pricingLink?.title}
											</Button>
										</div>
									)}
								</div>
								<div className={styles.imageContainer}>
									{product.product?.pricingImage?.url && (
										<Image
											className={styles.imageStyles}
											src={product.product?.pricingImage?.url}
											alt="chat"
											layout="fill"
											objectFit="fill"
										/>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

type IconElementProps = {
	image: ImageResult
	isInContentHeader?: boolean
}

const IconElement: FunctionComponent<IconElementProps> = ({ image, isInContentHeader }) => {
	return (
		<div
			className={clsx(styles.icon, isInContentHeader && styles.view_inContentHeader)}
			style={
				{
					'--ProductTab-icon-width': image.width,
					'--ProductTab-icon-height': image.height,
				} as React.CSSProperties
			}>
			<Image
				lazyBoundary={lazyBoundary}
				src={image.url}
				layout="fill"
				objectFit="contain"
				alt={image.alt ?? ''}
			/>
		</div>
	)
}
