import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FormFragment } from './FormFragment'

export const BannerFragment = () =>
	ZeusSelect<ValueTypes['Banner']>()({
		type: true,
		form: [{}, FormFragment()],
	})

export type BannerResult = MapType<ModelTypes['Banner'], ReturnType<typeof BannerFragment>>
