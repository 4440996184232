import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { LogoListFragment } from './LogoListFragment'

export const GridBoxFragment = () =>
	ZeusSelect<ValueTypes['GridBox']>()({
		id: true,
		backgroundImage: [{}, ImageFragment()],
		backgroundType: true,
		title: true,
		isTitleBigger: true,
		isLogoList: true,
		logoList: [{}, LogoListFragment()],
		text: true,
		image: [{}, ImageFragment()],
		mobileImage: [{}, ImageFragment()],
	})

export type GridBoxResult = MapType<ModelTypes['GridBox'], ReturnType<typeof GridBoxFragment>>
