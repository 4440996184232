import clsx from 'clsx'
import { FunctionComponent, useState } from 'react'
import { LinkType } from '../../generated/content'
import type { KeywordsTileResult } from '../data/content/KeywordsTileFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useParallax } from '../utilities/useParallax'
import { Button } from './Button'
import { Container } from './Container'
import styles from './KeywordsTile.module.sass'

export type KeywordsTileProps = KeywordsTileResult

export const KeywordsTile: FunctionComponent<KeywordsTileProps> = ({
	firstKeyword,
	secondKeyword,
	thirdKeyword,
	text,
	link,
}) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
	const offset = useParallax(wrapper, 0, 0.1)

	return (
		<div
			className={styles.keywordsTileBackground}
			ref={setWrapper}
			style={{
				'--KeywordsTile-offset': `${offset}`,
			}}>
			<Container size="normal">
				<div className={styles.keywordsTile}>
					<div className={styles.keywordWrapper}>
						<p className={clsx(styles.keyword, styles.is_color_black)}>{firstKeyword}</p>
						<div
							className={clsx(
								styles.object,
								styles.is_color_blue,
								styles.is_wide,
								styles.is_device_mobile
							)}></div>
						<div
							className={clsx(styles.object, styles.is_color_red, styles.is_device_tablet)}></div>
					</div>
					<div className={styles.wrapper}>
						<div className={styles.keywordContainer}>
							<div className={styles.keywordWrapper}>
								<div className={clsx(styles.object, styles.is_color_black)}></div>
								<p className={clsx(styles.keyword, styles.is_color_blue)}>{secondKeyword}</p>
							</div>
							<p className={clsx(styles.keyword, styles.is_color_red)}>{thirdKeyword}</p>
						</div>
						<div className={styles.textWrapper}>
							<p className={clsx(styles.text, styles.is_wide)}>{text}</p>
							{link && (
								<div className={styles.link}>
									<Button
										type="link"
										href={contemberLinkToHref(link)}
										fill="blue"
										target={
											link.type !== LinkType.scrollToTarget && link.isTargetBlank
												? '_blank'
												: undefined
										}>
										{link.title}
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
