import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import type { ProductResult } from '../data/content/ProductFragment'
import { lazyBoundary } from '../images/configuration'
import { Button } from './Button'
import { Link } from './Link'
import styles from './ProductTile.module.sass'

export type ProductTileProps = {
	product: ProductResult
}

export const ProductTile: FunctionComponent<ProductTileProps> = ({ product }) => {
	return (
		<Link link={product.link} className={styles.wrapper}>
			<div className={styles.logo}>
				{product.icon && (
					<div className={styles.logo_in}>
						<Image
							lazyBoundary={lazyBoundary}
							src={product.icon.url}
							layout="fill"
							objectFit="contain"
							alt=""
						/>
					</div>
				)}
			</div>
			<div className={styles.about}>
				{product.name && <h3 className={styles.name}>{product.name}</h3>}
				{product.description && <p className={styles.description}>{product.description}</p>}
			</div>
			<div className={styles.action}>
				<Button type="button" size="small" fill="dark">
					Open
				</Button>
			</div>
		</Link>
	)
}
