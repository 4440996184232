import Link from 'next/link'
import { AddArrowBelowText } from './AddArrowBelowText'
import styles from './ThankYou.module.sass'
export const ThankYou = () => {
	return (
		<div className={styles.thankYouWrapper}>
			<div className={styles.leftSide}>
				<p className={styles.leftSideTitle}>
					We can’t{' '}
					<AddArrowBelowText children={'wait'} shape={'arrow8' as any} iconColor="#FE5621" /> to
					meet you!
				</p>
				<span className={styles.leftSideQuote}>
					A member of our team will reach <br /> out shortly. Until then...
				</span>
			</div>
			<div className={styles.rightSide}>
				<div className={styles.card}>
					<div className={styles.cardContentWrapper}>
						<div className={styles.cardTitleContainer}>
							{/* <svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="20"
								viewBox="0 0 30 20"
								fill="none">
								<path
									d="M12.0177 3.98039C12.5852 3.00543 12.2507 1.76006 11.2705 1.19879C10.2903 0.637518 9.03558 0.972881 8.46802 1.94784L0.276389 16.0196C-0.29117 16.9946 0.0433475 18.2399 1.02356 18.8012C2.00376 19.3625 3.25848 19.0271 3.82604 18.0521L12.0177 3.98039Z"
									fill="#FF9A9A"
								/>
								<path
									d="M17.6963 3.98058C17.1288 3.00561 17.4633 1.76025 18.4435 1.19897C19.4237 0.637701 20.6784 0.973064 21.246 1.94803L25.3388 8.97881C25.9064 9.95377 25.5719 11.1991 24.5916 11.7604C23.6114 12.3217 22.3567 11.9863 21.7892 11.0114L17.6963 3.98058Z"
									fill="#FF9A9A"
								/>
								<path
									d="M21.2403 3.98058C21.8079 3.00561 21.4734 1.76025 20.4932 1.19897C19.513 0.637701 18.2582 0.973064 17.6907 1.94803L9.49905 16.0198C8.93149 16.9947 9.266 18.2401 10.2462 18.8014C11.2264 19.3626 12.4811 19.0273 13.0487 18.0523L21.2403 3.98058Z"
									fill="#FF0000"
								/>
								<path
									d="M8.47365 3.98058C7.9061 3.00561 8.24061 1.76025 9.22082 1.19897C10.201 0.637701 11.4557 0.973064 12.0233 1.94803L20.2149 16.0198C20.7825 16.9947 20.448 18.2401 19.4678 18.8014C18.4876 19.3626 17.2328 19.0273 16.6653 18.0523L8.47365 3.98058Z"
									fill="#1B00FB"
								/>
								<path
									d="M29.5612 16.5783C29.5612 17.9563 28.4413 19.0734 27.0599 19.0734C25.6785 19.0734 24.5586 17.9563 24.5586 16.5783C24.5586 15.2003 25.6785 14.0832 27.0599 14.0832C28.4413 14.0832 29.5612 15.2003 29.5612 16.5783Z"
									fill="#1B00FB"
								/>
							</svg>{' '} */}
							<p className={styles.cardTitle}> Ceros Inspire </p>
						</div>

						<p className={styles.cardDescription}>
							Discover our curated content gallery and premium templates, all created with Ceros.
						</p>
						<Link className={styles.linkButton} href="https://www.ceros.com/inspire/">
							View content
						</Link>
					</div>
				</div>
				{/* <div className={styles.card}>
					<div className={styles.cardContentWrapper}>
						<div className={styles.cardTitleContainer}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="19"
								height="28"
								viewBox="0 0 19 28"
								fill="none">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M8.31956 18.0584C12.4289 18.0584 15.7602 14.7271 15.7602 10.6178C15.7602 6.50843 12.4289 3.17714 8.31956 3.17714C4.2102 3.17714 0.878906 6.50843 0.878906 10.6178C0.878906 14.7271 4.2102 18.0584 8.31956 18.0584ZM8.31956 14.6763C10.561 14.6763 12.3781 12.8593 12.3781 10.6178C12.3781 8.37632 10.561 6.55925 8.31956 6.55925C6.07809 6.55925 4.26102 8.37632 4.26102 10.6178C4.26102 12.8593 6.07809 14.6763 8.31956 14.6763Z"
									fill="#1B00FB"
								/>
								<path
									d="M18.9488 2.88734C18.9488 4.22155 17.8672 5.30313 16.533 5.30313C15.1988 5.30313 14.1172 4.22155 14.1172 2.88734C14.1172 1.55313 15.1988 0.471542 16.533 0.471542C17.8672 0.471542 18.9488 1.55313 18.9488 2.88734Z"
									fill="#FF0000"
								/>
								<path
									d="M13.5809 25.349C14.9763 23.9537 15.7602 22.0611 15.7602 20.0877C15.7602 19.1538 15.0031 18.3967 14.0692 18.3967C13.1352 18.3967 12.3781 19.1538 12.3781 20.0877C12.3781 22.3292 10.561 24.1462 8.31956 24.1462C6.07809 24.1462 4.26102 22.3292 4.26102 20.0877C4.26102 19.1538 3.50391 18.3967 2.56996 18.3967C1.63602 18.3967 0.878906 19.1538 0.878906 20.0877C0.878907 22.0611 1.66283 23.9537 3.05822 25.349C4.45362 26.7444 6.34617 27.5284 8.31956 27.5284C10.2929 27.5284 12.1855 26.7444 13.5809 25.349Z"
									fill="#FF9A9A"
								/>
							</svg>
							<p className={styles.cardTitle}> Gemma </p>
						</div>

						<p className={styles.cardDescription}>Supercharge your designs with AI</p>
						<Link className={styles.linkButton} href="/gemma">
							Learn about Gemma
						</Link>
					</div>
				</div> */}
			</div>
		</div>
	)
}
