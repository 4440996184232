import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useState } from 'react'
import type { BackgroundType } from '../../generated/content'
import type { TabsWithMediaListResult } from '../data/content/TabsWithMediaListFragment'
import { BreakableText } from './BreakableContent'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import styles from './TabsWithMedia.module.sass'
import { WaitlistForm } from './WaitlistForm'

export type TabsWithMediaProps = TabsWithMediaListResult & {
	title?: string
	text?: string
	backgroundColor?: BackgroundType
}

export const TabsWithMedia: FunctionComponent<TabsWithMediaProps> = ({
	title,
	text,
	items,
	backgroundColor,
}) => {
	const [activeTabId, setActiveTabId] = useState(items[0]?.id ?? null)
	return (
		<div className={clsx(styles.wrapper, styles[`is_background_${backgroundColor}`])}>
			{title && <h3 className={styles.title}>{title}</h3>}
			{text && (
				<p className={styles.text}>
					<BreakableText text={text} />
				</p>
			)}
			{items.length > 0 && (
				<div className={styles.main}>
					<div className={styles.column}>
						{items.map(
							(item) =>
								item && (
									<div
										key={item.id}
										className={clsx(styles.media, activeTabId === item.id && styles.is_active)}>
										{item.image && (
											<Image
												className={styles.image}
												src={item.image.url}
												alt={item.image.alt ?? item.image.fileName ?? ''}
												width={item.image.width}
												height={item.image.height}
											/>
										)}
									</div>
								)
						)}
					</div>
					<div className={styles.column}>
						<ul className={styles.tabs}>
							{items.map(
								(item) =>
									item && (
										<li key={item.id} className={styles.tab}>
											<button
												type="button"
												className={clsx(
													styles.tabButton,
													activeTabId === item.id && styles.is_active
												)}
												onClick={() => setActiveTabId(item.id)}>
												{item.tabTitle}
											</button>
										</li>
									)
							)}
						</ul>
						{items.map(
							(item) =>
								item && (
									<div
										key={item.id}
										className={clsx(styles.content, activeTabId === item.id && styles.is_active)}>
										{item.title && <h3 className={styles.contentTitle}>{item.title}</h3>}
										{item.text && <p className={styles.contentText}>{item.text}</p>}
										{item.form?.hubspotFormId && (
											<div className={styles.contentForm}>
												{item.isFormWaitlist ? (
													<WaitlistForm
														formId={item.form.hubspotFormId}
														submitButtonColor="chartreuseGreen"
													/>
												) : (
													<HubspotEmbedForm
														formId={item.form.hubspotFormId}
														region={item.form.hubspotFormRegion}
													/>
												)}
											</div>
										)}
									</div>
								)
						)}
					</div>
				</div>
			)}
		</div>
	)
}
