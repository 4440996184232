import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useState } from 'react'
import styles from './Input.module.sass'

export type InputProps = Pick<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	| 'name'
	| 'type'
	| 'autoComplete'
	| 'value'
	| 'onChange'
	| 'disabled'
	| 'readOnly'
	| 'placeholder'
	| 'required'
	| 'hidden'
> & {
	compact?: boolean
	isInputHighlighted?: boolean
	isBackgroundColorTransparent?: boolean
	isColorWhite?: boolean
	isPlaceholderWhite?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			name,
			type,
			autoComplete,
			value,
			onChange,
			disabled,
			readOnly,
			placeholder,
			required,
			hidden,
			compact,
			isInputHighlighted = false,
			isBackgroundColorTransparent = false,
			isColorWhite = false,
			isPlaceholderWhite = false,
		},
		ref
	) => {
		const [isHighlighted, setHighlighted] = useState(isInputHighlighted)
		return (
			<input
				className={clsx(
					styles.wrapper,
					compact && styles.view_compact,
					isHighlighted && styles.is_highlighted,
					isBackgroundColorTransparent && styles.is_backgroundColor_transparent,
					isColorWhite && styles.is_color_white,
					isPlaceholderWhite && styles.is_placeholder_white
				)}
				name={name}
				type={type}
				autoComplete={autoComplete}
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				required={required}
				hidden={hidden}
				ref={ref}
				onFocus={() => setHighlighted(false)}
			/>
		)
	}
)

Input.displayName = 'Input'
