import { useEffect, useState } from 'react'
import { useReducedMotion } from './useReducedMotion'

/**
 * @param elementCenterOfGravity 0 for top edge, 1 for bottom edge
 * @param viewportCenterOfGravity 0 for top edge, 1 for bottom edge
 */
export const useParallax = (
	element: HTMLElement | null,
	elementCenterOfGravity = 0.5,
	viewportCenterOfGravity = 0.5
) => {
	const [offset, setOffset] = useState(0)
	const reducedMotion = useReducedMotion()

	useEffect(() => {
		if (element === null) {
			return
		}
		if (reducedMotion) {
			setOffset(0)
			return
		}

		const callback = () => {
			const rect = element.getBoundingClientRect()
			const elementY = rect.top + rect.height * elementCenterOfGravity
			const viewportY = window.innerHeight * viewportCenterOfGravity
			setOffset(elementY - viewportY)
		}
		callback()
		window.addEventListener('scroll', callback, { passive: true })
		return () => {
			window.removeEventListener('scroll', callback)
		}
	}, [element, elementCenterOfGravity, reducedMotion, viewportCenterOfGravity])
	return offset
}
