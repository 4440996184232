import type { FunctionComponent } from 'react'
import type { ProductListResult } from '../data/content/ProductListFragment'
import { CarouselCards } from './CarouselCards'
import { Container } from './Container'
import { ProductCard } from './ProductCard'
import styles from './ProductListSection.module.sass'
import { ProductTabs } from './ProductTabs'

export type ProductListSectionProps = ProductListResult & {
	title?: string
}

export const ProductListSection: FunctionComponent<ProductListSectionProps> = ({
	title,
	items,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.view_mobile}>
				<Container disableGutters>
					<ProductTabs items={items} title={title} />
				</Container>
			</div>
			<div className={styles.view_desktop}>
				<Container size="wide" disableGutters>
					<CarouselCards
						title={title}
						cards={{
							type: 'product',
							list: items.map((item) => {
								return { children: item.product && <ProductCard {...item.product} />, id: item.id }
							}),
						}}
					/>
				</Container>
			</div>
		</div>
	)
}
