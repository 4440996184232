import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import type { AccordionItemResult } from '../data/content/AccordionItemFragment'
import styles from './AsideContentAccordionItem.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type AsideContentAccordionItemProps = AccordionItemResult & {
	isItemOpenId: string | null
	setItemOpenId: Dispatch<SetStateAction<string | null>>
}

export const AsideContentAccordionItem: FunctionComponent<AsideContentAccordionItemProps> = ({
	id,
	name,
	description,
	isItemOpenId,
	setItemOpenId,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div className={styles.header}>
					<button
						type="button"
						className={clsx(styles.button, isItemOpenId === id && styles.resetPadding)}
						onClick={() => setItemOpenId(isItemOpenId === id ? null : id)}>
						{name}
						<div className={styles.mark}>{isItemOpenId === id ? '—' : '+'}</div>
					</button>
				</div>
				<Collapsible open={isItemOpenId === id}>
					<div className={clsx(styles.body, isItemOpenId === id && styles.resetPadding)}>
						{description && <Wysiwyg source={description} />}
					</div>
				</Collapsible>
			</div>
		</div>
	)
}
