import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const ResourceCategoryFragment = () =>
	ZeusSelect<ValueTypes['ResourceCategory']>()({
		id: true,
		name: true,
		slug: true,
	})

export type ResourceCategoryResult = MapType<
	ModelTypes['ResourceCategory'],
	ReturnType<typeof ResourceCategoryFragment>
>
