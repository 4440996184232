import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { useIsInView } from '../utilities/useIsInView'
import { Container } from './Container'
import styles from './HumblebragsTwo.module.sass'
import { Icon } from './Icon'

export const HumblebragsTwo = () => {
	const { y: scrollY } = useWindowScroll()

	const [rotationDegrees, setRotationDegrees] = useState<number>(0)

	const starRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(starRef, {
		threshold: 0.1,
	})

	useEffect(() => {
		setRotationDegrees(scrollY * 0.75)
	}, [scrollY])

	return (
		<Container size={'normal'}>
			<div className={styles.parent}>
				<div
					className={clsx(styles.div1, styles.coloredBoxContainer)}
					style={{ backgroundColor: '#FE5621' }}>
					<div className={styles.damnWrapper}>
						<Icon name="damn" />
					</div>
					<p className={styles.percentage} style={{ color: '#fff' }}>
						96%
					</p>
					<p style={{ color: '#fff' }} className={styles.message}>
						would recommend Ceros <br /> to a friend or colleague
					</p>
				</div>
				<div
					className={styles.shape1}
					style={{
						color: '#CFC3FF',
					}}>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 74 47"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<g id="Shapes">
							<path
								id="Union"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M73.4237 33.9544C73.4237 29.5757 71.1772 25.7218 67.7735 23.4817C71.1772 21.2416 73.4237 17.3878 73.4237 13.0091C73.4237 6.08893 67.812 0.479321 60.8897 0.479321C55.469 0.479321 50.8519 3.91931 49.1039 8.73449C47.3559 3.91931 42.7388 0.479321 37.3181 0.479321C31.6911 0.479321 26.93 4.18595 25.3453 9.29041C23.7606 4.18595 18.9995 0.479321 13.3724 0.479321C6.4501 0.479321 0.838379 6.08893 0.838379 13.0091C0.838379 17.3878 3.08493 21.2416 6.48865 23.4817C3.08493 25.7218 0.838379 29.5757 0.838379 33.9544C0.838379 40.8745 6.4501 46.4841 13.3724 46.4841C18.9995 46.4841 23.7606 42.7775 25.3453 37.673C26.93 42.7775 31.6911 46.4841 37.3181 46.4841C42.7388 46.4841 47.3559 43.0441 49.1039 38.229C50.8519 43.0441 55.469 46.4841 60.8897 46.4841C67.812 46.4841 73.4237 40.8745 73.4237 33.9544Z"
								fill="currentColor"
							/>
						</g>
					</svg>
				</div>
				<div className={clsx(styles.div2, styles.coloredBoxContainer)}>
					<p className={styles.percentage} style={{ color: '#0A0A0E' }}>
						98%
					</p>
					<p style={{ color: '#0A0A0E' }} className={styles.message}>
						rated us as having highly innovative <br /> products and services
					</p>
				</div>
				<div className={styles.shape2}>
					<svg
						width="80%"
						height="80%"
						viewBox="0 0 385 195"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M192.5 92.2531C248.417 92.2531 293.93 138.334 293.93 195L385 195C384.977 87.2945 298.802 0 192.5 0C86.1981 0 0 87.2945 0 195H91.0704C91.0704 138.357 136.56 92.2531 192.5 92.2531Z"
							fill="#DFFA9E"
						/>
					</svg>
				</div>
				<div
					style={{ background: '#FFCDE5' }}
					className={clsx(styles.div3, styles.coloredBoxContainer)}>
					<p className={styles.percentage} style={{ color: '#0A0A0E' }}>
						+600
					</p>
					<p style={{ color: '#0A0A0E' }} className={styles.message}>
						Of the world’s <br /> leading brands <br /> use Ceros products
					</p>
				</div>
				<div className={styles.shape3} ref={starRef}>
					<svg
						style={{
							transition: 'transform 0.2s linear',
							transform: `rotate(${rotationDegrees}deg)`,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="50%"
						height="50%"
						viewBox="0 0 196 196"
						fill="none">
						<g clip-path="url(#clip0_2053_807)">
							<path
								d="M190.137 90.5235C196.886 92.8271 196.886 102.371 190.137 104.675L171.606 111.001C166.574 112.719 164.892 118.993 168.392 122.997L181.277 137.74C185.97 143.11 181.198 151.375 174.202 149.996L154.99 146.209C149.773 145.18 145.18 149.773 146.209 154.99L149.996 174.202C151.375 181.198 143.11 185.97 137.74 181.277L122.997 168.392C118.993 164.892 112.719 166.574 111.001 171.606L104.675 190.137C102.371 196.886 92.8271 196.886 90.5235 190.137L84.1978 171.606C82.4799 166.574 76.2058 164.892 72.2019 168.392L57.4581 181.277C52.0889 185.97 43.8232 181.198 45.2025 174.202L48.9898 154.99C50.0184 149.773 45.4254 145.18 40.2082 146.209L20.9969 149.996C14.0008 151.375 9.22865 143.11 13.9212 137.74L26.8068 122.997C30.3061 118.993 28.6249 112.719 23.5925 111.001L5.06132 104.675C-1.68711 102.371 -1.68711 92.8271 5.06132 90.5235L23.5925 84.1978C28.625 82.4799 30.3061 76.2058 26.8068 72.2019L13.9212 57.4581C9.22865 52.0889 14.0009 43.8233 20.997 45.2025L40.2082 48.9899C45.4254 50.0184 50.0184 45.4254 48.9898 40.2082L45.2025 20.9969C43.8232 14.0008 52.0889 9.22866 57.4581 13.9212L72.2019 26.8068C76.2058 30.3061 82.4799 28.6249 84.1978 23.5925L90.5235 5.06132C92.8271 -1.68711 102.371 -1.6871 104.675 5.06132L111.001 23.5925C112.719 28.6249 118.993 30.3061 122.997 26.8068L137.74 13.9212C143.11 9.22865 151.375 14.0008 149.996 20.9969L146.209 40.2082C145.18 45.4254 149.773 50.0184 154.99 48.9899L174.202 45.2025C181.198 43.8232 185.97 52.0889 181.277 57.4581L168.392 72.2019C164.892 76.2058 166.574 82.4799 171.606 84.1978L190.137 90.5235Z"
								fill="#99D2FB"
							/>
						</g>
						<defs>
							<clipPath id="clip0_2053_807">
								<rect
									width="195.199"
									height="195.198"
									fill="white"
									transform="translate(195.198) rotate(90)"
								/>
							</clipPath>
						</defs>
					</svg>
				</div>
				<div
					style={{ background: '#FAFAFA' }}
					className={clsx(styles.div4, styles.coloredBoxContainer)}>
					<div className={styles.shoulderBrushingWrapper}>
						<Icon name="shoulderBrushing" />
					</div>
					<p style={{ color: '#0A0A0E' }} className={styles.percentage}>
						100%
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						Cite the support and training as <br /> being unparalleled and exceptional
					</p>
				</div>
				<div className={styles.shape4}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="50%"
						height="50%"
						viewBox="0 0 152 155"
						fill="none">
						<path
							d="M151.854 4.58892L146.54 23.0932C136.341 58.6159 136.341 96.3841 146.54 131.907L151.854 150.411C152.632 153.129 150.165 155.645 147.5 154.851L129.354 149.432C94.5186 139.032 57.4814 139.032 22.6462 149.432L4.5001 154.851C1.83448 155.645 -0.632468 153.129 0.1457 150.411L5.46038 131.907C15.6593 96.3841 15.6593 58.6159 5.46039 23.0932L0.145706 4.58891C-0.632462 1.87068 1.83449 -0.64495 4.50011 0.148571L22.6462 5.56815C57.4814 15.9683 94.5186 15.9683 129.354 5.56816L147.516 0.165461C150.165 -0.62806 152.632 1.88757 151.854 4.58892Z"
							fill="#FFD6CD"
						/>
					</svg>{' '}
				</div>
				<div
					style={{ background: '#BFF130', position: 'relative' }}
					className={clsx(styles.div5, styles.coloredBoxContainer)}>
					<p className={styles.percentage} style={{ color: '#0A0A0E' }}>
						+70%
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						Ceros users rated us <br /> as having highly innovative <br /> products and services
					</p>
				</div>
			</div>
		</Container>
	)
}
