import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import styles from './ImageHoverRevealCard.module.sass'

interface Props {
	name: string
	img: string
	index: number
	lastIndex: number
}

export default function ImageHoverRevealCard({ name, img, index, lastIndex }: Props) {
	const linkRef = useRef<HTMLDivElement>(null)
	const imgRef = useRef<HTMLImageElement>(null)

	useEffect(() => {
		if (linkRef.current) {
			linkRef.current.onmousemove = (event) => {
				const { offsetX } = event

				if (imgRef.current) {
					imgRef.current.animate(
						{
							left: `${offsetX / 3}px`,
						},
						{ duration: 10000 }
					)
				}
			}
		}
	}, [])

	return (
		<div ref={linkRef} className={styles.hover_card_wrapper}>
			<span className={styles.text}>{name}</span>
			<img
				className={clsx(styles.img, index === lastIndex && styles.imgBottom)}
				ref={imgRef}
				src={img}
				alt="typewriter"
			/>
		</div>
	)
}
