import type { ParsedUrlQuery } from 'querystring'

export const getPageNumberFromQuery = (query: ParsedUrlQuery, parameterName: string) => {
	const raw = query[parameterName]
	const page = typeof raw === 'string' && parseInt(raw, 10)
	if (!page || isNaN(page) || page < 1) {
		return 1
	}
	return page
}
