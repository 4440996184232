import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { LinkFragment } from './LinkFragment'

export function ProductFragment() {
	return ZeusSelect<ValueTypes['Product']>()({
		name: true,
		description: true,
		pricingDescription: true,
		icon: [{}, ImageFragment()],
		link: [{}, LinkFragment()],
		pricingLink: [{}, LinkFragment()],
		image: [{}, ImageFragment()],
		pricingImage: [{}, ImageFragment()],
		backgroundColor: true,
	})
}

export type ProductResult = MapType<ModelTypes['Product'], ReturnType<typeof ProductFragment>>
