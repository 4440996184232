import type { FunctionComponent } from 'react'
import type { BannerTestimonialsResult } from '../data/content/BannerTestimonialsFragment'
import styles from './BannerTestimonials.module.sass'
import { BannerTestimonialsCard } from './BannerTestimonialsCard'
import { CarouselCards } from './CarouselCards'

export type BannerTestimonialsProps = BannerTestimonialsResult

export const BannerTestimonials: FunctionComponent<BannerTestimonialsProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div className={styles.carousel}>
					<CarouselCards
						options={{
							slidesPerView: 1,
							gapSize: 'large',
							displayPagination: true,
						}}
						cards={{
							type: 'bannerTestimonial',
							list: items.map((item) => {
								return {
									id: item.id,
									children: <BannerTestimonialsCard {...item} />,
								}
							}),
						}}
					/>
				</div>
			</div>
		</div>
	)
}
