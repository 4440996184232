import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { VideoFragment } from './VideoFragment'

export function VideoSelectFragment() {
	return ZeusSelect<ValueTypes['VideoSelect']>()({
		videoType: true,
		poster: [{}, ImageFragment()],
		posterMobile: [{}, ImageFragment()],
		video: [{}, VideoFragment()],
		videoMobile: [{}, VideoFragment()],
		youtube: [
			{},
			{
				videoId: true,
			},
		],
		wistiaUrl: true,
	})
}

export type VideoSelectResult = MapType<
	ModelTypes['VideoSelect'],
	ReturnType<typeof VideoSelectFragment>
>
