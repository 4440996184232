import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useMemo, useRef } from 'react'
import gemmaStaticDemo from '../images/gemma/static-demo.webp'
import { easeInOutCubic } from '../utilities/easeInOutCubic'
import {
	gemmaAnimationEvents,
	MoveCursorEvent,
	TimelineEvent,
} from '../utilities/gemmaAnimationEvents'
import { useScrollProgress } from '../utilities/useScrollProgress'
import { Chat } from './Chat'
import { Container } from './Container'
import { Cursor } from './Cursor'
import { GemmaCatchPhrase } from './GemmaCatchPhrase'
import { GemmaContent } from './GemmaContent'
import { GemmaContentText } from './GemmaContentText'
import styles from './GemmaDemoAnimation.module.sass'
import { GemmaFrame } from './GemmaFrame'
import { ImageBoxPlaceholder } from './ImageBoxPlaceholder'

export type GemmaDemoAnimationProps = Record<string, never>

export const GemmaDemoAnimation: FunctionComponent<GemmaDemoAnimationProps> = () => {
	return (
		<div className={styles.wrapper}>
			<Image
				src={gemmaStaticDemo.src}
				width={gemmaStaticDemo.width}
				height={gemmaStaticDemo.height}
				blurDataURL={gemmaStaticDemo.blurDataURL}
				alt=""
				className={styles.mobile}
			/>
			<Desktop />
		</div>
	)
}

type TimelineEventWithProgress = TimelineEvent & {
	progress: number
	index: number
}

const events = gemmaAnimationEvents

const timelineDuration = events.reduce((total, event) => total + event.duration, 0)

const findLastLaunchedEventByType = <T extends TimelineEventWithProgress['type']>(
	events: TimelineEventWithProgress[],
	eventType: T
) =>
	[...events]
		.reverse()
		.find(
			(event): event is TimelineEventWithProgress & { type: T } =>
				event.type === eventType && event.progress > 0
		)

const Desktop: FunctionComponent = () => {
	const timelineRef = useRef<HTMLDivElement>(null)
	const progress = useScrollProgress(timelineRef)

	const eventsWithProgress = useMemo<TimelineEventWithProgress[]>(() => {
		const timelineProgress = progress * timelineDuration
		let currentEventEndTime = 0
		return events.map((event, index) => {
			const currentEventStartTime = currentEventEndTime
			currentEventEndTime = currentEventStartTime + event.duration

			const eventProgress = (timelineProgress - currentEventStartTime) / event.duration
			return {
				...event,
				index,
				progress: Math.max(0, Math.min(1, eventProgress)),
			}
		})
	}, [progress])

	const messages = useMemo(
		() =>
			eventsWithProgress
				.filter(
					(event): event is TimelineEventWithProgress & { type: 'chat' } =>
						event.type === 'chat' && event.progress > 0
				)
				.map((event) => event.message),
		[eventsWithProgress]
	)

	const title = useMemo(
		() =>
			findLastLaunchedEventByType(eventsWithProgress, 'title')?.text ??
			'Lorem Ipsum Dolor Sit Amen',
		[eventsWithProgress]
	)
	const text = useMemo(
		() =>
			findLastLaunchedEventByType(eventsWithProgress, 'text')?.text ??
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sollicitudin tortor vel accumsan euismod. Etiam interdum, eros eu.',
		[eventsWithProgress]
	)
	const imageSrc = useMemo(
		() => findLastLaunchedEventByType(eventsWithProgress, 'image')?.src ?? null,
		[eventsWithProgress]
	)
	const iconASrc = useMemo(
		() => findLastLaunchedEventByType(eventsWithProgress, 'iconA')?.src ?? null,
		[eventsWithProgress]
	)
	const iconBSrc = useMemo(
		() => findLastLaunchedEventByType(eventsWithProgress, 'iconB')?.src ?? null,
		[eventsWithProgress]
	)
	const iconCSrc = useMemo(
		() => findLastLaunchedEventByType(eventsWithProgress, 'iconC')?.src ?? null,
		[eventsWithProgress]
	)

	const slideToSideProgress = useMemo(
		() =>
			easeInOutCubic(
				findLastLaunchedEventByType(eventsWithProgress, 'chat-slide-to-side')?.progress ?? 0
			),
		[eventsWithProgress]
	)

	const catchPhrases = useMemo(() => {
		const items = eventsWithProgress
			.filter(
				(item): item is TimelineEventWithProgress & { type: 'catch-phrase' } =>
					item.type === 'catch-phrase'
			)
			.map(({ progress, title, text }) => ({
				progress,
				title,
				text,
			}))
		const activeIndex = items.reduce<number | null>(
			(resultIndex, { progress }, currentIndex) => (progress > 0 ? currentIndex : resultIndex),
			null
		)
		return items.map(({ title, text }, index) => ({
			title,
			text,
			isActive: index === activeIndex,
		}))
	}, [eventsWithProgress])

	const frameRef = useRef<HTMLDivElement>(null)
	const frameContentRef = useRef<HTMLDivElement>(null)
	const iconARef = useRef<HTMLDivElement>(null)
	const iconBRef = useRef<HTMLDivElement>(null)
	const iconCRef = useRef<HTMLDivElement>(null)
	const imageRef = useRef<HTMLDivElement>(null)
	const chatRef = useRef<HTMLDivElement>(null)
	const titleContentTextRef = useRef<HTMLDivElement>(null)
	const textContentTextRef = useRef<HTMLDivElement>(null)

	const backgroundProgress = useMemo(
		() => findLastLaunchedEventByType(eventsWithProgress, 'add-background')?.progress ?? 0,
		[eventsWithProgress]
	)

	const cursor = useMemo<{
		position: { x: number; y: number }
		progress: number
		isActive: boolean
	}>(() => {
		const frameElement = frameRef.current
		const iconAElement = iconARef.current
		const iconBElement = iconBRef.current
		const iconCElement = iconCRef.current
		const imageElement = imageRef.current
		const chatElement = chatRef.current
		const titleElement = titleContentTextRef.current
		const textElement = textContentTextRef.current
		if (
			frameElement === null ||
			iconAElement === null ||
			iconBElement === null ||
			iconCElement === null ||
			imageElement === null ||
			chatElement === null ||
			titleElement === null ||
			textElement === null
		) {
			// This should never happen
			return {
				position: {
					x: 0,
					y: 0,
				},
				progress: 0,
				isActive: false,
			}
		}
		const frameRect = frameElement.getBoundingClientRect()

		const lastMoveCursorEvent = findLastLaunchedEventByType(eventsWithProgress, 'move-cursor')
		const beforeLastMoveCursorEvent = findLastLaunchedEventByType(
			eventsWithProgress.slice(0, lastMoveCursorEvent?.index ?? 0),
			'move-cursor'
		)

		const getDestinationPositionByEvent = (event: MoveCursorEvent | undefined) => {
			const rect = ((): { x: number; y: number } => {
				// @TODO: react to window resize or any other reposition triggers
				const destination = event?.destination
				if (destination === 'iconA') {
					const rect = iconAElement.getBoundingClientRect()
					return { x: rect.x + (rect.width / 5) * 3, y: rect.y + (rect.height / 13) * 8 }
				} else if (destination === 'iconB') {
					const rect = iconBElement.getBoundingClientRect()
					return { x: rect.x + (rect.width / 4) * 3, y: rect.y + (rect.height / 14) * 11 }
				} else if (destination === 'iconC') {
					const rect = iconCElement.getBoundingClientRect()
					return { x: rect.x + (rect.width / 7) * 4, y: rect.y + (rect.height / 15) * 11 }
				} else if (destination === 'image') {
					const rect = imageElement.getBoundingClientRect()
					return { x: rect.x + (rect.width / 5) * 2, y: rect.y + (rect.height / 5) * 4 }
				} else if (destination === 'title') {
					const rect = titleElement.getBoundingClientRect()
					return { x: rect.x + (rect.width / 3) * 2, y: rect.y + rect.height / 3 } // @TODO: prevent jumps on height animation
				} else if (destination === 'text') {
					const rect = textElement.getBoundingClientRect()
					return { x: rect.x + (rect.width / 4) * 3, y: rect.y + rect.height / 2 } // @TODO: prevent jumps on height animation
				} else if (destination === 'chat') {
					const rect = chatElement.getBoundingClientRect()
					return { x: rect.x + rect.width / 5, y: rect.y + (rect.height / 14) * 9 }
				}
				destination satisfies undefined
				return {
					x: frameRect.x + frameRect.width / 3,
					y: frameRect.y + (frameRect.height / 23) * 21,
				}
			})()
			return {
				x: rect.x,
				y: rect.y,
			}
		}

		const lastPosition = getDestinationPositionByEvent(beforeLastMoveCursorEvent)
		const destinationPosition = getDestinationPositionByEvent(lastMoveCursorEvent)
		const progress = easeInOutCubic(lastMoveCursorEvent?.progress ?? 0)
		const currentPosition = {
			x: lastPosition.x + (destinationPosition.x - lastPosition.x) * progress,
			y: lastPosition.y + (destinationPosition.y - lastPosition.y) * progress,
		}
		return { position: currentPosition, progress, isActive: lastMoveCursorEvent !== undefined }
	}, [eventsWithProgress])

	useEffect(() => {
		const handleKey = (event: KeyboardEvent) => {
			const by = (() => {
				// @TODO: get distance to next/previous timeline event
				if (event.code === 'ArrowRight') {
					return 1000
				} else if (event.code === 'ArrowLeft') {
					return -1000
				}
				return 0
			})()
			if (by !== 0) {
				event.preventDefault()
				window.scrollBy({
					top: by,
					behavior: 'smooth',
				})
			}
		}

		window.addEventListener('keydown', handleKey)
		return () => {
			window.removeEventListener('keydown', handleKey)
		}
	}, [progress])

	return (
		<div
			className={clsx(styles.desktop, progress > 0 && styles.is_progressing)}
			style={{
				'--GemmaDemoAnimation-progress': `${progress}`,
				'--GemmaDemoAnimation-timeline-height': `${timelineDuration}px`,
				'--GemmaDemoAnimation-cursorPosition-x': `${cursor.position.x}px`,
				'--GemmaDemoAnimation-cursorPosition-y': `${cursor.position.y}px`,
				'--GemmaDemoAnimation-chat-slideToSideProgress': slideToSideProgress,
			}}>
			<div className={styles.in}>
				<div className={styles.chat}>
					<div className={clsx(styles.cursor, cursor.isActive && styles.is_active)}>
						<Cursor isResting={cursor.progress === 1} />
					</div>
					<Container size="wide">
						<div className={styles.chat_in}>
							<div className={styles.chat_content} ref={chatRef}>
								<Chat messages={messages} />
							</div>
						</div>
					</Container>
				</div>
				<div className={styles.content}>
					<div className={styles.catchPhrase}>
						<GemmaCatchPhrase items={catchPhrases} />
					</div>
					<Container size="wide">
						<div className={styles.frame} ref={frameRef}>
							<GemmaFrame backgroundProgress={backgroundProgress}>
								<div className={styles.frame_content} ref={frameContentRef}>
									<GemmaContent
										title={
											<GemmaContentText ref={titleContentTextRef} isHeading>
												{title}
											</GemmaContentText>
										}
										text={<GemmaContentText ref={textContentTextRef}>{text}</GemmaContentText>}
										iconA={<ImageBoxPlaceholder ref={iconARef} imageSrc={iconASrc} />}
										iconB={<ImageBoxPlaceholder ref={iconBRef} imageSrc={iconBSrc} />}
										iconC={<ImageBoxPlaceholder ref={iconCRef} imageSrc={iconCSrc} />}
										image={<ImageBoxPlaceholder ref={imageRef} imageSrc={imageSrc} />}
									/>
								</div>
							</GemmaFrame>
						</div>
					</Container>
				</div>
				<div className={styles.timeline} ref={timelineRef} />
			</div>
		</div>
	)
}
