import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import type { UrlObject } from 'url'
import { Button } from './Button'
import styles from './SelectLink.module.sass'

export type SelectLinkProps = {
	items: Array<{
		label: string
		url: UrlObject
		isSelected?: boolean
	}>
	children: ReactNode
}

export const SelectLink: FunctionComponent<SelectLinkProps> = ({ children, ...otherProps }) => {
	const items = useMemo(
		() => otherProps.items.map((item, i) => ({ id: String(i), ...item })),
		[otherProps.items]
	)

	const { push } = useRouter()
	const selectedId =
		useMemo(() => items.find((item) => item.isSelected), [items])?.id ?? items[0].id

	return (
		<label className={styles.wrapper}>
			<Button type="button" outline fill="light" tabIndex={-1}>
				{children}
			</Button>
			<select
				className={styles.select}
				onChange={(event) => {
					const url = items.find((item) => item.id === event.target.value)?.url
					if (url) {
						push(url, undefined, { scroll: false })
					}
				}}
				value={selectedId}>
				{items.map((item) => (
					<option key={item.id} value={item.id}>
						{item.label}
					</option>
				))}
			</select>
		</label>
	)
}
