import type { FunctionComponent } from 'react'
import type { TextListResult } from '../data/content/TextListFragment'
import { Container } from './Container'
import styles from './Timeline.module.sass'

export type TimelineProps = {
	timeline: TextListResult
}

export const Timeline: FunctionComponent<TimelineProps> = ({ timeline }) => {
	return (
		<Container size="normal">
			{timeline.items.map((item) => (
				<div className={styles.timePeriod} key={item.id}>
					<h3 className={styles.title}> {item.title}</h3>
					<p className={styles.text}>{item.text}</p>
				</div>
			))}
		</Container>
	)
}
