import { createContext, useContext } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'

const HubspotFormsContext = createContext<CommonPageProps['hubspotForm'] | null>(null)

export const HubspotFormsProvider = HubspotFormsContext.Provider

export const useHubspotForm = () => {
	const form = useContext(HubspotFormsContext)
	if (!form?.list || !form?.portalId) {
		return undefined
	}

	return {
		list: form.list.results,
		portalId: form.portalId,
	}
}
