import { FunctionComponent, useMemo } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { CategoriesFilter } from './CategoriesFilter'
import { Filters } from './Filters'
import { InspirationLayout, InspirationLayoutProps } from './InspirationLayout'
import { Inspirations } from './Inspirations'

export type InspirationGalleryPageProps = {
	inspirationLinks: InspirationLayoutProps
	data: NonNullable<CommonPageProps['page']['inspirationGalleryPage']>
}

export const InspirationGalleryPage: FunctionComponent<InspirationGalleryPageProps> = ({
	inspirationLinks,
	data: { inspirations, categories, features, industries, types },
}) => {
	const items = useMemo(
		() => inspirations.map((inspiration) => ({ data: inspiration })),
		[inspirations]
	)

	const filterItems = useMemo(
		() => [
			{
				title: 'Feature',
				data: features,
			},
			{
				title: 'Industry',
				data: industries,
			},
			{
				title: 'Type',
				data: types,
			},
		],
		[features, industries, types]
	)

	return (
		<InspirationLayout {...inspirationLinks}>
			<CategoriesFilter data={categories} />
			<Filters items={filterItems} />
			<Inspirations items={items} />
		</InspirationLayout>
	)
}
