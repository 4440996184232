import { useRef, useState, type FunctionComponent } from 'react'

import clsx from 'clsx'
import { useWindowSize } from 'react-use'
import { LinkType } from '../../generated/content'
import { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import styles from './ImageHoverReveal.module.sass'
import ImageHoverRevealCard from './ImageHoverRevealCard'
import desktopStyles from './ImageHoverRevealDesktop.module.sass'

export type ImageHoverRevealProps = {
	title: string
	description?: string | undefined
	images: any
	link?: LinkResult
}

export const ImageHoverReveal: FunctionComponent<ImageHoverRevealProps> = ({
	title,
	description,
	images,
	link,
}) => {
	const { width } = useWindowSize()

	const imageRef = useRef<HTMLImageElement>(null)

	const [selectedItem, setSelectedItem] = useState<number | null>(null)

	const handleSpanClick = (itemId: number) => {
		if (selectedItem === itemId) {
			setSelectedItem(null)
		} else {
			setSelectedItem(itemId)

			if (imageRef.current === null) return
			// imageRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	if (width < 1000) {
		return (
			<section className={styles.wrapper}>
				<div className={styles.leftSection}>
					<BreakableContent breakableFrom="768">
						<h2 className={styles.title}>
							<CerosRichTextRenderer source={title} />
						</h2>
					</BreakableContent>
					{width < 1000 && (
						<div style={{ position: 'absolute', right: 0, top: '40%' }}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="48"
								height="97"
								viewBox="0 0 48 97"
								fill="none">
								<path
									d="M1.86952 8.7215C16.4452 -10.4797 50.9789 13.8761 32.9494 25.8617C10.4125 40.8438 29.4689 7.30129 41.7385 27.2588C54.0081 47.2163 39.7457 80.3993 10.2489 92.6181M15.5411 79.929L7.86051 94.818L31.5177 91.3756"
									stroke="#008FF5"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
					)}
					<BreakableContent breakableFrom="768">
						<p className={styles.text}>{description}</p>
					</BreakableContent>
					{!!link && (
						<div style={{ paddingBottom: '32px' }}>
							<Button
								type="link"
								href={contemberLinkToHref(link)}
								fill="blue"
								target={
									link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
								}>
								{link.title}
							</Button>
						</div>
					)}
				</div>
				<div className={styles.rightSection}>
					{images &&
						images.map((f: any, i: number) => {
							return (
								<div key={f.id} className={styles.content_wrapper}>
									<span
										onClick={() => handleSpanClick(f.id)}
										style={{ color: selectedItem === f.id ? 'orange' : 'white' }}
										className={styles.text}>
										{f.tile.title}
									</span>
									<div
										style={{ position: 'relative' }}
										className={clsx(
											styles.imageContainer,
											selectedItem === f.id && styles.is_opened
										)}>
										<img ref={imageRef} src={f.tile.image.url} alt={f.tile.title} />
									</div>
								</div>
							)
						})}
				</div>
			</section>
		)
	}

	return (
		<div style={{ backgroundColor: '#C5E7FF' }}>
			<Container size="normal">
				<section className={desktopStyles.wrapper}>
					<div className={desktopStyles.leftSection}>
						<BreakableContent breakableFrom="768">
							<h2 className={desktopStyles.title}>
								<CerosRichTextRenderer source={title} />
							</h2>
						</BreakableContent>
						<BreakableContent breakableFrom="768">
							{description && <p className={desktopStyles.text}>{description}</p>}
						</BreakableContent>
						{!!link && (
							<Button
								type="link"
								href={contemberLinkToHref(link)}
								fill="blue"
								target={
									link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
								}>
								{link.title}
							</Button>
						)}
						<svg
							style={{ position: 'absolute', right: 0 }}
							xmlns="http://www.w3.org/2000/svg"
							width="238"
							height="128"
							viewBox="0 0 238 128"
							fill="none">
							<path
								d="M26.768 2.12617C-25.9633 30.3658 20.2713 124.48 56.6655 84.9659C102.158 35.5732 12.5375 69.2072 56.6657 107.048C100.794 144.89 187.667 122.864 229.099 55.339M195.942 63.3652L235.42 50.3428L217.77 106.976"
								stroke="#008FF5"
								strokeWidth="4"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className={desktopStyles.rightSection}>
						{images &&
							images.map((f: any, i: number) => {
								return (
									<ImageHoverRevealCard
										index={i}
										lastIndex={images.length - 1}
										key={f.id}
										name={f.tile.title}
										img={f.tile.image.url}
									/>
								)
							})}
					</div>
				</section>
			</Container>
		</div>
	)
}
