import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { BannerTestimonialsItemFragment } from './BannerTestimonialsItemFragment'

export const BannerTestimonialsFragment = () =>
	ZeusSelect<ValueTypes['BannerTestimonials']>()({
		items: [{ orderBy: [{ order: OrderDirection.asc }] }, BannerTestimonialsItemFragment()],
	})

export type BannerTestimonialsResult = MapType<
	ModelTypes['BannerTestimonials'],
	ReturnType<typeof BannerTestimonialsFragment>
>
