import clsx from 'clsx'

import { Container } from './Container'

import { useWindowSize } from 'react-use'
import styles from './InitiativesBlock.module.sass'
import { WistiaEmbedPlayer } from './WistiaEmbedPlayer'

interface Props {
	items: any
}

export const InitiativesBlock = ({ items }: Props) => {
	const { width } = useWindowSize()

	const connectVideo = width < 768 ? 'v1o9alqu9t ' : 'vf6jgwam7x'
	const unityVideo = width < 768 ? 'gu6486x75k' : 'afoqycxyhz'
	const potentialVideo = width < 768 ? 'p2utoj9j47' : 'h2wss92b5y'
	const evolveVideo = width < 768 ? 'fdt3i2vn0j' : 'wtb1ms3bok'

	if (!items) return null

	return (
		<Container size="wide">
			<section className={styles.parent}>
				<div className={styles.div1} id={`playOnHover_${connectVideo}`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId={connectVideo} wrapper={'init_video1'} />
					</div>
					<div className={clsx(styles.textContent)} style={{ zIndex: 10 }}>
						<h4>{items[0].tile.title}</h4>
						<p className={styles.fiftyWidth}>{items[0].tile.text}</p>
					</div>
				</div>
				<div className={styles.div2} id={`playOnHover_${unityVideo}`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId={unityVideo} wrapper={'init_video2'} />
					</div>
					<div className={styles.textContent} style={{ zIndex: 10 }}>
						<h4>{items[1].tile.title}</h4>
						<p className={styles.seventyWidth}>{items[1].tile.text}</p>
					</div>
				</div>
				<div className={styles.div3} id={`playOnHover_${potentialVideo}`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId={potentialVideo} wrapper={'init_video3'} />
					</div>
					<div className={clsx(styles.textContent)} style={{ zIndex: 10 }}>
						<h4>{items[2].tile.title}</h4>
						<p className={styles.seventyWidth}>{items[2].tile.text}</p>
					</div>
				</div>
				<div className={styles.div4} id={`playOnHover_${evolveVideo}`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId={evolveVideo} wrapper={'init_video4'} />
					</div>
					<div className={clsx(styles.textContent)} style={{ zIndex: 10 }}>
						<h4 style={{ color: 'white' }}>{items[3].tile.title}</h4>
						<p style={{ color: '#ffffff' }} className={styles.seventyWidth}>
							{items[3].tile.text}
						</p>
					</div>
				</div>
			</section>
		</Container>
	)
}
