import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useWindowScroll, useWindowSize } from 'react-use'
import { useIsInView } from '../utilities/useIsInView'
import { Container } from './Container'
import styles from './Humblebrags.module.sass'
import { HumblebragsMobile } from './HumblebragsMobile'
import { Icon } from './Icon'

export const Humblebrags = () => {
	const { width } = useWindowSize()
	const { y: scrollY } = useWindowScroll()

	const [rotationDegrees, setRotationDegrees] = useState<number>(0)

	const starRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(starRef, {
		threshold: 0.1,
	})

	useEffect(() => {
		setRotationDegrees(scrollY * 0.75)
	}, [scrollY])

	if (width < 900) {
		return <HumblebragsMobile />
	}

	return (
		<Container size={'normal'}>
			<div className={styles.parent}>
				<div className={styles.div1}>
					<p className={styles.cardDescription}>
						"Over the course of a year, sites that had Ceros attached to it were{' '}
						<span style={{ color: '#1B00FB' }}>16% more likely to close</span> ... It was millions
						of dollars in revenue."
					</p>
					<p className={styles.cardAuthor}>
						Jeff Holmes, MX Technologies <br /> Head of Sales Design
					</p>
				</div>
				<div
					style={{ background: '#FE5621' }}
					className={clsx(styles.div2, styles.coloredBoxContainer)}>
					<div className={styles.damnWrapper}>
						<Icon name="damn" />
					</div>
					<p className={styles.percentage} style={{ color: '#fff' }}>
						96%
					</p>
					<p style={{ color: '#fff' }} className={styles.message}>
						would <br /> recommend <br /> Ceros to a friend <br /> or colleague
					</p>
				</div>
				<div className={styles.div3}>
					<p className={styles.cardDescription}>
						“Using Ceros, it took us 3 weeks, instead of the 8-12 week timeline,{' '}
						<span style={{ color: '#E21075' }}>for a designer, web developer, and QA.</span>”
					</p>
					<p className={styles.cardAuthor}>
						{' '}
						Jonathan Harrop, AdColony <br /> VP, Global Marketing & Communications
					</p>
				</div>

				<div
					style={{ background: '#FFCDE5' }}
					className={clsx(styles.div4, styles.coloredBoxContainer)}>
					<div className={styles.shoulderBrushingWrapper}>
						<Icon name="shoulderBrushing" />
					</div>
					<p style={{ color: '#0A0A0E' }} className={styles.percentage}>
						100%
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						describe our support and <br /> training as unparalleled and <br /> exceptional
					</p>
				</div>
				<div className={styles.div5}>
					<p className={styles.cardDescription}>
						"The key for our Ceros workflow has been{' '}
						<span style={{ color: '#FE5621' }}>creating templates that we can repurpose</span>.
						Ceros allows us to do that really easily."
					</p>
					<p className={styles.cardAuthor}>
						Colton Pond, Truv
						<br />
						VP, Head of Marketing
					</p>
				</div>
				<div
					style={{ background: '#BFF130', position: 'relative' }}
					className={clsx(styles.div6, styles.coloredBoxContainer)}>
					<div style={{ color: '#99D2FB', position: 'absolute', bottom: '-190px' }}>
						<svg
							style={{
								transition: 'transform 0.2s linear',
								transform: `rotate(${rotationDegrees}deg)`,
							}}
							width="170"
							height="170"
							viewBox="0 0 73 73"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<g id="Shapes" clipPath="url(#clip0_3135_49376)">
								<path
									id="Star 3"
									d="M34.0258 2.0711C34.8824 -0.438333 38.4315 -0.43833 39.2881 2.0711L41.6403 8.96199C42.2791 10.8333 44.6122 11.4585 46.1011 10.1572L51.5836 5.36567C53.5802 3.62074 56.6538 5.39529 56.1409 7.99682L54.7325 15.1406C54.3501 17.0806 56.058 18.7886 57.998 18.4061L65.1418 16.9978C67.7433 16.4849 69.5179 19.5585 67.773 21.5551L62.9814 27.0376C61.6802 28.5265 62.3053 30.8595 64.1767 31.4983L71.0676 33.8506C73.577 34.7072 73.577 38.2563 71.0676 39.1129L64.1767 41.4651C62.3053 42.1039 61.6802 44.4369 62.9814 45.9258L67.773 51.4084C69.5179 53.4049 67.7434 56.4785 65.1418 55.9657L57.998 54.5573C56.058 54.1749 54.3501 55.8828 54.7325 57.8228L56.1409 64.9666C56.6538 67.5681 53.5802 69.3427 51.5836 67.5978L46.1011 62.8062C44.6122 61.5049 42.2791 62.1301 41.6403 64.0014L39.2881 70.8923C38.4315 73.4017 34.8824 73.4017 34.0258 70.8923L31.6736 64.0014C31.0348 62.1301 28.7017 61.5049 27.2128 62.8062L21.7303 67.5978C19.7337 69.3427 16.6601 67.5681 17.173 64.9666L18.5813 57.8228C18.9638 55.8828 17.2559 54.1749 15.3159 54.5573L8.17206 55.9657C5.57052 56.4785 3.79597 53.4049 5.5409 51.4084L10.3325 45.9258C11.6337 44.4369 11.0086 42.1039 9.13722 41.4651L2.24633 39.1129C-0.263098 38.2563 -0.263096 34.7072 2.24633 33.8506L9.13722 31.4983C11.0086 30.8595 11.6337 28.5265 10.3325 27.0376L5.5409 21.555C3.79597 19.5585 5.57052 16.4849 8.17206 16.9978L15.3159 18.4061C17.2559 18.7886 18.9638 17.0806 18.5813 15.1406L17.173 7.99682C16.6601 5.39529 19.7337 3.62074 21.7303 5.36567L27.2128 10.1572C28.7017 11.4585 31.0348 10.8333 31.6736 8.96199L34.0258 2.0711Z"
									fill="currentColor"
								/>
							</g>
							<defs>
								<clipPath id="clip0_3135_49376">
									<rect
										width="72.5854"
										height="72.5854"
										fill="white"
										transform="translate(0.364258 0.189026)"
									/>
								</clipPath>
							</defs>
						</svg>
					</div>
					<p className={styles.percentage} style={{ color: '#0A0A0E' }}>
						98%
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						Ceros users <br /> rated us as having <br /> highly innovative <br /> products and{' '}
						<br />
						services
					</p>
				</div>
				<div
					style={{
						color: '#CFC3FF',
					}}
					className={styles.svg1}>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 74 47"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<g id="Shapes">
							<path
								id="Union"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M73.4237 33.9544C73.4237 29.5757 71.1772 25.7218 67.7735 23.4817C71.1772 21.2416 73.4237 17.3878 73.4237 13.0091C73.4237 6.08893 67.812 0.479321 60.8897 0.479321C55.469 0.479321 50.8519 3.91931 49.1039 8.73449C47.3559 3.91931 42.7388 0.479321 37.3181 0.479321C31.6911 0.479321 26.93 4.18595 25.3453 9.29041C23.7606 4.18595 18.9995 0.479321 13.3724 0.479321C6.4501 0.479321 0.838379 6.08893 0.838379 13.0091C0.838379 17.3878 3.08493 21.2416 6.48865 23.4817C3.08493 25.7218 0.838379 29.5757 0.838379 33.9544C0.838379 40.8745 6.4501 46.4841 13.3724 46.4841C18.9995 46.4841 23.7606 42.7775 25.3453 37.673C26.93 42.7775 31.6911 46.4841 37.3181 46.4841C42.7388 46.4841 47.3559 43.0441 49.1039 38.229C50.8519 43.0441 55.469 46.4841 60.8897 46.4841C67.812 46.4841 73.4237 40.8745 73.4237 33.9544Z"
								fill="currentColor"
							/>
						</g>
					</svg>
				</div>
				<div
					style={{
						color: '#907EFF',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					ref={starRef}
					className={styles.svg2}>
					<svg
						className={clsx(isInView && styles.starAnimated)}
						style={{ transform: 'rotate(-33deg)' }}
						width="80%"
						height="80%"
						viewBox="0 0 73 73"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<g id="Shapes">
							<path
								id="Vector"
								d="M2.14896 0.0695941L10.8144 2.60754C27.4494 7.47788 45.136 7.47788 61.771 2.60754L70.4364 0.0695941C71.7093 -0.302006 72.8874 0.876045 72.5158 2.14897L69.9778 10.8144C65.1075 27.4494 65.1075 45.136 69.9778 61.771L72.5158 70.4364C72.8874 71.7093 71.7093 72.8874 70.4364 72.5158L61.771 69.9778C45.136 65.1075 27.4494 65.1075 10.8144 69.9778L2.14896 72.5158C0.876029 72.8874 -0.302022 71.7093 0.0695777 70.4364L2.60753 61.771C7.47786 45.136 7.47786 27.4494 2.60753 10.8144L0.0774841 2.14107C-0.294116 0.876045 0.883936 -0.302006 2.14896 0.0695941Z"
								fill="currentColor"
							/>
						</g>
					</svg>
				</div>
			</div>
		</Container>
	)
}
