import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { ContentAlignment, FormAlignment, LinkType } from '../../generated/content'
import type { AsideContentResult } from '../data/content/AsideContentFragment'
import type { FormResult } from '../data/content/FormFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './AsideContent.module.sass'
import { AsideContentAccordion } from './AsideContentAccordion'
import { AsideContentMediumList, AsideContentMediumListProps } from './AsideContentMediumList'
import { Button } from './Button'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Form } from './Form'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import { Icon } from './Icon'
import { Wysiwyg } from './Wysiwyg'

export type AsideContentProps = {
	background?: string
	icon?: any
	title?: string
	description?: string
	link?: LinkResult
	mediumList?: AsideContentMediumListProps
	contentAlignment?: ContentAlignment
	form?: FormResult
	bgColor?: string | undefined
} & AsideContentResult

export const AsideContent: FunctionComponent<AsideContentProps> = ({
	background,
	icon,
	title,
	description,
	link,
	mediumList,
	contentAlignment = ContentAlignment.left,
	form,
	accordion,
	isAccordion,
	isAsideMediaOutOfLayout,
	isShapeInBackground,
	formAlignment,
	isFormBox,
	bgColor,
}) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				contentAlignment && styles[`view_${contentAlignment}`],
				isAsideMediaOutOfLayout && styles.is_asideMediaOutOfLayout
			)}>
			<Container size={isAsideMediaOutOfLayout ? 'wide' : 'normal'} disableGutters>
				<div className={clsx(styles.main, isAccordion && styles.is_accordion)}>
					<div
						className={clsx(
							styles.content,
							isAccordion && styles.is_accordion,
							background === 'grey' && styles.is_flexStart
						)}>
						{icon && (
							<div className={styles.iconWrapper}>
								<ContemberImage image={icon.icon} />
							</div>
						)}
						{/* {title && <h2 className={styles.title}>{title}</h2>} */}
						{title && (
							// <BreakableContent breakableFrom="768">
							<h2 className={clsx(styles.title, isAccordion && styles.is_accordion)}>
								<CerosRichTextRenderer source={title} />
							</h2>
							// </BreakableContent>
						)}
						{description && (
							<div className={styles.description}>
								<Wysiwyg source={description} />
							</div>
						)}
						{form && formAlignment === FormAlignment.default && (
							<AsideContentForm form={form} isFormBox={isFormBox} alignment={formAlignment} />
						)}
						{link && (
							<Button
								type="link"
								href={contemberLinkToHref(link)}
								fill="blue"
								target={
									link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
								}>
								{link.title}
							</Button>
						)}
						{isAccordion && accordion?.items && (
							<div className={styles.accordion}>
								<AsideContentAccordion items={accordion.items} />
							</div>
						)}
					</div>
					<div
						style={{ backgroundColor: bgColor || 'transparent', overflow: 'hidden' }}
						className={clsx(styles.medias, isAccordion && styles.is_accordion)}>
						{isShapeInBackground && (
							<div className={styles.waveShape}>
								<Icon name="wave" />
							</div>
						)}
						{mediumList && <AsideContentMediumList {...mediumList} />}
						{/* <AsideContentMediumList {...mediumList} /> */}
						{form && formAlignment === FormAlignment.nextToContent && (
							<AsideContentForm form={form} isFormBox={isFormBox} alignment={formAlignment} />
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}

type AsideContentFormProps = {
	form: FormResult
	isFormBox?: AsideContentProps['isFormBox']
	alignment?: FormAlignment
}

const AsideContentForm: FunctionComponent<AsideContentFormProps> = ({
	form,
	isFormBox,
	alignment,
}) => {
	return (
		<div
			className={clsx(
				styles.form,
				isFormBox && styles.is_box,
				styles[`is_alignment_${alignment}`]
			)}>
			{form.hubspotFormId &&
				(form.isHubspotFormEmbed ? (
					<HubspotEmbedForm formId={form.hubspotFormId} region={form.hubspotFormRegion} />
				) : (
					<Form form={form} isFirstInputHighlighted />
				))}
		</div>
	)
}
