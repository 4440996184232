import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { VideoSelectFragment } from './VideoSelectFragment'

export const RevealableMediaContentFragment = () =>
	ZeusSelect<ValueTypes['RevealableMediaContent']>()({
		title: true,
		text: true,
		footerInfo: true,
		icon: [{}, ImageFragment()],
		isIconAtTheEnd: true,
		videoSelect: [{}, VideoSelectFragment()],
	})

export type RevealableMediaContentResult = MapType<
	ModelTypes['RevealableMediaContent'],
	ReturnType<typeof RevealableMediaContentFragment>
>
