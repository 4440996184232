const localeCodes = {
	en: 'en-US',
} as const

export function useFormatDate(
	locale: keyof typeof localeCodes = 'en',
	date?: string | Date,
	options: Intl.DateTimeFormatOptions = {}
) {
	return date
		? new Date(date).toLocaleDateString(locale, {
				day: 'numeric',
				month: 'numeric',
				year: '2-digit',
				...options,
		  })
		: undefined
}
