import clsx from 'clsx'
import { FunctionComponent, useEffect, useState } from 'react'
import { useHubspotForm } from '../contexts/HubspotFormsContext'
import type { FormResult } from '../data/content/FormFragment'
import type { ButtonProps } from './Button'
import buttonStyles from './Button.module.sass'
import styles from './HubspotEmbedForm.module.sass'

type HubspotEmbedFormProps = {
	formId: FormResult['hubspotFormId']
	region?: FormResult['hubspotFormRegion']
	buttonOptions?: Partial<ButtonProps>
	isWaitlist?: boolean
	onFormReady?: (form: HTMLFormElement) => void
	onFormSubmit?: (form: HTMLFormElement) => void
	onFormSubmitted?: () => void
}

let lastId = 0

export const HubspotEmbedForm: FunctionComponent<HubspotEmbedFormProps> = (props) => {
	const [id, setId] = useState<string | null>(null)

	useEffect(() => {
		setId(`hubspot-${++lastId}`)
	}, [])

	if (id === null) {
		return null
	}

	return <In id={id} {...props} />
}

const In: FunctionComponent<{ id: string } & HubspotEmbedFormProps> = ({
	id,
	formId,
	region = 'eu1',
	buttonOptions,
	isWaitlist,
	onFormReady,
	onFormSubmit,
	onFormSubmitted,
}) => {
	const buttonSize = buttonOptions?.size ?? 'medium'
	const buttonAlign = buttonOptions?.align ?? 'center'
	const buttonFill = buttonOptions?.fill ?? 'blue'
	const buttonOutline = buttonOptions?.outline ?? false
	const buttonBland = buttonOptions?.bland ?? false
	const buttonIsFullWidth = buttonOptions?.isFullWidth ?? true
	const hubspotContent = useHubspotForm()
	const [isFormLoaded, setFormLoaded] = useState(false)

	useEffect(() => {
		if (!formId) {
			console.error(`${formId} is missing.`)
			return
		}

		if (!isFormLoaded) {
			setFormLoaded(true)

			const script = document.createElement('script')
			script.src = 'https://js.hsforms.net/forms/v2.js'
			document.body.appendChild(script)

			script.addEventListener('load', () => {
				if (window.hbspt) {
					window.hbspt.forms.create({
						region: region,
						portalId: hubspotContent?.portalId ? hubspotContent?.portalId : '403603',
						formId: formId,
						target: '#' + id,
						cssRequired: 'required',
						cssClass: clsx(styles.embedForm, isWaitlist && styles.is_waitlist),
						errorClass: styles.is_error,
						errorMessageClass: styles.errorMessage,
						submitButtonClass: clsx(
							styles.buttonSubmit,
							buttonStyles.wrapper,
							buttonSize && buttonStyles[`is_size_${buttonSize}`],
							buttonFill && buttonStyles[`is_fill_${buttonFill}`],
							buttonAlign && buttonStyles[`is_align_${buttonAlign}`],
							buttonOutline && buttonStyles.is_outline,
							buttonBland && buttonStyles.is_bland,
							buttonIsFullWidth && buttonStyles.is_fullWidth
						),
						onFormReady: onFormReady,
						onFormSubmit: onFormSubmit,
						onFormSubmitted: onFormSubmitted,
					})
				}
			})
		}
	}, [
		buttonAlign,
		buttonBland,
		buttonFill,
		buttonIsFullWidth,
		buttonOptions,
		buttonOutline,
		buttonSize,
		formId,
		hubspotContent?.portalId,
		isFormLoaded,
		isWaitlist,
		region,
		id,
		onFormReady,
		onFormSubmitted,
		onFormSubmit,
	])

	return <div style={{ width: '100%' }} id={id}></div>
}
