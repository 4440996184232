import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const ExtensionItemFragment = () =>
	ZeusSelect<ValueTypes['ExtensionItem']>()({
		id: true,
		image: [{}, ImageFragment()],
		text: true,
		extension: [
			{},
			{
				page: [
					{},
					{
						link: [
							{},
							{
								url: true,
							},
						],
						activeRevision: [
							{},
							{
								name: true,
							},
						],
					},
				],
			},
		],
	})

export type ExtensionItemResult = MapType<
	ModelTypes['ExtensionItem'],
	ReturnType<typeof ExtensionItemFragment>
>
