import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import { BackgroundType } from '../../generated/content'
import type { FormResult } from '../data/content/FormFragment'
import { lazyBoundary } from '../images/configuration'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import { Form } from './Form'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import styles from './RequestDemo.module.sass'
import { useTheme } from './ThemeProvider'

export type RequestDemoProps = {
	title?: string
	form: FormResult
	background?: BackgroundType
}

export const RequestDemo: FunctionComponent<RequestDemoProps> = ({ title, form, background }) => {
	const theme = useTheme()

	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_theme_${theme.primary}`],
				styles[`is_background_${background}`]
			)}>
			<Container>
				<div className={styles.main}>
					{title && (
						<h2 className={styles.title}>
							<BreakableContent text={title} breakableFrom="768" />
						</h2>
					)}
					{form.hubspotFormId && (
						<div className={styles.form}>
							{form.isHubspotFormEmbed ? (
								<HubspotEmbedForm formId={form.hubspotFormId} region={form.hubspotFormRegion} />
							) : (
								<Form form={form} isFirstInputHighlighted isButtonFullWidth />
							)}
						</div>
					)}
				</div>
			</Container>
			{background === BackgroundType.blue && (
				<div className={styles.background}>
					<Image
						lazyBoundary={lazyBoundary}
						src="/form_background.jpg"
						layout="fill"
						objectFit="cover"
						alt=""
					/>
				</div>
			)}
		</div>
	)
}
