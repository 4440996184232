import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import ReactModal from 'react-modal'
import { VideoSelectType } from '../../generated/content'
import type { VideoSelectResult } from '../data/content/VideoSelectFragment'
import styles from './Modal.module.sass'
import { ReactVideoWithNoSSR } from './ReactVideoWithNoSSR'
import { useScrollLock } from './ScrollLock'

export type ModalProps = ReactModal['props'] & {
	isType?: 'default' | 'video'
	onRequestClose?: () => void
}

export const Modal: FunctionComponent<ModalProps> = ({
	isType = 'default',
	children,
	onRequestClose,
	...props
}) => {
	useScrollLock(props.isOpen)

	return (
		<ReactModal
			{...props}
			className={clsx(styles.content, styles[`is_${isType}`])}
			overlayClassName={{
				base: styles.overlay,
				afterOpen: styles.is_afterOpen,
				beforeClose: styles.is_beforeClose,
			}}
			bodyOpenClassName={styles.is_modalOpen}
			portalClassName={styles.portal}
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			onRequestClose={onRequestClose}>
			<div className={styles.main}>{children}</div>
		</ReactModal>
	)
}

type VideoModalProps = ModalProps & {
	video: VideoSelectResult
}

export const VideoModal: FunctionComponent<VideoModalProps> = ({ video, ...props }) => {
	const uploadedVideoAspectRatio =
		video.videoType === VideoSelectType.upload && video.video?.width && video.video?.height
			? video.video?.width / video.video?.height
			: undefined

	const uploadedVideoMobileAspectRatio =
		video.videoType === VideoSelectType.upload &&
		video.videoMobile?.width &&
		video.videoMobile?.height
			? video.videoMobile?.width / video.videoMobile?.height
			: undefined

	return (
		<Modal {...props} isType="video">
			<div
				className={styles.contentIn}
				style={{
					'--Modal-video-aspectRatio': uploadedVideoAspectRatio,
					'--Modal-videoMobile-aspectRatio': uploadedVideoMobileAspectRatio,
				}}>
				<ReactVideoWithNoSSR
					{...video}
					playing={props.isOpen}
					light={false}
					controls
					mirrorToGlobalLoading
				/>
			</div>
		</Modal>
	)
}
