import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const AppTimelineFragment = () =>
	ZeusSelect<ValueTypes['AppTimeline']>()({
		logo: [{}, ImageFragment()],
		groups: [
			{},
			{
				id: true,
				title: true,
				items: [
					{},
					{
						id: true,
						image: [{}, ImageFragment()],
					},
				],
			},
		],
	})

export type AppTimelineResult = MapType<
	ModelTypes['AppTimeline'],
	ReturnType<typeof AppTimelineFragment>
>
