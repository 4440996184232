import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { lazyBoundary } from '../images/configuration'
import { Container } from './Container'
import { Icon } from './Icon'
import styles from './UserHeader.module.sass'

export type UserHeaderProps = Record<string, never>

export const UserHeader: FunctionComponent<UserHeaderProps> = ({}) => {
	return (
		<header className={styles.wrapper}>
			<Container size="fullWidth">
				<div className={styles.in}>
					<Link href="/" className={styles.home}>
						<Icon name="home" />
					</Link>
					<div className={styles.user}>
						<div className={styles.avatar}>
							<Image
								lazyBoundary={lazyBoundary}
								src="https://picsum.photos/id/103/200/300"
								layout="fill"
								objectFit="cover"
								alt=""
							/>
						</div>
						Jack Dixon
					</div>
				</div>
			</Container>
		</header>
	)
}
