import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import type { FeatureTileResult } from '../data/content/FeatureTileFragment'
import { lazyBoundary } from '../images/configuration'
import styles from './FeatureTile.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type FeatureTileProps = FeatureTileResult

export const FeatureTile: FunctionComponent<FeatureTileProps> = ({ image, title, text }) => {
	return (
		<div className={styles.wrapper}>
			{image?.url && (
				<div className={styles.image}>
					<Image
						lazyBoundary={lazyBoundary}
						src={image.url}
						layout="fill"
						objectFit="cover"
						alt={image.alt ?? ''}
						sizes="(min-width: 62rem) 21rem, (min-width: 48rem) 34vw, (min-width: 30rem) 50vw, 100vw"
					/>
				</div>
			)}
			{title && <h3 className={styles.title}>{title}</h3>}
			{text && (
				<div className={styles.text}>
					<Wysiwyg source={text} />
				</div>
			)}
		</div>
	)
}
