import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { BackgroundType } from '../../generated/content'
import type { BannerFeaturesResult } from '../data/content/BannerFeaturesFragment'
import { BreakableContent } from './BreakableContent'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import styles from './PricingBannerFeatures.module.sass'

export type PricingBannerFeaturesProps = {
	items: BannerFeaturesResult
	title?: string
	subtitle?: string
	background?: BackgroundType
}

export const PricingBannerFeatures: FunctionComponent<PricingBannerFeaturesProps> = ({
	title,
	subtitle,
	items,
	background,
}) => {
	return (
		<div className={clsx(styles.background, styles[`is_background_${background}`])}>
			<Container size="normal">
				<div className={clsx(styles.wrapper)}>
					{title && (
						<div className={styles.title}>
							<BreakableContent breakableFrom="768">
								<p className={styles.titleStyles}>
									<CerosRichTextRenderer source={title} />{' '}
								</p>
							</BreakableContent>
						</div>
					)}
					{subtitle && (
						<div className={styles.subtitle}>
							<BreakableContent breakableFrom="992">
								<CerosRichTextRenderer source={subtitle} />
							</BreakableContent>
						</div>
					)}
					<div className={styles.bannerFeatures}>
						{items?.items.map((item) => (
							<div key={item.id} className={clsx(styles.bannerFeature)}>
								{item.icon?.icon ? (
									<ContemberImage image={item.icon?.icon as any} />
								) : (
									<span className={styles.checkmark}>
										<Icon name="checkmark" />
									</span>
								)}
								{item.mainTitle && (
									<p className={clsx(styles.bannerFeatureMainTitle)}>{item.mainTitle}</p>
								)}
								<p className={clsx(styles.bannerFeatureTitle)}>{item.title}</p>
							</div>
						))}
					</div>
				</div>
			</Container>
		</div>
	)
}
