import clsx from 'clsx'
import { forwardRef, ReactNode, useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import styles from './GemmaContentText.module.sass'

export interface GemmaContentTextProps {
	children: ReactNode
	isHeading?: boolean
}

const contentClassName = (children: ReactNode) =>
	clsx(
		styles.content,
		typeof children === 'string' &&
			(children.startsWith('Discover ') || children.startsWith('Embark on ')) &&
			styles.is_specialFont // Different font hack on change
	)

export const GemmaContentText = forwardRef<HTMLDivElement, GemmaContentTextProps>(
	({ children, isHeading }, ref) => {
		const [activeContentRef, { height }] = useMeasure<HTMLDivElement>()
		const [previousChildren, setPreviousChildren] = useState<ReactNode>(children)
		const [isTransitioning, setIsTransitioning] = useState(false)

		useEffect(() => {
			return () => {
				setIsTransitioning(true)
				setPreviousChildren(children)
			}
		}, [children])

		return (
			<div
				ref={ref}
				className={clsx(
					styles.wrapper,
					isHeading && styles.is_heading,
					isTransitioning && styles.is_transitioning
				)}
				style={{
					'--GemmaContentText-height': `${height}px`,
				}}>
				<div
					className={styles.in}
					onTransitionEnd={() => {
						setIsTransitioning(false)
					}}>
					<div className={styles.content}>
						<div ref={activeContentRef} className={contentClassName(children)}>
							{children}
						</div>
						<div className={contentClassName(previousChildren)}>{previousChildren}</div>
					</div>
				</div>
			</div>
		)
	}
)

GemmaContentText.displayName = 'GemmaContentText'
