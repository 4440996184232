import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { InteractionBlockFragment } from './InteractionBlockFragment'

export const InteractionBlockTileItemFragment = () =>
	ZeusSelect<ValueTypes['InteractionBlockItem']>()({
		id: true,
		tile: [{}, InteractionBlockFragment()],
	})

export type InteractionBlockTileItem = MapType<
	ModelTypes['InteractionBlockItem'],
	ReturnType<typeof InteractionBlockTileItemFragment>
>
