import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { lazyBoundary } from '../images/configuration'
import { Button } from './Button'
import { Icon } from './Icon'
import type { InspirationGalleryPageProps } from './InspirationGalleryPage'
import styles from './InspirationTile.module.sass'

export type InspirationTileProps = {
	isFeatured?: boolean
	data: InspirationGalleryPageProps['data']['inspirations'][number]
}

export const InspirationTile: FunctionComponent<InspirationTileProps> = ({
	isFeatured = false,
	data: {
		title,
		description,
		views,
		likes,
		author,
		thumbnail,
		isNew,
		isFeature,
		isImmersive,
		link,
	},
}) => {
	return (
		<Link
			href={link?.url ?? '/'}
			className={clsx(
				styles.wrapper,
				isFeatured && styles.is_featured,
				isImmersive && styles.is_immersive
			)}>
			<div className={styles.thumbnail}>
				<div className={styles.thumbnailIn}>
					{thumbnail && (
						<Image
							lazyBoundary={lazyBoundary}
							src={thumbnail.url}
							alt={thumbnail.alt ?? ''}
							layout="fill"
							objectFit="cover"
						/>
					)}
				</div>
			</div>
			<div className={styles.main}>
				<h3 className={styles.title}>{title}</h3>
				{isFeatured && description && <div className={styles.description}>{description}</div>}
				{isFeatured && (
					<div className={styles.callToAction}>
						<Button type="button" size="small" fill="dark">
							Open
						</Button>
					</div>
				)}
			</div>
			<div className={styles.meta}>
				<div className={styles.author}>{author?.name}</div>
				{views > 0 && (
					<div className={styles.views}>
						<span className={styles.icon}>
							<Icon name="eye" />
						</span>{' '}
						{views}
					</div>
				)}
				{likes > 0 && (
					<div className={styles.likes}>
						<span className={styles.icon}>
							<Icon name="thumbUp" />
						</span>{' '}
						{likes}
					</div>
				)}
			</div>
			<div className={styles.labels}>
				{isNew && <div className={styles.label}>New</div>}
				{isFeature && <div className={styles.label}>Feature</div>}
			</div>
		</Link>
	)
}
