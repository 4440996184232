import { useRouter } from 'next/dist/client/router'
import { FunctionComponent, useMemo } from 'react'
import { Button } from './Button'
import { Icon, IconName } from './Icon'
import styles from './Navigation.module.sass'

export type NavigationProps = {
	originalsPageUrl: string | null
	productListPageUrl: string | null
	inspirationGalleryPageUrl: string | null
	educationPageUrl: string | null
}

export const Navigation: FunctionComponent<NavigationProps> = ({
	originalsPageUrl,
	productListPageUrl,
	inspirationGalleryPageUrl,
	educationPageUrl,
}) => {
	const router = useRouter()

	const items: Array<{
		href: string
		label: string
		icon: IconName
		isActive: boolean
	}> = useMemo(() => {
		const pathWithoutQuery = `/${(Array.isArray(router.query?.page) ? router.query.page : []).join(
			'/'
		)}`

		return [
			...(productListPageUrl
				? [
						{
							href: productListPageUrl,
							label: 'Products',
							icon: 'tools' as IconName,
							isActive: pathWithoutQuery === productListPageUrl,
						},
				  ]
				: []),
			...(originalsPageUrl
				? [
						{
							href: originalsPageUrl,
							label: 'Inspiration',
							icon: 'eye' as IconName,
							isActive:
								pathWithoutQuery === originalsPageUrl ||
								pathWithoutQuery === inspirationGalleryPageUrl,
						},
				  ]
				: []),
			...(educationPageUrl
				? [
						{
							href: educationPageUrl,
							label: 'Education',
							icon: 'education' as IconName,
							isActive: pathWithoutQuery === educationPageUrl,
						},
				  ]
				: []),
		]
	}, [
		educationPageUrl,
		inspirationGalleryPageUrl,
		originalsPageUrl,
		productListPageUrl,
		router.query?.page,
	])

	return (
		<div className={styles.wrapper}>
			<nav className={styles.links}>
				{items.map((item) => (
					<div className={styles.item} key={item.href}>
						<Button
							type="link"
							href={item.href}
							icon={item.icon}
							outline={item.isActive}
							bland={!item.isActive}
							align="start">
							{item.label}
						</Button>
					</div>
				))}
			</nav>
			<div className={styles.logo}>
				<a href="https://www.ceros.com/">
					<Icon name="logo" />
				</a>
			</div>
		</div>
	)
}
