import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { OurValuesFragment } from './OurValuesFragment'

export const OurValuesTileItemFragment = () =>
	ZeusSelect<ValueTypes['OurValuesItem']>()({
		id: true,
		tile: [{}, OurValuesFragment()],
	})

export type OurValuesTileItem = MapType<
	ModelTypes['OurValuesItem'],
	ReturnType<typeof OurValuesTileItemFragment>
>
