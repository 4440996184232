import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { OurValuesTileItemFragment } from './OurValuesTileItemFragment'

export const OurValuesListFragment = () =>
	ZeusSelect<ValueTypes['OurValuesList']>()({
		items: [{}, OurValuesTileItemFragment()],
	})

export type OurValuesListResult = MapType<
	ModelTypes['OurValuesList'],
	ReturnType<typeof OurValuesListFragment>
>
