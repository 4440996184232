import type { FunctionComponent } from 'react'
import style from './Spacer.module.sass'

export interface SpacerProps {
	initial?: number
	break38?: number
	break62?: number
	break75?: number
	break92?: number
}

const getBreakCustomProperties = (props: SpacerProps): React.CSSProperties => {
	const breaks = [
		{ name: 'initial', value: props.initial },
		{ name: '38', value: props.break38 },
		{ name: '62', value: props.break62 },
		{ name: '75', value: props.break75 },
		{ name: '92', value: props.break92 },
	]

	for (let i = 1; i < breaks.length; i++) {
		if (typeof breaks[i].value === 'undefined' && typeof breaks[i - 1].value !== 'undefined') {
			breaks[i].value = breaks[i - 1].value
		}
	}

	const styles: React.CSSProperties = {}
	breaks.forEach((item) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		;(styles as any)[`--Spacer-break-${item.name}`] = item.value
	})

	return styles
}

export const Spacer: FunctionComponent<SpacerProps> = (props) => {
	return <div className={style.wrapper} style={getBreakCustomProperties(props)}></div>
}
