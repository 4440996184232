import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import Modal from 'react-modal'
import { lazyBoundary } from '../images/configuration'
import { Button } from './Button'
import { CloseButton } from './CloseButton'
import { Input } from './Input'
import styles from './SubscribeModal.module.sass'

export type SubscribeModalProps = {
	isOpen: boolean
	onRequestClose?: () => void
}

export const SubscribeModal: FunctionComponent<SubscribeModalProps> = ({
	isOpen,
	onRequestClose,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			className={styles.modal}
			overlayClassName={styles.overlay}>
			<div className={styles.in}>
				<div className={styles.illustration}>
					<Image
						lazyBoundary={lazyBoundary}
						src="/subscribe.png"
						layout="fill"
						objectFit="contain"
						objectPosition="left center"
						alt=""
					/>
				</div>
				<form className={styles.form}>
					<h2 className={styles.title}>Subscribe</h2>
					<Input type="email" placeholder="Enter your email address" />
					<Button type="submit" fill="dark">
						Subscribe
					</Button>
				</form>
				<div className={styles.close}>
					<CloseButton type="button" onClick={onRequestClose} />
				</div>
			</div>
		</Modal>
	)
}
