import type { FunctionComponent } from 'react'
import { GridCardListType } from '../../generated/content'
import type { GridCardListResult } from '../data/content/GridCardListFragment'
import { Container } from './Container'
import { GridCardListContacts } from './GridCardListContacts'
import { GridCardListIcon } from './GridCardListIcon'
import { GridCardListTestemonials } from './GridCardListTestemonial'

export type GridCardListProps = GridCardListResult

export const GridCardList: FunctionComponent<GridCardListProps> = ({
	type,
	title,
	items,
	maxColumnsInRow,
}) => {
	return (
		<>
			{items && (
				<Container size="normal">
					{type === GridCardListType.testimonial ? (
						<GridCardListTestemonials items={items} type={type} />
					) : type === GridCardListType.icon ? (
						<GridCardListIcon items={items} title={title} maxColumnsInRow={maxColumnsInRow} />
					) : type === GridCardListType.contacts ? (
						<GridCardListContacts items={items} />
					) : null}
				</Container>
			)}
		</>
	)
}
