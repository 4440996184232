import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { BreakableText } from './BreakableContent'
import styles from './ContentMedia.module.sass'

export type ContentMediaProps = {
	title: string
	text?: string
	image?: ImageResult
}

export const ContentMedia: FunctionComponent<ContentMediaProps> = ({ title, text, image }) => {
	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>{title}</h3>
			{text && (
				<p className={styles.text}>
					<BreakableText text={text} />
				</p>
			)}
			{image && (
				<Image
					className={styles.image}
					src={image.url}
					alt={image.alt ?? image.fileName ?? ''}
					width={image.width}
					height={image.height}
				/>
			)}
		</div>
	)
}
