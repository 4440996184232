import { z } from 'zod'
import type { CommonPageProps } from '../../pages/[[...page]]'
import type { FormResult } from '../data/content/FormFragment'

const HubspotSubmitFormResponse = z.union([
	z.object({ redirectUri: z.string() }),
	z.object({ inlineMessage: z.string() }),
])

export type HandleSubmitHubspotFormFieldData = {
	objectTypeId: string
	name: string
	value: FormDataEntryValue
}

export type handleSubmitHubspotFormProps = {
	formId: FormResult['hubspotFormId']
	portalId: CommonPageProps['hubspotForm']['portalId']
	formFieldsData: HandleSubmitHubspotFormFieldData[]
}

export const handleSubmitHubspotForm = async ({
	formId,
	portalId,
	formFieldsData,
}: handleSubmitHubspotFormProps) => {
	const formGuid = formId
	const submitApiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`

	try {
		const response = await fetch(submitApiUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				// @TODO: other data
				fields: [...formFieldsData],
			}),
		})

		const responseData = await response
			.json()
			.then((response) => HubspotSubmitFormResponse.parse(response))

		return responseData
	} catch (error) {
		console.error(error)

		return {
			error,
		}
	}
}
