import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode, useState } from 'react'
import { Button } from './Button'
import styles from './InspirationLayout.module.sass'
import { SubscribeModal } from './SubscribeModal'

export type InspirationLayoutProps = {
	originalsLink: string | null
	inspirationGalleryLink: string | null
	children?: ReactNode
}

export const InspirationLayout: FunctionComponent<InspirationLayoutProps> = ({
	originalsLink,
	inspirationGalleryLink,
	children,
}) => {
	const router = useRouter()
	const isOriginalsLinkActive =
		Array.isArray(router.query?.page) && `/${router.query?.page.join('/')}` === originalsLink
	const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false)

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.primary}>
					{originalsLink && (
						<Button
							type="link"
							href={originalsLink}
							outline={isOriginalsLinkActive}
							bland={!isOriginalsLinkActive}>
							Originals
						</Button>
					)}
					{inspirationGalleryLink && (
						<Button
							type="link"
							href={inspirationGalleryLink}
							outline={!isOriginalsLinkActive}
							bland={isOriginalsLinkActive}>
							Inspiration Gallery
						</Button>
					)}
				</div>
				<div className={styles.secondary}>
					<Button
						type="button"
						fill="light"
						size="small"
						onClick={() => {
							setIsSubscribeModalOpen(true)
						}}>
						Subscribe
					</Button>
					<SubscribeModal
						isOpen={isSubscribeModalOpen}
						onRequestClose={() => {
							setIsSubscribeModalOpen(false)
						}}
					/>
				</div>
			</div>
			<div className={styles.content}>{children}</div>
		</div>
	)
}
