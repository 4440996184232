import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageHoverFragment } from './ImageHoverFragment'

export const ImageHoverTileItemFragment = () =>
	ZeusSelect<ValueTypes['ImageHoverRevealItem']>()({
		id: true,
		tile: [{}, ImageHoverFragment()],
	})

export type ImageHoverTileItemResult = MapType<
	ModelTypes['ImageHoverRevealItem'],
	ReturnType<typeof ImageHoverTileItemFragment>
>
