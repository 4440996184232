import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { TabsWithMediaItemFragment } from './TabsWithMediaItemFragment'

export const TabsWithMediaListFragment = () =>
	ZeusSelect<ValueTypes['TabsWithMediaList']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			TabsWithMediaItemFragment(),
		],
	})

export type TabsWithMediaListResult = MapType<
	ModelTypes['TabsWithMediaList'],
	ReturnType<typeof TabsWithMediaListFragment>
>
