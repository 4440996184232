import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { GridCardIconTitleLayout, GridCardRatingType } from '../../generated/content'
import type { GridCardListResult } from '../data/content/GridCardListFragment'
import { CardTile } from './CardTile'
import styles from './CardWithIcon.module.sass'
import { Icon } from './Icon'
import { ImageSizeByArea, ImageSizeByAreaProps } from './ImageSizeByArea'
import { Link } from './Link'
import { Wysiwyg } from './Wysiwyg'

export type CardWithIconProps = {
	id: GridCardListResult['items'][number]['id']
	title?: GridCardListResult['items'][number]['title']
	rating?: GridCardListResult['items'][number]['rating']
	icon?: GridCardListResult['items'][number]['icon']
	text?: GridCardListResult['items'][number]['text']
	linkList?: GridCardListResult['items'][number]['linkList']
	iconTitleLayout?: GridCardListResult['items'][number]['iconTitleLayout']
	isMobileHidden?: GridCardListResult['items'][number]['isMobileHidden']
}

export const CardWithIcon: FunctionComponent<CardWithIconProps> = ({
	icon,
	rating,
	title,
	text,
	linkList,
	iconTitleLayout,
}) => {
	const starsCount = 5
	const iconSize = 60
	return (
		<div
			className={clsx(
				styles.wrapper,
				iconTitleLayout === GridCardIconTitleLayout.inline && styles.iconNextToTitle
			)}
			style={{ '--CardWithIcon-icon-size': `${iconSize}px` }}>
			<div className={styles.main}>
				<CardTile borderRadius="big" isOverflowPrevented={false}>
					<div className={clsx(styles.mainIn, (rating?.source || linkList) && styles.has_footer)}>
						{iconTitleLayout === GridCardIconTitleLayout.default && icon?.icon && (
							<CardIcon
								icon={{
									image: icon.icon,
									baseSize: iconSize,
									imageMaxArea: iconSize,
								}}
							/>
						)}
						{iconTitleLayout === GridCardIconTitleLayout.inline && (
							<div className={styles.iconAndTitle}>
								{icon?.icon && (
									<>
										<CardIcon
											displayType="mobile"
											icon={{
												image: icon.icon,
												baseSize: iconSize,
												imageMaxArea: iconSize,
											}}
										/>
										<CardIcon
											displayType="desktop"
											icon={{
												image: icon.icon,
												baseSize: iconSize,
												imageMaxArea: iconSize,
											}}
										/>
									</>
								)}
								{title && <h3 className={styles.title}>{title}</h3>}
							</div>
						)}
						{rating?.isRating && (
							<div className={clsx(styles.header, styles[`is_${rating.type}`])}>
								{rating.type === GridCardRatingType.percentage ? (
									`${rating.percentage} %`
								) : rating.type === GridCardRatingType.stars ? (
									<div className={styles.stars}>
										{Array.from({ length: starsCount }, (_, index) => (
											<div key={index} className={styles.star}>
												<Icon name="star" />
											</div>
										))}
									</div>
								) : null}
							</div>
						)}
						{!iconTitleLayout && title && <h3 className={styles.title}>{title}</h3>}
						{text && (
							<div className={styles.body}>
								<div className={styles.bodyText}>
									<Wysiwyg source={text} />
								</div>
							</div>
						)}
						{linkList && (
							<ul className={styles.linkList}>
								{linkList.items.map((link) => (
									<li key={link.id} className={styles.linkItem}>
										<Link className={styles.linkIn} link={link.link} />
									</li>
								))}
							</ul>
						)}
						{rating?.source && (
							<div className={styles.footer}>
								<span className={styles.footerText}>Source: {rating.source}</span>
							</div>
						)}
					</div>
				</CardTile>
			</div>
		</div>
	)
}

type CardIconProps = {
	icon: ImageSizeByAreaProps
	displayType?: 'default' | 'desktop' | 'mobile'
}

const CardIcon: FunctionComponent<CardIconProps> = ({ icon, displayType = 'default' }) => {
	const mobileSize = 40
	const desktopSize = 50
	return (
		<div className={clsx(styles.icon, styles[`is_${displayType}`])}>
			<ImageSizeByArea
				image={icon.image}
				baseSize={
					displayType === 'mobile'
						? mobileSize
						: displayType === 'desktop'
						? desktopSize
						: icon.baseSize
				}
				imageMaxArea={
					displayType === 'mobile'
						? mobileSize
						: displayType === 'desktop'
						? desktopSize
						: icon.imageMaxArea
				}
			/>
		</div>
	)
}
