import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { ProductItemFragment } from './ProductItemFragment'

export const ProductListFragment = () =>
	ZeusSelect<ValueTypes['ProductList']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			ProductItemFragment(),
		],
	})

export type ProductListResult = MapType<
	ModelTypes['ProductList'],
	ReturnType<typeof ProductListFragment>
>
