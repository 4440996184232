import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const ImageHoverFragment = () =>
	ZeusSelect<ValueTypes['FeatureTile']>()({
		image: [{}, ImageFragment()],
		title: true,
	})

export type ImageHoverResult = MapType<
	ModelTypes['FeatureTile'],
	ReturnType<typeof ImageHoverFragment>
>
