import clsx from 'clsx'

import { useWindowSize } from 'react-use'
import { Container } from './Container'
import styles from './FeaturedGrid.module.sass'
import { WistiaEmbedPlayer } from './WistiaEmbedPlayer'

interface Props {
	items: any
}

export const FeaturedGrid = ({ items }: Props) => {
	const { width } = useWindowSize()

	const thanksToAiVideo = width < 768 ? 'gnddloncaa' : 'zjhurw0unp'
	const streamliningVideo = width < 768 ? 'l2sjjc3zdp' : 'r2gfgchron'
	const giveEntireTeamVideo = width < 768 ? 'ihtcanw1bj' : '8xkghtg1wu'
	const needHelpVideo = width < 768 ? '6ece8nacdd' : '8tmxae71dl'
	const noCodeVideo = width < 768 ? 'p4vt4xlfah' : '43jzcuvz05'

	if (!items) return null

	return (
		<Container size="normal">
			<section className={styles.grid}>
				<div className={styles.row}>
					<div className={clsx(styles.card, styles.oneCol)} id={`playOnHover_13tvxa7eem`}>
						<div className={clsx(styles.cardContainer)}>
							<span style={{ zIndex: 2 }} className={styles.cardOverline}>
								{items[0] && items[0].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer playOnHover={true} videoId="13tvxa7eem" wrapper={'feature1'} />
							</div>
						</div>
					</div>
					<div className={clsx(styles.card)} id={`playOnHover_${thanksToAiVideo}`}>
						<div className={clsx(styles.cardContainer)} style={{ background: '#f5f5f5' }}>
							<span style={{ zIndex: 2 }} className={clsx(styles.cardOverline, styles.maxWidth)}>
								{items[1] && items[1].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer
									playOnHover={true}
									videoId={thanksToAiVideo}
									wrapper={'feature2'}
								/>
							</div>
						</div>
					</div>
					<div className={clsx(styles.card, styles.oneCol)} id={`playOnHover_quoydbd3dj`}>
						<div className={styles.cardContainer}>
							<span style={{ zIndex: 2, color: 'white' }} className={styles.cardOverline}>
								{items[2] && items[2].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer playOnHover={true} videoId="quoydbd3dj" wrapper={'feature3'} />
							</div>
						</div>
					</div>
					<div className={clsx(styles.card)} id={`playOnHover_${streamliningVideo}`}>
						<div className={clsx(styles.cardContainer)} style={{ background: '#060065' }}>
							<span
								style={{ zIndex: 2, color: 'white' }}
								className={clsx(styles.cardOverline, styles.maxWidth)}>
								{items[3] && items[3].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer
									playOnHover={true}
									videoId={streamliningVideo}
									wrapper={'feature4'}
								/>
							</div>
						</div>
					</div>
					<div className={styles.card} id={`playOnHover_${needHelpVideo}`}>
						<div className={styles.cardContainer} style={{ background: '#F1EAFF' }}>
							<span style={{ zIndex: 2 }} className={clsx(styles.cardOverline, styles.maxWidth)}>
								{items[4] && items[4].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer
									playOnHover={true}
									videoId={needHelpVideo}
									wrapper={'feature5'}
								/>
							</div>
						</div>
					</div>
					<div className={clsx(styles.card, styles.oneCol)} id={`playOnHover_${noCodeVideo}`}>
						<div className={clsx(styles.cardContainer, styles.zeroPadding)}>
							<span
								style={{ zIndex: 2 }}
								className={clsx(styles.cardOverline, styles.textContentPadding)}>
								{items[5] && items[5].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer videoId={noCodeVideo} wrapper={'feature6'} />
							</div>
						</div>
					</div>
					<div className={clsx(styles.card, styles.oneCol)} id={`playOnHover_07t6b0m7c1`}>
						<div className={clsx(styles.cardContainer, styles.zeroPadding)}>
							<span
								style={{ zIndex: 2 }}
								className={clsx(styles.cardOverline, styles.textContentPadding)}>
								{items[6] && items[6].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer playOnHover={true} videoId="07t6b0m7c1" wrapper={'feature7'} />
							</div>
						</div>
					</div>
					<div className={clsx(styles.card)} id={`playOnHover_${giveEntireTeamVideo}`}>
						<div className={clsx(styles.cardContainer)} style={{ background: '#9B88FE' }}>
							<span
								style={{ zIndex: 2, color: 'white' }}
								className={clsx(styles.cardOverline, styles.maxWidth70)}>
								{items[7] && items[7].tile.text}
							</span>
							<div className={styles.videoBackground}>
								<WistiaEmbedPlayer
									playOnHover={true}
									videoId={giveEntireTeamVideo}
									wrapper={'feature8'}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Container>
	)
}
