import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo } from 'react'
import { ArticleStatus, BackgroundType, NewsItemType } from '../../generated/content'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import { getPageNumberFromQuery } from '../utilities/getPageNumberFromQuery'
import { Link } from './Link'
import styles from './NewsBlock.module.sass'

export const newsPageParameterName = 'news'

export interface NewsBlockProps {
	title?: string
	paginatedNews: ReferenceRendererProps<
		ContentBlockResult['references'][number]
	>['reference']['paginateNews']
	itemsPerPage: number
	background: BackgroundType
}

export const NewsBlock: FunctionComponent<NewsBlockProps> = ({
	title,
	paginatedNews,
	itemsPerPage,
	background,
}) => {
	const pages = useMemo(
		() =>
			new Array(Math.ceil(paginatedNews.pageInfo.totalCount / itemsPerPage))
				.fill(null)
				.map((_, i) => i + 1),
		[itemsPerPage, paginatedNews.pageInfo.totalCount]
	)
	const { pathname, query } = useRouter()
	const currentPage = useMemo(() => getPageNumberFromQuery(query, newsPageParameterName), [query])

	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.in, styles[`is_background_${background}`])}>
				{title && <h2 className={styles.title}>{title}</h2>}
				<ul className={styles.items}>
					{paginatedNews.edges.map(({ node }) => {
						if (node.item) {
							const content = (
								<li key={node.id} className={styles.item}>
									<Item {...node.item} />
								</li>
							)
							if (
								node.item.type === NewsItemType.article &&
								node.item?.article?.status === ArticleStatus.published
							) {
								return content
							} else if (node.item.type === NewsItemType.external) {
								return content
							}
						}
					})}
				</ul>
				<ul className={styles.pagination}>
					{pages.map((page) => (
						<li
							key={page}
							className={clsx(styles.pagination_item, currentPage === page && styles.is_active)}>
							<NextLink
								href={{
									pathname,
									query: {
										...query,
										[newsPageParameterName]: page,
									},
								}}
								scroll={false}
								className={styles.pagination_link}>
								{page}
							</NextLink>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

const Item: FunctionComponent<
	NonNullable<NewsBlockProps['paginatedNews']['edges'][number]['node']['item']>
> = ({ type, title, linkLabel, publishedAt, link, article }) => {
	const date = useMemo(
		() =>
			publishedAt &&
			new Date(publishedAt).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'numeric',
				year: '2-digit',
			}),
		[publishedAt]
	)
	const className = styles.card
	const content = (
		<>
			<h3 className={styles.card_title}>{title}</h3>
			{/* @TODO: remove link.title */}
			{(link?.title || linkLabel) && (
				<div className={styles.card_linkTitle}>{linkLabel ?? link?.title}</div>
			)}
			{date && <div className={styles.card_publishedAt}>{date}</div>}
		</>
	)

	if (type === NewsItemType.external && link) {
		return (
			<Link link={link} className={className}>
				{content}
			</Link>
		)
	} else if (type === NewsItemType.article && article?.link) {
		return (
			<NextLink href={article.link.url} className={className}>
				{content}
			</NextLink>
		)
	}
	return <div className={className}>{content}</div>
}
