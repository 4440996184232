import clsx from 'clsx'
import { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import { useActivePage } from '../contexts/ActiveUrlContext'
import styles from './CarouselCards.module.sass'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Icon } from './Icon'

export type CarouselCardsProps = {
	title?: string
	cards: {
		type?: 'default' | 'product' | 'bannerTestimonial'
		list: CarouselCard[]
	}
	options?: {
		slidesPerView?: number
		isSideShadowHidden?: boolean
		gapSize?: 'large' | 'small'
		displayPagination?: boolean
	}
}

type CarouselCard = {
	children: ReactNode
	id: string
}

export const CarouselCards: FunctionComponent<CarouselCardsProps> = ({ title, cards, options }) => {
	const activePage = useActivePage()
	const { list, type = 'default' } = cards
	const cardElement = useRef<HTMLDivElement>(null)
	const cardsElement = useRef<HTMLDivElement>(null)

	// options
	const isShadowHidden = options?.isSideShadowHidden ?? false
	const gapSize = options?.gapSize

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideModifier = options?.slidesPerView === 1 ? 1 : 1.4 // purpose: slide to the sides if it's almost at the beginning/end

		const scrollSlideOffset = direction * cardWidth * scrollSlideModifier

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)
	const [currentSlide, setCurrentSlide] = useState<number>(0)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}

		setShowPreviousButton(true)
		setShowNextButton(true)

		// Calculate the current slide based on scroll position
		const currentSlide = Math.round(
			(cardsElement.current.scrollLeft / cardsElement.current.clientWidth) * 1.25
		)

		// You can use the currentSlide for any specific logic related to the slide
		setCurrentSlide(currentSlide)

		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])

	return (
		<div className={clsx(styles.wrapper, styles[`view_gap_${gapSize}`])}>
			<div className={styles.header}>
				{title && (
					<h3 className={styles.title}>
						<CerosRichTextRenderer source={title} />
					</h3>
				)}
				{title && !activePage.startsWith('/pricing') && (
					<div className={styles.iconWrapper}>
						<Icon name={'exploreTheSuite'} />
					</div>
				)}
			</div>
			{list.length > 0 && (
				<div className={styles.main}>
					{/* {list.length > 1 && (
						<div className={styles.navigation}>
							<CarouselCardsNavigation
								onPreviousClick={() => scroll(-1)}
								onNextClick={() => scroll(1)}
								isPreviousButtonVisible={showPreviousButton}
								isNextButtonVisible={showNextButton}
								slidesPerView={options?.slidesPerView}
							/>
						</div>
					)} */}
					<div
						className={clsx(
							styles.mainIn,
							!(isShadowHidden || list.length <= 1) && styles.view_sideShadow,
							styles[`view_slidesPerView_${options?.slidesPerView}`]
						)}>
						<div
							className={clsx(
								styles.cards,
								list.length <= 4 && type === 'product'
									? styles.is_aligned_center
									: list.length <= 3 && type === 'default'
									? styles.is_aligned_center
									: null
							)}
							ref={cardsElement}
							onScroll={() => onSlideChange()}>
							<div className={styles.cardsSideSpace} />
							{list.map((card) => (
								<div key={card.id} className={styles.card} ref={cardElement}>
									{card.children}
								</div>
							))}
							<div className={styles.cardsSideSpace} />
						</div>
						<div
							className={clsx(
								styles.carousel_nav_wrapper,
								activePage.includes('/blog') && styles.nav_wrapper_blog
							)}>
							{options?.displayPagination &&
								list.length > 1 &&
								list.map((l, i) => {
									const isActive = i === currentSlide

									const handleSpanClick = () => {
										if (cardsElement.current === null) return
										const slideWidth = cardsElement.current.clientWidth
										const scrollPosition = i * slideWidth
										cardsElement.current.scrollTo({
											left: scrollPosition,
											behavior: 'smooth',
										})
									}

									return (
										<span
											className={clsx(cards.type === 'default' && styles.color)}
											onClick={handleSpanClick}
											style={{
												borderRadius: isActive ? '0.625rem' : '100px',
												width: isActive ? '4rem' : '0.75rem',
												opacity: isActive ? '1' : '0.3',
												cursor: 'pointer',
											}}
											key={l.id}></span>
									)
								})}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
