import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { NewsItemFragment } from './NewsItemFragment'

export const NewsroomItemFragment = () =>
	ZeusSelect<ValueTypes['NewsroomItem']>()({
		id: true,
		image: [{}, ImageFragment()],
		newsData: [{}, NewsItemFragment()],
	})

export type NewsroomItemResult = MapType<
	ModelTypes['NewsroomItem'],
	ReturnType<typeof NewsroomItemFragment>
>
