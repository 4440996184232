import type { FunctionComponent } from 'react'
import { ButtonContentAlignType, ButtonFillType, ButtonSizeType } from '../../generated/content'
import type { ButtonResult } from '../data/content/ButtonFragment'
import { Button, ButtonProps } from './Button'
import styles from './ContemberButton.module.sass'

export type ContemberButtonProps = ButtonResult & ButtonProps

export const ContemberButton: FunctionComponent<ContemberButtonProps> = ({
	size = ButtonSizeType.medium,
	fill = ButtonFillType.transparent,
	contentAlign = ButtonContentAlignType.center,
	isOutline = false,
	isBland = false,
	isFullWidth = false,
	...props
}) => {
	return (
		<div className={styles.wrapper}>
			<Button
				{...props}
				size={size}
				fill={fill}
				align={contentAlign}
				outline={isOutline}
				bland={isBland}
				isFullWidth={isFullWidth}>
				{props.children}
			</Button>
		</div>
	)
}
