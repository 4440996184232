import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const OurValuesFragment = () =>
	ZeusSelect<ValueTypes['FeatureTile']>()({
		title: true,
		text: true,
	})

export type OurValuesResult = MapType<
	ModelTypes['FeatureTile'],
	ReturnType<typeof OurValuesFragment>
>
