import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import { Container } from './Container'
import styles from './Main.module.sass'
import { Navigation, NavigationProps } from './Navigation'

export type MainProps = {
	navigation: NavigationProps
	dark?: boolean
	hideNavigation?: boolean
	children: ReactNode
}

export const Main: FunctionComponent<MainProps> = ({
	children,
	navigation,
	dark = false,
	hideNavigation = false,
}) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				dark && styles.is_dark,
				hideNavigation && styles.is_hideNavigation
			)}>
			<div className={styles.navigation}>
				<Navigation {...navigation} />
			</div>
			<div className={styles.in}>
				<Container size="wide" disableGutters>
					{children}
				</Container>
			</div>
		</div>
	)
}
