import { ArrowDown, ArrowUp } from '../icons'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import styles from './PricingAccordion.module.sass'

interface AccordionInterface {
	title: string
	children: any
	expanded: boolean // Add expanded prop
	onClick: () => void // Add onClick prop
}

export const PricingAccordion = ({ title, children, expanded, onClick }: AccordionInterface) => {
	const toggleAccordion = () => {
		onClick()
	}

	return (
		<div className={styles.pricingAccordionWrapper}>
			<div className={styles.pricingAccordionContainer} onClick={toggleAccordion}>
				<p className={styles.accordionTitle}>{title}</p>
				<span className={styles.arrowStyles}>{expanded ? <ArrowUp /> : <ArrowDown />}</span>
			</div>
			{expanded && (
				<div className={styles.accordionItem}>
					<p className={styles.accordionDescription}>
						<CerosRichTextRenderer source={children} />
					</p>
				</div>
			)}
		</div>
	)
}
