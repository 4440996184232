import clsx from 'clsx'
import Image from 'next/legacy/image'
import { FunctionComponent, useState } from 'react'
import { useWindowSize } from 'react-use'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { lazyBoundary } from '../images/configuration'
import { useParallax } from '../utilities/useParallax'
import type { VisualLineProps } from './VisualLine'
import styles from './VisualLineImages.module.sass'

type VisualLineImagesProps = {
	lineType: VisualLineProps['lineType']
	items: MediumListResult['items']
	section: 'first' | 'second'
	deviceVisibility: 'mobile' | 'desktop'
}

export const VisualLineImages: FunctionComponent<VisualLineImagesProps> = ({
	lineType,
	items,
	section,
	deviceVisibility,
}) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
	const { height: windowHeight } = useWindowSize()
	const offset = useParallax(wrapper, 0, 1 /* navigation height */ / windowHeight)

	return (
		<div
			className={clsx(
				styles.images,
				styles[`is_${section}`],
				styles[`is_${deviceVisibility}`],
				styles[`is_${lineType}`]
			)}
			ref={setWrapper}
			style={{
				'--VisualLine-offset': offset,
			}}>
			{items.map(
				(image) =>
					image.medium?.image && (
						<div
							key={image.id}
							className={styles.image}
							style={{
								'--VisualLine-image-width': image.medium.image.width,
								'--VisualLine-image-height': image.medium.image.height,
							}}>
							<Image
								lazyBoundary={lazyBoundary}
								src={image.medium.image.url}
								layout="fill"
								objectFit="cover"
								alt={image.medium.image.alt ?? ''}
								sizes="300px"
							/>
						</div>
					)
			)}
		</div>
	)
}
