import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const AccordionItemFragment = () =>
	ZeusSelect<ValueTypes['AccordionItem']>()({
		id: true,
		name: true,
		description: true,
	})

export type AccordionItemResult = MapType<
	ModelTypes['AccordionItem'],
	ReturnType<typeof AccordionItemFragment>
>
