import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const ButtonFragment = () =>
	ZeusSelect<ValueTypes['Button']>()({
		fill: true,
		size: true,
		contentAlign: true,
		isOutline: true,
		isBland: true,
		isFullWidth: true,
	})

export type ButtonResult = MapType<ModelTypes['Button'], ReturnType<typeof ButtonFragment>>
