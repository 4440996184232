import { FunctionComponent, useEffect, useState } from 'react'
import { Filter, FilterProps } from './Filter'
import styles from './Filters.module.sass'

export type FiltersProps = {
	items: Pick<FilterProps, 'title' | 'data'>[]
}

export const Filters: FunctionComponent<FiltersProps> = ({ items }) => {
	const [openFilter, setOpenFilter] = useState<null | string>(null)

	useEffect(() => {
		if (openFilter) {
			const callback = (event: KeyboardEvent) => {
				if (event.code === 'Escape') {
					setOpenFilter(null)
				}
			}
			document.addEventListener('keydown', callback)
			return () => {
				document.removeEventListener('keydown', callback)
			}
		}
	}, [openFilter])

	return (
		<div className={styles.wrapper}>
			{items.map((item) => (
				<Filter
					key={item.data.urlKey}
					isOpen={openFilter === item.data.urlKey}
					requestClose={() => {
						setOpenFilter(null)
					}}
					requestOpen={() => {
						setOpenFilter(item.data.urlKey)
					}}
					{...item}
				/>
			))}
		</div>
	)
}
