import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { LinkType } from '../../generated/content'
import type { GridCardListResult } from '../data/content/GridCardListFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { CardTile } from './CardTile'
import { Form } from './Form'
import styles from './GridCardListContacts.module.sass'

export type GridCardListContactsProps = {
	items: GridCardListResult['items']
}

export const GridCardListContacts: FunctionComponent<GridCardListContactsProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			{items.map((item) => (
				<div
					key={item.id}
					className={clsx(styles.item, item.isMobileHidden && styles.is_mobileHidden)}>
					<CardTile key={item.id} borderRadius="big">
						<div className={styles.gridCard}>
							{item.title && (
								<h3 className={styles.title}>
									<BreakableContent text={item.title} breakableFrom="768" />
								</h3>
							)}
							{item.text && (
								<div className={styles.text}>
									<BreakableContent text={item.text} breakableFrom="768" />
								</div>
							)}
							<div className={styles.form}>
								{item.form && <Form form={item.form} place="card" />}
							</div>
							{item.link && !item.form && (
								<Button
									type="link"
									href={contemberLinkToHref(item.link)}
									fill="blue"
									target={
										item.link.type !== LinkType.scrollToTarget && item.link.isTargetBlank
											? '_blank'
											: undefined
									}>
									{item.link.title}
								</Button>
							)}
						</div>
					</CardTile>
				</div>
			))}
		</div>
	)
}
