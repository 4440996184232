import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { LinkFragment } from './LinkFragment'
import { VideoFragment } from './VideoFragment'

export const HeroFragment = () =>
	ZeusSelect<ValueTypes['Hero']>()({
		background: true,
		title: true,
		text: true,
		link: [{}, LinkFragment()],
		video: [{}, VideoFragment()],
	})

export type HeroResult = MapType<ModelTypes['Hero'], ReturnType<typeof HeroFragment>>
