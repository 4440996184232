import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const BannerTestimonialsItemFragment = () =>
	ZeusSelect<ValueTypes['BannerTestimonialsItem']>()({
		id: true,
		text: true,
		image: [{}, ImageFragment()],
		name: true,
		companyName: true,
	})

export type BannerTestimonialsItemResult = MapType<
	ModelTypes['BannerTestimonialsItem'],
	ReturnType<typeof BannerTestimonialsItemFragment>
>
