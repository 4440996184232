import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ResourceCategoryFragment } from './ResourceCategoryFragment'

export const ContentReferenceResourceCategoryFragment = () =>
	ZeusSelect<ValueTypes['ContentReferenceResourceCategory']>()({
		id: true,
		item: [{}, ResourceCategoryFragment()],
	})

export type ContentReferenceResourceCategoryResult = MapType<
	ModelTypes['ContentReferenceResourceCategory'],
	ReturnType<typeof ContentReferenceResourceCategoryFragment>
>
