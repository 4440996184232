import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './BlogHero.module.sass'
// import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import { Link } from './Link'

export type BlogHeroProps = {
	primaryText?: string
	secondaryText?: string
	primaryNumber?: number
	image?: ImageResult
	link?: LinkResult
}

export const BlogHero: FunctionComponent<BlogHeroProps> = ({
	primaryText,
	secondaryText,
	primaryNumber,
	image,
	link,
}) => {
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<div className={styles.leftSide}>
					{link && (
						<div className={styles.linkWrapper}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="8"
								height="13"
								viewBox="0 0 8 13"
								fill="none">
								<path
									d="M6.5 11.5L1.62022 6.76923C1.58227 6.7347 1.55202 6.69299 1.53135 6.64669C1.51067 6.60038 1.5 6.55046 1.5 6.5C1.5 6.44954 1.51067 6.39962 1.53135 6.35331C1.55202 6.30701 1.58227 6.2653 1.62022 6.23077L6.5 1.5"
									stroke="#1B00FB"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>

							<Link className={styles.link} href={contemberLinkToHref(link)}>
								{link.title}
							</Link>
						</div>
					)}

					{primaryText && (
						<h1 className={styles.title}>
							<CerosRichTextRenderer source={primaryText} />
						</h1>
					)}

					{secondaryText && (
						<p className={styles.info}>
							{secondaryText} {primaryNumber && `- ${primaryNumber} min read`}
						</p>
					)}
				</div>

				<div className={styles.rightSide}>
					{image && (
						<div className={styles.imageWrapper}>
							<Image
								className={styles.imageStyles}
								src={image.url}
								alt={image.fileName || image.alt!}
								fill
							/>
						</div>
					)}
				</div>
			</div>
		</Container>
	)
}
