import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const FeatureTileFragment = () =>
	ZeusSelect<ValueTypes['FeatureTile']>()({
		image: [{}, ImageFragment()],
		title: true,
		text: true,
	})

export type FeatureTileResult = MapType<
	ModelTypes['FeatureTile'],
	ReturnType<typeof FeatureTileFragment>
>
