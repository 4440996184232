import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent, ReactNode } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { InternalLinkResult } from '../data/content/InternalLinkFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { lazyBoundary } from '../images/configuration'
import styles from './CardTileContent.module.sass'
import { Link } from './Link'
import { Wysiwyg } from './Wysiwyg'

export type CardTileContentProps = {
	title?: string
	subtitleChildren?: ReactNode
	isSubtitleAbove?: boolean
	videoChildren?: ReactNode
	image?: ImageResult
	text?: string
	link?: LinkResult | InternalLinkResult
	footerChildren?: ReactNode
	isImageVisibleOnlyOnMobile?: boolean
	isTitleWithBackground?: boolean
	isMainContentCentered?: boolean
}

export const CardTileContent: FunctionComponent<CardTileContentProps> = ({
	title,
	isSubtitleAbove = false,
	isTitleWithBackground = false,
	subtitleChildren,
	image,
	videoChildren,
	text,
	link,
	footerChildren,
	isImageVisibleOnlyOnMobile = false,
	isMainContentCentered = false,
}) => {
	return (
		<CardTileContentLink link={link}>
			{image && (
				<div className={clsx(styles.image, isImageVisibleOnlyOnMobile && styles.is_mobile)}>
					<div className={styles.imageIn}>
						<Image
							lazyBoundary={lazyBoundary}
							src={image.url}
							layout="fill"
							objectFit="cover"
							alt={image.alt ?? ''}
						/>
					</div>
				</div>
			)}
			{videoChildren && <div className={styles.video}>{videoChildren}</div>}
			<div
				className={clsx(styles.mainContent, isMainContentCentered && styles.isMainContentCentered)}>
				{title && (
					<h3 className={clsx(styles.title, isTitleWithBackground && styles.is_withBackground)}>
						{title}
					</h3>
				)}
				{subtitleChildren && (
					<div className={clsx(styles.subtitle, isSubtitleAbove && styles.is_aboveTitle)}>
						{subtitleChildren}
					</div>
				)}
				{text && (
					<div className={styles.text}>
						<Wysiwyg source={text} />
					</div>
				)}
				{footerChildren && <div className={styles.footer}>{footerChildren}</div>}
			</div>
		</CardTileContentLink>
	)
}

export const CardTileContentLink: FunctionComponent<{
	link?: LinkResult | InternalLinkResult
	children?: ReactNode
	type?: 'default' | 'resourceBox'
}> = ({ link, children, type = 'default' }) => {
	if (link) {
		if ('url' in link) {
			return (
				<div className={clsx(styles.wrapper, styles[`is_${type}`])}>
					<Link href={link.url} className={styles.link} />
					{children}
				</div>
			)
		} else if (link.externalLink || link.internalLink) {
			const linkWithoutTitle: LinkResult = {
				...link,
				title: undefined,
			}
			return (
				<div className={clsx(styles.wrapper, styles[`is_${type}`])}>
					<Link link={linkWithoutTitle} className={styles.link} />
					{children}
				</div>
			)
		}
	}

	return <div className={clsx(styles.wrapper, styles[`is_${type}`])}>{children}</div>
}
