import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageHoverTileItemFragment } from './ImageHoverTileItemFragment'

export const ImageHoverListFragment = () =>
	ZeusSelect<ValueTypes['ImageHoverRevealList']>()({
		items: [{}, ImageHoverTileItemFragment()],
	})

export type FeatureTileListResult = MapType<
	ModelTypes['ImageHoverRevealList'],
	ReturnType<typeof ImageHoverListFragment>
>
