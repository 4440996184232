import { RefObject, useEffect, useState } from 'react'

export const useScrollProgress = (ref: RefObject<HTMLElement>) => {
	const [progress, setProgress] = useState(0)
	useEffect(() => {
		const handleScroll = () => {
			const timeline = ref.current
			if (timeline === null) {
				return
			}
			const { top, height } = timeline.getBoundingClientRect()
			const progress = (window.innerHeight - top) / height

			setProgress(Math.max(0, Math.min(1, progress)))
		}

		window.addEventListener('scroll', handleScroll, { passive: true })
		window.addEventListener('resize', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleScroll)
		}
	}, [ref])

	return progress
}
