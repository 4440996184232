import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { LogoItemFragment } from './LogoItemFragment'

export const LogoListFragment = () =>
	ZeusSelect<ValueTypes['LogoList']>()({
		items: [{}, LogoItemFragment()],
		labelType: true,
		maxLogosInRow: true,
	})

export type LogoListResult = MapType<ModelTypes['LogoList'], ReturnType<typeof LogoListFragment>>
