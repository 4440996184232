import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { useWindowSize } from 'react-use'
import { LinkType } from '../../generated/content'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import styles from './PricingDiscoverCeros.module.sass'

export type PricingDiscoverCerosProps = any

export const PricingDiscoverCeros: FunctionComponent<PricingDiscoverCerosProps> = ({
	...props
}) => {
	console.log(props.mobileImage)
	const { width } = useWindowSize()

	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<div className={styles.leftSide}>
					<p className={styles.primaryText}>{props.primaryText}</p>
					<p className={styles.secondaryText}>{props.secondaryText}</p>
					<div className={styles.buttonWrapper}>
						<Button
							style={{
								width: '100%',
							}}
							type="link"
							href={contemberLinkToHref(props.link)}
							fill="blue"
							target={
								props.link.type !== LinkType.scrollToTarget && props.link.isTargetBlank
									? '_blank'
									: undefined
							}>
							{props.link.title}
						</Button>
					</div>
				</div>
				<div className={styles.rightSide}>
					{width <= 768 ? (
						<Image
							className={styles.imageStyles}
							src={props.mobileImage.url}
							alt={props.mobileImage.fileName || props.mobileImage.alt}
							fill
						/>
					) : (
						<Image
							className={styles.imageStyles}
							src={props.image.url}
							alt={props.image.fileName || props.image.alt}
							fill
						/>
					)}
				</div>
			</div>
		</Container>
	)
}
