import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ResourceFragment } from './ResourceFragment'

export const ContentReferenceResourceFragment = () =>
	ZeusSelect<ValueTypes['ContentReferenceResource']>()({
		id: true,
		item: [{}, ResourceFragment()],
	})

export type ContentReferenceResourceResult = MapType<
	ModelTypes['ContentReferenceResource'],
	ReturnType<typeof ContentReferenceResourceFragment>
>
