import type { FunctionComponent } from 'react'
import { ArticleStatus, NewsItemType } from '../../generated/content'
import type { NewsroomResult } from '../data/content/NewsroomFragment'
import { useFormatDate } from '../utilities/useFormatDate'
import styles from './ArticleCarouselCards.module.sass'
import { CarouselCard } from './CarouselCard'
import { CarouselCards } from './CarouselCards'

export type ArticleCarouselCardsProps = NewsroomResult & { title?: string }

export const ArticleCarouselCards: FunctionComponent<ArticleCarouselCardsProps> = ({
	items,
	title,
}) => {
	return (
		<div className={styles.wrapper}>
			{title && <h2 className={styles.title}>{title}</h2>}
			<div className={styles.carousel}>
				<CarouselCards
					cards={{
						list: items.map((item) => {
							return {
								id: item.id,
								children:
									item.newsData?.type === NewsItemType.external ? (
										<CarouselCard
											image={item.image}
											title={item.newsData?.title}
											link={item.newsData?.link}
											footerChildren={
												<div className={styles.itemFooter}>
													<div>{item.newsData?.link?.title}</div>
													{item.newsData?.publishedAt && (
														<ArticleCarouselCardFooter date={item.newsData.publishedAt} />
													)}
												</div>
											}
										/>
									) : item.newsData?.type === NewsItemType.article &&
									  item.newsData?.article?.status === ArticleStatus.published ? (
										<CarouselCard
											image={item.image}
											title={item.newsData?.title}
											link={item.newsData?.article.link}
											footerChildren={
												<div className={styles.itemFooter}>
													<div>{item.newsData?.link?.title}</div>
													{item.newsData?.publishedAt && (
														<ArticleCarouselCardFooter date={item.newsData.publishedAt} />
													)}
												</div>
											}
										/>
									) : null,
							}
						}),
					}}
				/>
			</div>
		</div>
	)
}

const ArticleCarouselCardFooter: FunctionComponent<{ date: string }> = ({ date }) => {
	const formateDate = useFormatDate('en', date)
	return <div>{formateDate}</div>
}
