import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { GridBoxResult } from '../data/content/GridBoxFragment'
import type { GridBoxListResult } from '../data/content/GridBoxListFragment'
import type { ImageResult } from '../data/content/ImageFragment'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import styles from './GridBoxList.module.sass'
import { LogoList } from './LogoList'

export type GridBoxListProps = GridBoxListResult

export const GridBoxList: FunctionComponent<GridBoxListProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				{items.length > 0 && (
					<div className={styles.items}>
						{items.map(
							(item) =>
								item && (
									<div
										key={item.id}
										className={clsx(styles.item, item.isLogoList && styles.is_logoList)}>
										<GridBoxItem {...item} />
									</div>
								)
						)}
					</div>
				)}
			</Container>
		</div>
	)
}

const GridBoxItem: FunctionComponent<GridBoxResult> = ({
	title,
	isTitleBigger,
	isLogoList,
	logoList,
	text,
	image,
	mobileImage,
	backgroundImage,
	backgroundType,
}) => {
	const isOnlyTitle = !text && !image && isTitleBigger
	return (
		<div
			className={clsx(
				styles.box,
				isOnlyTitle && styles.is_onlyTitle,
				styles[`is_${backgroundType}`]
			)}>
			{backgroundImage && (
				<div className={styles.backgroundImage}>
					<Image
						className={styles.backgroundImageIn}
						src={backgroundImage.url}
						alt={backgroundImage.alt ?? backgroundImage.fileName ?? ''}
						fill
					/>
				</div>
			)}
			{title && <h3 className={clsx(styles.title, isTitleBigger && styles.is_bigger)}>{title}</h3>}
			{text && (
				<div className={styles.text}>
					<BreakableContent breakableFrom="768" text={text} />
				</div>
			)}
			<GridBoxImage image={mobileImage ?? image} />
			<GridBoxImage image={image ?? mobileImage} deviceType="desktop" />
			{isLogoList && logoList && (
				<div className={styles.logoList}>
					<div className={styles.is_desktop}>
						<LogoList {...logoList} view="bannerLogos" imageMaxArea={100} baseSize={100} />
					</div>
					<div className={styles.is_mobile}>
						<LogoList {...logoList} view="strip" />
					</div>
				</div>
			)}
		</div>
	)
}

type GridBoxImageProps = {
	image?: ImageResult
	deviceType?: 'mobile' | 'desktop'
}

const GridBoxImage: FunctionComponent<GridBoxImageProps> = ({ image, deviceType = 'mobile' }) => {
	if (!image) {
		return <></>
	}

	return (
		<div className={clsx(styles.image, styles[`is_${deviceType}`])}>
			<Image
				className={styles.imageIn}
				src={image.url}
				alt={image.alt ?? image.fileName ?? ''}
				width={image.width}
				height={image.height}
			/>
		</div>
	)
}
