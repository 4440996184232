import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { NewsroomItemFragment } from './NewsroomItemFragment'

export const NewsroomFragment = () =>
	ZeusSelect<ValueTypes['Newsroom']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			NewsroomItemFragment(),
		],
	})

export type NewsroomResult = MapType<ModelTypes['Newsroom'], ReturnType<typeof NewsroomFragment>>
