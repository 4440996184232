import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { InitiativesTileItemFragment } from './InitiativesTileItemFragment'

export const InitiativesListFragment = () =>
	ZeusSelect<ValueTypes['InitiativesList']>()({
		items: [{}, InitiativesTileItemFragment()],
	})

export type InitiativesListFragment = MapType<
	ModelTypes['InitiativesList'],
	ReturnType<typeof InitiativesListFragment>
>
