import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'

export const TextListFragment = () =>
	ZeusSelect<ValueTypes['TextList']>()({
		items: [
			{ orderBy: [{ order: OrderDirection.asc }] },
			{
				id: true,
				title: true,
				text: true,
			},
		],
	})

export type TextListResult = MapType<ModelTypes['TextList'], ReturnType<typeof TextListFragment>>
