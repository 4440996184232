import clsx from 'clsx'
import Image from 'next/image'
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'
import type { TabWithRevealableMediaContentListResult } from '../data/content/TabWithRevealableMediaContentListFragment'
import type { VideoSelectResult } from '../data/content/VideoSelectFragment'
import { CardTile } from './CardTile'
import { CardTileContent } from './CardTileContent'
import { Icon } from './Icon'
import { ImageSizeByArea } from './ImageSizeByArea'
import { VideoModal } from './Modal'
import styles from './TabsWithRevealableMediaContent.module.sass'

export type TabsWithRevealableMediaContentProps = TabWithRevealableMediaContentListResult

export const TabsWithRevealableMediaContent: FunctionComponent<
	TabsWithRevealableMediaContentProps
> = ({ items }) => {
	const [isMobileActiveTabId, setMobileActiveTabId] = useState<string | null>(null)
	const [isDesktopActiveTabId, setDesktopActiveTabId] = useState<string | null>(
		items.length > 0 ? items[0].id : null
	)
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div
					className={clsx(
						styles.mediaContentList,
						isMobileActiveTabId && styles.is_mobileOpen,
						isDesktopActiveTabId && styles.is_desktopOpen
					)}>
					{items.map(
						(item) =>
							item?.item?.title && (
								<MediaContentCard
									key={item.id}
									id={item.id}
									item={item.item}
									isMobileActiveTabId={isMobileActiveTabId}
									setMobileActiveTabId={setMobileActiveTabId}
									isDesktopActiveTabId={isDesktopActiveTabId}
								/>
							)
					)}
				</div>
				{items.map(
					(item) =>
						item?.item?.tabImage && (
							<TabItem
								key={item.id}
								id={item.id}
								item={item.item}
								isMobileActiveTabId={isMobileActiveTabId}
								setMobileActiveTabId={setMobileActiveTabId}
								isDesktopActiveTabId={isDesktopActiveTabId}
								setDesktopActiveTabId={setDesktopActiveTabId}
							/>
						)
				)}
			</div>
		</div>
	)
}

const TabItem: FunctionComponent<{
	id: NonNullable<TabWithRevealableMediaContentListResult['items'][number]['id']>
	item: NonNullable<TabWithRevealableMediaContentListResult['items'][number]['item']>
	setMobileActiveTabId: Dispatch<SetStateAction<string | null>>
	isMobileActiveTabId: string | null
	setDesktopActiveTabId: Dispatch<SetStateAction<string | null>>
	isDesktopActiveTabId: string | null
}> = ({
	id,
	item,
	setMobileActiveTabId,
	isMobileActiveTabId,
	setDesktopActiveTabId,
	isDesktopActiveTabId,
}) => {
	return (
		<button
			type="button"
			className={clsx(
				styles.tab,
				isMobileActiveTabId === id && styles.is_active,
				isDesktopActiveTabId === id && styles.is_desktopActive
			)}
			onClick={() => {
				setMobileActiveTabId(id)
				setDesktopActiveTabId(id)
			}}>
			{item?.tabImage && <ImageSizeByArea image={item.tabImage} baseSize={80} imageMaxArea={170} />}
			<div className={styles.plus} />
		</button>
	)
}

const MediaContentCard: FunctionComponent<{
	id: NonNullable<TabWithRevealableMediaContentListResult['items'][number]['id']>
	item: NonNullable<TabWithRevealableMediaContentListResult['items'][number]['item']>
	setMobileActiveTabId: Dispatch<SetStateAction<string | null>>
	isMobileActiveTabId: string | null
	isDesktopActiveTabId: string | null
}> = ({ id, item, isMobileActiveTabId, setMobileActiveTabId, isDesktopActiveTabId }) => {
	return (
		<div
			className={clsx(
				styles.card,
				isMobileActiveTabId === id && styles.is_open,
				isDesktopActiveTabId === id && styles.is_desktopOpen
			)}>
			<button
				type="button"
				className={styles.cardClose}
				onClick={() => setMobileActiveTabId(null)}
			/>
			<CardTile>
				<CardTileContent
					isMainContentCentered
					videoChildren={
						<>
							<CardPoster video={item.videoSelect} deviceType="desktop" />
							<CardPoster video={item.videoSelect} />
						</>
					}
					title={item.title}
					subtitleChildren={
						item.tags &&
						item.tags.length > 0 && (
							<ul className={styles.tags}>
								{item.tags.map(
									(item) =>
										item && (
											<li key={item.id} className={styles.tag}>
												{item.text}
											</li>
										)
								)}
							</ul>
						)
					}
					isSubtitleAbove
					text={item.text}
				/>
			</CardTile>
		</div>
	)
}

type CardPosterProps = {
	video?: VideoSelectResult
	deviceType?: 'mobile' | 'desktop'
}

const CardPoster: FunctionComponent<CardPosterProps> = ({ deviceType = 'mobile', video }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<>
			<button
				type="button"
				className={clsx(styles.cardPoster, styles[`is_${deviceType}`])}
				onClick={() => setIsModalOpen(true)}
				style={{
					'--TabsWithRevealableMediaContent-posterMobile-width':
						video?.poster?.width ?? deviceType === 'mobile' ? 331 : 0,
					'--TabsWithRevealableMediaContent-posterMobile-height':
						video?.poster?.height ?? deviceType === 'mobile' ? 236 : 0,
					'--TabsWithRevealableMediaContent-poster-width':
						video?.poster?.width ?? deviceType === 'desktop' ? 716 : 0,
					'--TabsWithRevealableMediaContent-poster-height':
						video?.poster?.height ?? deviceType === 'desktop' ? 362 : 0,
				}}>
				<div className={styles.cardPosterIn}>
					{video?.poster && (
						<Image
							src={video.poster.url}
							alt={video.poster.alt ?? video.poster.fileName ?? ''}
							fill
						/>
					)}
				</div>
				<div className={styles.playButton}>
					<Icon name="playButton" />
				</div>
			</button>
			{video && (
				<VideoModal
					isOpen={isModalOpen}
					onRequestClose={() => {
						setIsModalOpen(false)
					}}
					video={video}
				/>
			)}
		</>
	)
}
