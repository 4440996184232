import type { FunctionComponent } from 'react'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './Gallery.module.sass'

export type GalleryProps = {
	gallery: MediumListResult
}

export const Gallery: FunctionComponent<GalleryProps> = ({ gallery }) => {
	return (
		<Container size="normal">
			<div className={styles.gallery}>
				{gallery.items.map((item) => (
					<div className={styles.image} key={item.id}>
						{item.medium?.image && (
							<ContemberImage image={item.medium?.image} layout="intrinsic" objectFit="cover" />
						)}
					</div>
				))}
			</div>
		</Container>
	)
}
