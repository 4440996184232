import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import { LinkType } from '../../generated/content'
import type { ProductResult } from '../data/content/ProductFragment'
import { lazyBoundary } from '../images/configuration'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { CardTile } from './CardTile'
import styles from './PricingProductCard.module.sass'

export type PricingProductCardProps = ProductResult

export const PricingProductCard: FunctionComponent<PricingProductCardProps> = ({
	icon,
	name,
	pricingDescription,
	pricingLink,
	pricingImage,
	backgroundColor,
}) => {
	const colors: Record<any, any> = {
		green: '#DFFA9E',
		purple: '#F0E6FF',
		blue: '#E2F1FD',
		pink: '#FCE5EF',
	}

	return (
		<div className={styles.wrapper}>
			<CardTile>
				<div className={styles.main} style={{ backgroundColor: colors[backgroundColor as any] }}>
					<div className={styles.content}>
						<div style={{ width: '100%', display: 'flex', gap: '10px' }}>
							{icon && (
								<div
									className={styles.icon}
									style={
										{
											'--ProductCard-icon-width': icon.width,
											'--ProductCard-icon-height': icon.height,
										} as React.CSSProperties
									}>
									<Image
										lazyBoundary={lazyBoundary}
										src={icon.url}
										layout="fill"
										objectFit="contain"
										alt={icon.alt ?? ''}
									/>
								</div>
							)}
							{name && <h3 className={styles.name}>{name}</h3>}
						</div>
						{pricingDescription && <p className={styles.description}>{pricingDescription}</p>}
						{pricingLink && (
							<div style={{ padding: '2rem 0rem' }}>
								<Button
									style={{
										width: '100%',
									}}
									type="link"
									href={contemberLinkToHref(pricingLink!)}
									fill="blue"
									target={
										pricingLink?.type !== LinkType.scrollToTarget && pricingLink?.isTargetBlank
											? '_blank'
											: undefined
									}>
									{pricingLink?.title}
								</Button>
							</div>
						)}
					</div>
					<div className={styles.imageContainer}>
						{pricingImage?.url && (
							<Image
								layout="fill"
								objectPosition="center"
								objectFit="fill"
								src={pricingImage?.url}
								alt="dwqdqwdqw"
							/>
						)}
					</div>
				</div>
			</CardTile>
		</div>
	)
}
