import type { FunctionComponent } from 'react'
import type { CaseStudyListResult } from '../data/content/CaseStudyListFragment'
import { CarouselCard } from './CarouselCard'
import { CarouselCards } from './CarouselCards'
import styles from './CaseStudyCarouselCards.module.sass'

export type CaseStudyCarouselCardsProps = CaseStudyListResult & { title?: string }

export const CaseStudyCarouselCards: FunctionComponent<CaseStudyCarouselCardsProps> = ({
	items,
	title,
}) => {
	return (
		<div className={styles.wrapper}>
			{title && <h2 className={styles.title}>{title}</h2>}
			<div className={styles.carousel}>
				<CarouselCards
					cards={{
						list: items.map((item) => {
							return {
								id: item.id,
								children: (
									<CarouselCard
										image={item.image}
										title={item.caseStudy?.page?.name}
										text={item.text}
										link={item.caseStudy?.page?.link}
									/>
								),
							}
						}),
					}}
				/>
			</div>
		</div>
	)
}
