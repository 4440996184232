import clsx from 'clsx'
import Image from 'next/legacy/image'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { BackgroundType, BannerType, ContentAlignment, LinkType } from '../../generated/content'
import type { BannerResult } from '../data/content/BannerFragment'
import type { FormResult } from '../data/content/FormFragment'
import type { ImageResult } from '../data/content/ImageFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { lazyBoundary } from '../images/configuration'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useIsInView } from '../utilities/useIsInView'
import styles from './Banner.module.sass'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { Container } from './Container'
import { Form } from './Form'
import { Icon } from './Icon'
import { WaitlistForm } from './WaitlistForm'
import { Wysiwyg } from './Wysiwyg'

export type BannerProps = {
	data: BannerResult
	text?: string
	description?: string
	link?: LinkResult
	background?: BackgroundType
	form?: FormResult
	isWaitlist?: boolean
	backgroundImage?: ImageResult
	contentAlignment?: ContentAlignment
}

export const Banner: FunctionComponent<BannerProps> = ({
	data,
	text,
	description,
	background,
	link,
	form,
	isWaitlist = false,
	backgroundImage,
	contentAlignment = ContentAlignment.left,
}) => {
	const { type } = data

	const [isInitialInView, setIsInitialInView] = useState(false)
	const bannerRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(bannerRef, {
		threshold: 0.9,
	})

	useEffect(() => {
		if (isInView) {
			setIsInitialInView(isInView)
		}
	}, [isInView])
	return (
		<div
			className={clsx(
				styles.banner,
				isInitialInView && styles.is_initialInView,
				isInView && styles.is_inView
			)}
			ref={bannerRef}>
			<div
				className={clsx(
					styles.main,
					styles[`view_${type}`],
					styles[`is_background_${background}`],
					backgroundImage && styles[`is_backgroundImage`],
					isWaitlist && styles.is_waitlist
				)}>
				<Container>
					<div
						className={clsx(
							styles.content,
							contentAlignment === ContentAlignment.center && styles.is_center
						)}>
						{text && (
							<h2 className={styles.title}>
								<BreakableContent breakableFrom="768">
									<Wysiwyg source={text} />
								</BreakableContent>
							</h2>
						)}
						{description && (
							<div className={styles.description}>
								<Wysiwyg source={description} />
							</div>
						)}
						{type === BannerType.form && form && isWaitlist && (
							<div className={clsx(styles.form, styles.is_waitlist)}>
								<WaitlistForm formId={form.hubspotFormId} submitButtonColor="chartreuseGreen" />
							</div>
						)}
						{link && !isWaitlist && (
							<div className={styles.link}>
								<Button
									type="link"
									href={contemberLinkToHref(link)}
									fill={background === 'blue' ? 'dark' : 'blue'}
									target={
										link.type !== LinkType.scrollToTarget && link.isTargetBlank
											? '_blank'
											: undefined
									}>
									{link.title}
								</Button>
							</div>
						)}
					</div>
				</Container>
				{type === BannerType.form && form && !isWaitlist && (
					<div className={styles.form}>
						<Form form={form} />
					</div>
				)}
			</div>
			{backgroundImage ? (
				<div className={styles.backgroundImage}>
					<Image
						src={backgroundImage.url}
						layout="fill"
						objectFit="cover"
						alt={backgroundImage.alt ?? backgroundImage.fileName ?? ''}
					/>
				</div>
			) : form && isWaitlist ? (
				<div className={styles.shapes}>
					<div className={styles.shapeColumn}>
						<div className={styles.shape}>
							<Icon name="splashStarOutline" />
						</div>
						<div className={styles.shape}>
							<Icon name="ball" />
						</div>
						<div className={styles.shape}>
							<Icon name="triangleOutline" />
						</div>
					</div>
					<div className={styles.shapeColumn}>
						<div className={styles.shape}>
							<Icon name="triangle" />
						</div>
						<div className={styles.shape}>
							<Icon name="fourTipStar" />
						</div>
						<div className={styles.shape}>
							<Icon name="circle" />
						</div>
					</div>
				</div>
			) : background === 'black' ? (
				<div className={styles.image}>
					<Image
						lazyBoundary={lazyBoundary}
						src="/wave.png"
						layout="fill"
						objectFit="cover"
						alt=""
					/>
				</div>
			) : (
				background === 'blue' && (
					<div className={styles.image}>
						<Image
							lazyBoundary={lazyBoundary}
							src="/wave2.png"
							layout="fill"
							objectFit="cover"
							objectPosition="right top"
							alt=""
						/>
					</div>
				)
			)}
		</div>
	)
}
