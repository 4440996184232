import type { FunctionComponent } from 'react'
import type { BannerTestimonialsItemResult } from '../data/content/BannerTestimonialsItemFragment'
import { AuthorBadge } from './AuthorBadge'
import styles from './BannerTestimonialsCard.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type BannerTestimonialsCardProps = BannerTestimonialsItemResult

export const BannerTestimonialsCard: FunctionComponent<BannerTestimonialsCardProps> = ({
	text,
	image,
	name,
	companyName,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				{text && (
					<div className={styles.text}>
						<Wysiwyg source={text} />
					</div>
				)}
				<div className={styles.author}>
					<AuthorBadge type="banner" image={image} name={name} companyName={companyName} />
				</div>
			</div>
		</div>
	)
}
