import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const InteractionBlockFragment = () =>
	ZeusSelect<ValueTypes['FeatureTile']>()({
		text: true,
	})

export type InteractionBlockResult = MapType<
	ModelTypes['FeatureTile'],
	ReturnType<typeof InteractionBlockFragment>
>
