import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { InteractionBlockTileItemFragment } from './InteractionBlockTileItemFragment'

export const InteractionBlockListFragment = () =>
	ZeusSelect<ValueTypes['InteractionBlockList']>()({
		items: [{}, InteractionBlockTileItemFragment()],
	})

export type InteractionBlockTileListResult = MapType<
	ModelTypes['InteractionBlockList'],
	ReturnType<typeof InteractionBlockListFragment>
>
