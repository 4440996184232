import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import styles from './TableOfContents.module.sass'

export const TableOfContents: FunctionComponent<any> = ({ linkList }) => {
	return (
		<Container size={'normal'}>
			<div className={styles.wrapper}>
				<p className={styles.blockTitle}>Table of Contents</p>
				<ul className={styles.linksContainer}>
					{linkList.items.map((link: any) => {
						return (
							<li key={link.id} className={styles.linkTitle}>
								<Link href={contemberLinkToHref(link.link)}> {link.link.title}</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</Container>
	)
}
