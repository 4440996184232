import type { ChatProps } from '../components/Chat'
import backgroundA from '../images/gemma/background-a.jpg'
import backgroundB from '../images/gemma/background-b.jpg'
import backgroundC from '../images/gemma/background-c.jpg'
import backgroundD from '../images/gemma/background-d.jpg'
import backgroundDetail from '../images/gemma/background-detail.jpg'
import backpackDark from '../images/gemma/backpack-dark.jpg'
import backpackLight from '../images/gemma/backpack-light.jpg'
import mountainDark from '../images/gemma/mountain-dark.jpg'
import mountainLight from '../images/gemma/mountain-light.jpg'
import tentLight from '../images/gemma/tent-light.jpg'
import wheelDark from '../images/gemma/wheel-dark.jpg'
import wheelLight from '../images/gemma/wheel-light.jpg'

export type NothingEvent = {
	type: 'nothing'
}

export type ChatEvent = {
	type: 'chat'
	message: ChatProps['messages'][number]
}

export type ChatSlideToSideEvent = {
	type: 'chat-slide-to-side'
}

export type TitleEvent = {
	type: 'title'
	text: string
}

export type TextEvent = {
	type: 'text'
	text: string
}

export type AddBackgroundEvent = {
	type: 'add-background'
}

export type ImageEvent = {
	type: 'image'
	src: string | null
}

export type IconAEvent = {
	type: 'iconA'
	src: string | null
}

export type IconBEvent = {
	type: 'iconB'
	src: string | null
}

export type IconCEvent = {
	type: 'iconC'
	src: string | null
}

export type CatchPhraseEvent = {
	type: 'catch-phrase'
	title: string
	text: string
}

export type CursorPosition = 'chat' | 'title' | 'text' | 'image' | 'iconA' | 'iconB' | 'iconC'
export type MoveCursorEvent = {
	type: 'move-cursor'
	destination: CursorPosition
}

export type TimelineEvent = (
	| NothingEvent
	| ChatEvent
	| ChatSlideToSideEvent
	| TitleEvent
	| TextEvent
	| AddBackgroundEvent
	| ImageEvent
	| IconAEvent
	| IconBEvent
	| IconCEvent
	| MoveCursorEvent
	| CatchPhraseEvent
) & {
	duration: number
}

export const gemmaAnimationEvents: Readonly<TimelineEvent[]> = [
	{
		type: 'chat',
		message: {
			sender: 'remote',
			text: "Hi, I'm Gemma. I'm a creative design assistant. I can help with your design projects. Ask me anything.",
		},
		duration: 200,
	},
	{
		type: 'chat-slide-to-side',
		duration: 600,
	},
	{
		type: 'catch-phrase',
		title: 'Talk to Gemma like a creative',
		text: 'Share thoughts, ideas, and even bounce around creative concepts with Gemma.',
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: "Hi Gemma, I'm creating a landing page about Nature!",
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'remote',
			text: 'Sounds like an exciting project. What do you need I can help you find images, videos, facts, and more!',
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: 'Could you give me a headline and body copy about the mysteries of nature?',
		},
		duration: 200,
	},
	{
		type: 'catch-phrase',
		title: 'No more Lorem ipsum',
		text: 'Ask Gemma for help writing just about anything, from headlines and body text to brand names.',
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'remote',
			text: 'Sure thing! How about this headline: "Discover the Wonders of The National Parks" And for the body copy, something like: "Embark on a journey through America’s breathtaking national parks. Each park offers a unique adventure, from awe-inspiring landscapes to diverse wildlife."',
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: 'Perfect!',
		},
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'chat',
		duration: 200,
	},
	{
		type: 'nothing',
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'title',
		duration: 200,
	},
	{
		type: 'nothing',
		duration: 200,
	},
	{
		type: 'title',
		text: 'Discover the Wonders of The National Parks',
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'text',
		duration: 200,
	},
	{
		type: 'nothing',
		duration: 200,
	},
	{
		type: 'text',
		text: 'Embark on a journey through America’s breathtaking national parks. Each park offers a unique adventure, from awe-inspiring landscapes to diverse wildlife.',
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'remote',
			text: 'What else do you need?',
		},
		duration: 200,
	},
	{
		type: 'catch-phrase',
		title: 'Visualize whatever is in your mind',
		text: 'Gemma is capable of creating ultra realistic imagery, which can be upscaled and edited.',
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: 'Can you give me some moody nature images!',
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'remote',
			text: 'Absolutely! What do you think of these?',
			images: [backgroundA, backgroundB, backgroundC, backgroundD],
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: 'This is great, thanks Gemma!',
		},
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'chat',
		duration: 200,
	},
	{
		type: 'nothing',
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'image',
		duration: 200,
	},
	{
		type: 'image',
		src: backgroundDetail.src,
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: 'Can you give me some nature exploration icons?',
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'remote',
			text: 'Sure! Here you go!',
			images: [mountainLight, backpackLight, wheelLight, tentLight],
		},
		duration: 200,
	},
	{
		type: 'chat',
		message: {
			sender: 'local',
			text: 'These are perfect!',
		},
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'chat',
		duration: 200,
	},
	{
		type: 'nothing',
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'iconA',
		duration: 200,
	},
	{
		type: 'iconA',
		src: mountainDark.src,
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'iconB',
		duration: 200,
	},
	{
		type: 'iconB',
		src: wheelDark.src,
		duration: 200,
	},
	{
		type: 'move-cursor',
		destination: 'iconC',
		duration: 200,
	},
	{
		type: 'iconC',
		src: backpackDark.src,
		duration: 200,
	},
	{
		type: 'catch-phrase',
		title: '',
		text: '',
		duration: 200,
	},
	{
		type: 'add-background',
		duration: 600,
	},
]
