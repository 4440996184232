import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export function LinkFragment() {
	return ZeusSelect<ValueTypes['Link']>()({
		title: true,
		type: true,
		internalLink: [
			{},
			{
				url: true,
			},
		],
		externalLink: true,
		isTargetBlank: true,
	})
}

export type LinkResult = MapType<ModelTypes['Link'], ReturnType<typeof LinkFragment>>
