import { FunctionComponent, useMemo, useRef } from 'react'
import styles from './Chat.module.sass'
import { ChatBubble, ChatBubbleProps } from './ChatBubble'
import { Icon } from './Icon'

export interface ChatProps {
	messages: Array<Omit<ChatBubbleProps, 'isActive'>>
}

export const Chat: FunctionComponent<ChatProps> = ({ messages }) => {
	const allKnownMessages = useRef(messages)

	const messagesWithActive = useMemo<ChatBubbleProps[]>(() => {
		if (allKnownMessages.current.length < messages.length) {
			allKnownMessages.current = messages
		}
		return allKnownMessages.current.map((message, i) => {
			const isActive = i < messages.length
			return {
				...message,
				isActive,
			}
		})
	}, [messages])

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.logo}>
					<Icon name="chatLogo" />
				</div>
				Gemma
				<div className={styles.close} />
				<div className={styles.title}>
					<div className={styles.back} />
					🏔 Exploring Nature
				</div>
			</div>
			<div className={styles.messages}>
				<div className={styles.messages_in}>
					{messagesWithActive.map((message, i) => (
						<ChatBubble key={i} {...message} />
					))}
				</div>
			</div>
			<div className={styles.footer}>
				<div className={styles.input}>
					<div className={styles.text}>{/* @TODO */}</div>
					<div className={styles.placeholder}>Tell me your wish…</div>
				</div>
			</div>
		</div>
	)
}
