import type { FunctionComponent } from 'react'
import type { ArticleResult } from '../data/content/ArticleFragment'
import styles from './Article.module.sass'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Share } from './Share'

export type ArticleProps = {
	article: ArticleResult
}

export const Article: FunctionComponent<ArticleProps> = ({ article }) => {
	return (
		<div className={styles.wrapper}>
			<Container size="normal">
				<h2 className={styles.title}>{article.newsItem?.title}</h2>
				<p className={styles.leadParagraph}>{article.leadParagraph}</p>
				<div className={styles.info}>
					<p className={styles.readingTime}>{article.readingTime} Minute Read</p>
					<Share shareTitle="Share this release" />
				</div>
			</Container>
			{article.content && <ContentRenderer content={article.content} />}
		</div>
	)
}
