import { FunctionComponent, useCallback, useMemo } from 'react'
import { FormType } from '../../generated/content'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { useHubspotForm } from '../contexts/HubspotFormsContext'
import type { FormResult } from '../data/content/FormFragment'
import type { ButtonProps } from './Button'
import { CustomForm, CustomFormProps } from './CustomForm'
import styles from './Form.module.sass'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import { HubspotForm } from './HubspotForm'

export type FormProps = {
	form: FormResult
	place?: CustomFormProps['place']
	isFirstInputHighlighted?: boolean
	isButtonFullWidth?: ButtonProps['isFullWidth']
}

export const Form: FunctionComponent<FormProps> = ({
	form,
	place,
	isFirstInputHighlighted,
	isButtonFullWidth,
}) => {
	const hubspotForm = useHubspotForm()
	return (
		<div className={styles.wrapper}>
			{form.formType === FormType.custom ? (
				<CustomForm
					form={form}
					isFirstInputHighlighted={isFirstInputHighlighted}
					isButtonFullWidth={isButtonFullWidth}
					place={place}
				/>
			) : (
				form.hubspotFormId &&
				(form.isHubspotFormEmbed ? (
					<HubspotEmbedForm formId={form.hubspotFormId} region={form.hubspotFormRegion} />
				) : (
					hubspotForm && (
						<GetHubspotForm
							results={hubspotForm.list}
							formId={form.hubspotFormId}
							portalId={hubspotForm.portalId}
						/>
					)
				))
			)}
		</div>
	)
}

export type GetHubspotFormProps = CommonPageProps['hubspotForm']['list'] & {
	formId: FormResult['hubspotFormId']
} & {
	portalId: CommonPageProps['hubspotForm']['portalId']
}

export const GetHubspotForm: FunctionComponent<GetHubspotFormProps> = ({
	results,
	formId,
	portalId,
}) => {
	const formFields = useCallback(
		(
			fieldGroups: NonNullable<
				NonNullable<
					NonNullable<CommonPageProps['hubspotForm']['list']>['results'][number]
				>['fieldGroups']
			>
		) => fieldGroups.flatMap((field) => field.fields.flatMap((field) => field)),
		[]
	)

	const form = useMemo(() => {
		return results?.find((form) => form.id === formId)
	}, [formId, results])

	return (
		<div className={styles.wrapper}>
			{form && portalId && (
				<HubspotForm
					key={form.id}
					formId={form.id}
					portalId={portalId}
					name={form.name}
					fields={formFields(form.fieldGroups)}
					submitButtonLabel={form.displayOptions?.submitButtonText}
					isButtonFullWidth
					note={form.legalConsentOptions?.privacyText}
				/>
			)}
		</div>
	)
}
