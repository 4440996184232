import clsx from 'clsx'
import Script from 'next/script'
import type { FunctionComponent } from 'react'
import { iframeSandbox } from '../utilities/iframeSandbox'
import styles from './Frame.module.sass'

export type FrameProps = {
	title?: string
	frameUrl: string
	aspectRatio: number
	aspectRatioMobile?: number
}

export const Frame: FunctionComponent<FrameProps> = ({
	frameUrl,
	aspectRatio,
	aspectRatioMobile = aspectRatio,
	title,
}) => {
	return (
		<div
			className={styles.wrapper}
			style={{
				'--Frame-iframe-aspectRatio': aspectRatio,
				'--Frame-iframe-aspectRatioMobile': aspectRatioMobile,
			}}>
			<iframe
				allowFullScreen
				sandbox={iframeSandbox}
				className={clsx(styles.iframe, 'ceros-experience')}
				src={frameUrl}
				frameBorder="0"
				title={title}
				scrolling="no"
			/>
			<Script
				id="scroll-proxy.min.js"
				src="//view.ceros.com/scroll-proxy.min.js"
				data-ceros-origin-domains="view.ceros.com"
				strategy="afterInteractive"
			/>
		</div>
	)
}
