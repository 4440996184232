import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { LogoListFragment } from './LogoListFragment'

export const BoxFragment = () =>
	ZeusSelect<ValueTypes['Box']>()({
		type: true,
		backgroundColor: true,
		image: [{}, ImageFragment()],
		highlightedTitleList: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			{
				id: true,
				title: true,
			},
		],
		items: [
			{},
			{
				title: true,
				text: true,
			},
		],
		logoList: [{}, LogoListFragment()],
	})

export type BoxResult = MapType<ModelTypes['Box'], ReturnType<typeof BoxFragment>>
