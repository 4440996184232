import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { useIsInView } from '../utilities/useIsInView'
import { Container } from './Container'
import styles from './HumblebragsThree.module.sass'
import { Icon } from './Icon'

export const HumblebragsThree = () => {
	const { y: scrollY } = useWindowScroll()

	const [rotationDegrees, setRotationDegrees] = useState<number>(0)

	const starRef = useRef<HTMLDivElement>(null)
	const isInView = useIsInView(starRef, {
		threshold: 0.1,
	})

	useEffect(() => {
		setRotationDegrees(scrollY * 0.75)
	}, [scrollY])

	return (
		<Container size={'normal'}>
			<div className={styles.parent}>
				<div className={clsx(styles.div1)} style={{ backgroundColor: '#FAFAFA' }}>
					<p className={styles.cardDescription}>
						LastPass’ Ceros project won a 2022 Content Marketing Award for Visual Storytelling –
						<span style={{ color: 'blue' }}>Best Overall Design</span>
					</p>
				</div>
				<div
					className={styles.shape1}
					style={{
						color: '#CFC3FF',
						transition: 'transform 0.2s linear',
						transform: `rotate(${rotationDegrees}deg)`,
					}}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="179"
						height="179"
						viewBox="0 0 179 179"
						fill="none">
						<path
							d="M51.0965 9.37427C51.995 0.808003 62.3948 -2.92582 68.5369 3.1127L108.213 42.1198C109.872 43.7514 112.036 44.7719 114.351 45.0147L169.686 50.8189C178.253 51.7174 181.986 62.1172 175.948 68.2592L136.941 107.935C135.309 109.595 134.289 111.758 134.046 114.073L128.242 169.409C127.343 177.975 116.943 181.709 110.801 175.67L71.1255 136.663C69.466 135.032 67.3022 134.011 64.9876 133.768L9.65193 127.964C1.08566 127.066 -2.64815 116.666 3.39036 110.524L42.3974 70.8479C44.029 69.1883 45.0496 67.0245 45.2923 64.7099L51.0965 9.37427Z"
							fill="#006BB8"
						/>
					</svg>
				</div>
				<div style={{ position: 'relative' }} className={clsx(styles.div2)}>
					<div className={styles.grandSlamBaby}>
						<Icon name="grandSlamBaby" />
					</div>
					<p className={styles.cardDescription}>
						2021: MarkUp receives{' '}
						<span style={{ color: '#008FF5' }}>Product of the Day, Week, Month and Year</span> on
						Product Hunt.{' '}
					</p>
				</div>
				<div style={{ marginTop: '40px' }} className={styles.shape2}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="300"
						height="190"
						viewBox="0 0 300 190"
						fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0.811484 51.8577C0.811482 69.8958 10.0485 85.7717 24.0434 94.9999C10.0485 104.228 0.811478 120.104 0.811476 138.142C0.811474 166.65 23.8849 189.759 52.3472 189.759C74.6349 189.759 93.619 175.588 100.806 155.751C107.993 175.588 126.977 189.759 149.265 189.759C172.402 189.759 191.978 174.489 198.493 153.461C205.009 174.489 224.585 189.759 247.721 189.759C276.184 189.759 299.257 166.65 299.257 138.142C299.257 120.104 290.02 104.228 276.025 94.9999C290.02 85.7717 299.257 69.8958 299.257 51.8577C299.257 23.3501 276.184 0.241209 247.721 0.241206C224.585 0.241204 205.009 15.5107 198.493 36.5386C191.978 15.5107 172.402 0.2412 149.265 0.241198C126.977 0.241196 107.993 14.4123 100.806 34.2485C93.619 14.4123 74.6349 0.241191 52.3472 0.241189C23.8849 0.241187 0.811486 23.3501 0.811484 51.8577Z"
							fill="#CFC3FF"
						/>
					</svg>
				</div>
				<div
					style={{ background: '#FE5621' }}
					className={clsx(styles.div3, styles.coloredBoxContainer)}>
					<p className={styles.percentage} style={{ color: '#fff' }}>
						15+
					</p>
					<p style={{ maxWidth: '204px', color: '#fff' }} className={styles.message}>
						years driving creativity through Ceros products.
					</p>
				</div>
				<div className={styles.shape3} ref={starRef}>
					<svg
						style={{
							transition: 'transform 0.2s linear',
							transform: `rotate(${rotationDegrees}deg)`,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="196"
						height="196"
						viewBox="0 0 196 196"
						fill="none">
						<g clip-path="url(#clip0_1992_208148)">
							<path
								d="M190.526 90.5155C197.275 92.8191 197.275 102.363 190.526 104.667L171.995 110.993C166.963 112.711 165.282 118.985 168.781 122.989L181.666 137.732C186.359 143.102 181.587 151.367 174.591 149.988L155.379 146.201C150.162 145.172 145.569 149.765 146.598 154.982L150.385 174.194C151.764 181.19 143.499 185.962 138.13 181.269L123.386 168.384C119.382 164.884 113.108 166.566 111.39 171.598L105.064 190.129C102.761 196.878 93.2162 196.878 90.9126 190.129L84.5869 171.598C82.8691 166.566 76.595 164.884 72.591 168.384L57.8472 181.269C52.478 185.962 44.2124 181.19 45.5916 174.194L49.379 154.982C50.4075 149.765 45.8146 145.172 40.5974 146.201L21.3861 149.988C14.39 151.367 9.61781 143.102 14.3103 137.732L27.1959 122.989C30.6952 118.985 29.0141 112.711 23.9816 110.993L5.45048 104.667C-1.29795 102.363 -1.29795 92.8191 5.45048 90.5155L23.9817 84.1898C29.0141 82.4719 30.6952 76.1978 27.196 72.1939L14.3103 57.4501C9.61781 52.0809 14.39 43.8153 21.3861 45.1945L40.5974 48.9819C45.8146 50.0104 50.4075 45.4174 49.379 40.2002L45.5916 20.9889C44.2124 13.9928 52.478 9.22066 57.8472 13.9132L72.591 26.7988C76.595 30.2981 82.8691 28.6169 84.5869 23.5845L90.9126 5.05332C93.2162 -1.6951 102.761 -1.6951 105.064 5.05333L111.39 23.5845C113.108 28.6169 119.382 30.2981 123.386 26.7988L138.13 13.9132C143.499 9.22066 151.764 13.9928 150.385 20.9889L146.598 40.2002C145.569 45.4174 150.162 50.0104 155.379 48.9819L174.591 45.1945C181.587 43.8153 186.359 52.0809 181.666 57.4501L168.781 72.1939C165.282 76.1978 166.963 82.4719 171.995 84.1898L190.526 90.5155Z"
								fill="#99D2FB"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1992_208148">
								<rect
									width="195.199"
									height="195.198"
									fill="white"
									transform="translate(195.588 -0.00799561) rotate(90)"
								/>
							</clipPath>
						</defs>
					</svg>
				</div>
				<div
					style={{ background: '#FFCDE5' }}
					className={clsx(styles.div4, styles.coloredBoxContainer)}>
					<div className={styles.shoulderBrushingWrapper}>
						<Icon name="chaChing" />
					</div>
					<p style={{ color: '#0A0A0E' }} className={styles.percentage}>
						$100m
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						investment led by Sumeru Equity Partners in 2020
					</p>
				</div>

				<div
					style={{ background: '#FAFAFA', position: 'relative' }}
					className={clsx(styles.div5, styles.coloredBoxContainer)}>
					<p className={styles.cardDescription}>
						In 2022,{' '}
						<span style={{ color: '#E21075' }}>
							{' '}
							two of our clients were named gold winners of the MUSE Creative Awards
						</span>
						, which recognize forward-thinking creators.
					</p>
				</div>
				<div
					style={{ background: '#BFF130' }}
					className={clsx(styles.div6, styles.coloredBoxContainer)}>
					<p style={{ color: '#0A0A0E' }} className={styles.percentage}>
						2020
					</p>
					<p className={styles.message} style={{ color: '#0A0A0E' }}>
						Ceros is officially a remote-first company
					</p>
				</div>
			</div>
		</Container>
	)
}
