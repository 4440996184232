import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useState } from 'react'
import type { RevealableMediaContentResult } from '../data/content/RevealableMediaContentFragment'
import type { VideoSelectResult } from '../data/content/VideoSelectFragment'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Icon } from './Icon'
import { VideoModal } from './Modal'
import styles from './RevealableMediaContentMobile.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type RevealableMediaContentProps = RevealableMediaContentResult

export interface Props {
	isVisible: boolean
	onTitleClick: () => void
	media: RevealableMediaContentProps
}

export const RevealableMediaContentMobile: FunctionComponent<Props> = ({
	isVisible,
	onTitleClick,
	media: { title, text, footerInfo, videoSelect },
}) => (
	<div className={styles.wrapper}>
		<span
			className={clsx(styles.titleWrapper, isVisible && styles.is_active)}
			onClick={onTitleClick}>
			<span className={clsx(styles.title, isVisible && styles.is_active)}>
				<CerosRichTextRenderer source={title as string} />
			</span>
		</span>
		{isVisible && (videoSelect || text || footerInfo) && (
			<div className={styles.mediaContent} style={{ display: isVisible ? 'flex' : 'none' }}>
				{videoSelect && <PosterPreview {...videoSelect} />}
				{(text || footerInfo) && (
					<div className={styles.content}>
						<div className={styles.text}>
							<Wysiwyg source={text} />
						</div>
						{footerInfo && (
							<div className={styles.footer} style={{ marginTop: '1rem' }}>
								<Wysiwyg source={footerInfo} />
							</div>
						)}
					</div>
				)}
			</div>
		)}
	</div>
)

type PosterPreviewProps = VideoSelectResult

const PosterPreview: FunctionComponent<PosterPreviewProps> = ({ ...props }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	return (
		<>
			<button
				type="button"
				className={styles.posterPreview}
				onClick={() => setIsModalOpen(true)}
				style={{
					'--RevealableMediaContentItem-poster-width': props.poster?.width,
					'--RevealableMediaContentItem-poster-height': props.poster?.height,
				}}>
				{props.poster && (
					<Image
						className={styles.posterPreviewIn}
						src={props.poster.url}
						fill
						alt={props.poster.alt ?? props.poster.fileName ?? ''}
					/>
				)}
				<div className={styles.playButton}>
					<Icon name="playButton" />
				</div>
			</button>
			<VideoModal
				isOpen={isModalOpen}
				onRequestClose={() => {
					setIsModalOpen(false)
				}}
				video={props}
			/>
		</>
	)
}
