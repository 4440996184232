import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { FormEvent, FunctionComponent, useState } from 'react'
import { useMirrorLoading } from 'shared-loading-indicator'
import {
	handleSubmitHubspotForm,
	HandleSubmitHubspotFormFieldData,
	handleSubmitHubspotFormProps,
} from '../utilities/handleSubmitHubspotForm'
import { Button, ButtonProps } from './Button'
import { HubspotField, HubspotFieldProps } from './HubspotField'
import styles from './HubspotForm.module.sass'

export type HubspotFormProps = {
	formId: string
	portalId: string
	name: string
	fields: HubspotFieldProps[]
	submitButtonLabel?: string
	isButtonFullWidth?: ButtonProps['isFullWidth']
	note?: string
}

type HubspotFormResponseProps = HubspotFormResponseMessageProps | HubspotFormResponseErrorProps

type HubspotFormResponseMessageProps = {
	type: 'message'
	text: string
}

// @TODO: error
type HubspotFormResponseErrorProps = {
	type: 'error'
	error: unknown
}

export const HubspotForm: FunctionComponent<HubspotFormProps> = ({
	formId,
	portalId,
	name,
	fields,
	submitButtonLabel = 'Submit',
	isButtonFullWidth = false,
	note,
}) => {
	const [isAfterSubmitVisible, setAfterSubmitVisible] = useState(false)
	const [hubspotResponseMessage, setHubspotResponseMessage] =
		useState<HubspotFormResponseProps | null>(null)
	const { push } = useRouter()

	const { mutateAsync, isLoading } = useMutation(async (data: handleSubmitHubspotFormProps) => {
		const responseData = await handleSubmitHubspotForm(data)
		return responseData
	})

	useMirrorLoading(isLoading)

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		const { target, currentTarget } = event
		if (!currentTarget || !target) {
			return
		}

		const formData = new FormData(currentTarget)
		const entries = Array.from(formData.entries())
		const formFieldsData: HandleSubmitHubspotFormFieldData[] = entries.map(([key, value]) => ({
			objectTypeId: fields.find((field) => field.name === key)?.objectTypeId || 'unknown',
			name: key,
			value: value,
		}))

		try {
			const data = await mutateAsync({ formId, portalId, formFieldsData })

			if ('redirectUri' in data) {
				push(data.redirectUri)
			} else if ('inlineMessage' in data) {
				setHubspotResponseMessage({
					type: 'message',
					text: data.inlineMessage,
				})
			} else {
				console.error(data.error)
				setHubspotResponseMessage({
					type: 'error',
					error: data.error,
				})
			}
		} catch (error) {
			setHubspotResponseMessage({
				type: 'error',
				error: error,
			})
		}
		setAfterSubmitVisible(true)

		if (target instanceof HTMLFormElement) {
			target.reset()
			setTimeout(() => setAfterSubmitVisible(false), 3000)
		}
	}

	return (
		<form
			className={clsx(styles.wrapper, isLoading && styles.is_loading)}
			title={name}
			onSubmit={handleSubmit}>
			<div className={styles.inputs}>
				{fields.map((field, index) => (
					<HubspotField key={index} {...field} />
				))}
			</div>
			<div className={styles.button}>
				<Button type="submit" fill="blue" isFullWidth={isButtonFullWidth}>
					{submitButtonLabel}
				</Button>
			</div>
			{note && <div className={styles.note} dangerouslySetInnerHTML={{ __html: note }} />}
			{/* @TODO: custom component */}
			{isLoading && (
				<div className={styles.loading}>
					<div className={styles.loadingElement}>
						<div className={styles.loadingElementChild}></div>
						<div className={styles.loadingElementChild}></div>
						<div className={styles.loadingElementChild}></div>
						<div className={styles.loadingElementChild}></div>
					</div>
				</div>
			)}
			<div className={clsx(styles.afterSubmit, isAfterSubmitVisible && styles.is_active)}>
				{hubspotResponseMessage?.type === 'message' ? (
					<div> Hello </div>
				) : (
					<div className={styles.is_error}>Something went wrong. Please try it again.</div>
				)}
			</div>
		</form>
	)
}
