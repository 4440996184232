import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { InspirationTileResult } from '../data/content/InspirationTileFragment'
import styles from './Inspirations.module.sass'
import { InspirationTile, InspirationTileProps } from './InspirationTile'

export type InspirationsProps = {
	featuredItem?: InspirationTileResult
	items: Array<{
		data: InspirationTileProps['data']
	}>
}

export const Inspirations: FunctionComponent<InspirationsProps> = ({ featuredItem, items }) => {
	return (
		<ul className={styles.wrapper}>
			{featuredItem && <Item data={featuredItem} isFeatured />}
			{items.map((item) => (
				<Item key={item.data.id} data={item.data} />
			))}
		</ul>
	)
}

export const Item: FunctionComponent<{ data: InspirationTileResult; isFeatured?: boolean }> = ({
	data,
	isFeatured = false,
}) => {
	return (
		<li key={data.id} className={clsx(styles.item, isFeatured && styles.is_featured)}>
			<InspirationTile data={data} isFeatured={isFeatured} />
		</li>
	)
}
