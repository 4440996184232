import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FancyTitleImageListFragment } from './FancyTitleImageListFragment'

export const FancyTitleFragment = () =>
	ZeusSelect<ValueTypes['FancyTitle']>()({
		text: true,
		imageList: [{}, FancyTitleImageListFragment()],
	})

export type FancyTitleResult = MapType<
	ModelTypes['FancyTitle'],
	ReturnType<typeof FancyTitleFragment>
>
