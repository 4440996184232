import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { FeaturedProductTile } from './FeaturedProductTile'
import { ProductGroup } from './ProductGroup'
import { SelectLink } from './SelectLink'
import { Spacer } from './Spacer'

export type ProductListPageProps = {
	data: NonNullable<CommonPageProps['page']['productListPage']>
}

export const ProductListPage: FunctionComponent<ProductListPageProps> = ({
	data: {
		featuredProduct,
		productGroups: { urlKey, items, filterApplied },
	},
}) => {
	const { query, pathname } = useRouter()
	const currentGroup = items.find((item) => filterApplied && item.items.length > 0)

	return (
		<>
			<SelectLink
				items={[
					{
						label: 'All',
						url: {
							pathname,
							query: {
								...Object.fromEntries(Object.entries(query).filter(([key]) => key !== urlKey)),
							},
						},
					},
					...items.map((item) => ({
						label: item.name ?? '',
						url: {
							pathname,
							query: {
								...query,
								[urlKey]: item.slug,
							},
						},
						isSelected: currentGroup?.id === item.id,
					})),
				]}>
				{filterApplied ? currentGroup?.name : 'All'}
			</SelectLink>
			{featuredProduct && !filterApplied && (
				<>
					<Spacer initial={32} />
					<FeaturedProductTile data={featuredProduct} />
				</>
			)}
			{items.map((data) => data.items.length > 0 && <ProductGroup key={data.id} data={data} />)}
		</>
	)
}
