import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo } from 'react'
import type { InspirationCategoryResult } from '../data/content/InspirationCategoryFragment'
import { lazyBoundary } from '../images/configuration'
import styles from './CategoriesFilter.module.sass'

export type CategoriesFilterProps = {
	data: {
		urlKey: string
		items: InspirationCategoryResult[]
	}
}

export const CategoriesFilter: FunctionComponent<CategoriesFilterProps> = ({
	data: { urlKey, items },
}) => {
	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				{items.map((item) => (
					<Item urlKey={urlKey} item={item} key={item.slug} />
				))}
			</ul>
		</div>
	)
}

const Item: FunctionComponent<{
	urlKey: CategoriesFilterProps['data']['urlKey']
	item: CategoriesFilterProps['data']['items'][number]
}> = ({ urlKey, item }) => {
	const { query, pathname } = useRouter()

	const newQuery = useMemo(
		() =>
			Object.fromEntries(
				Object.entries({
					...query,
					[urlKey]: item.slug === query[urlKey] ? null : item.slug,
				})
					.filter(([_, value]) => value !== null)
					.sort(([a], [b]) => (a > b ? 1 : -1))
			),
		[item.slug, query, urlKey]
	)

	return (
		<li
			key={item.slug}
			className={clsx(styles.item, item.slug === query[urlKey] && styles.is_active)}>
			<Link
				className={styles.itemLink}
				href={{
					pathname,
					query: newQuery,
				}}>
				<div className={styles.itemBackground}>
					{item.thumbnail && (
						<Image
							lazyBoundary={lazyBoundary}
							src={item.thumbnail.url}
							alt={item.thumbnail.alt ?? ''}
							layout="fill"
							objectFit="cover"
						/>
					)}
				</div>
				{item.name}
			</Link>
		</li>
	)
}
