import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { useMeasure } from 'react-use'
import gemmaStaticDemo from '../images/gemma/static-demo.webp'
import { Container } from './Container'
import styles from './GemmaDemoAnimationVideo.module.sass'

export type GemmaDemoAnimationVideoProps = Record<string, never>

export const GemmaDemoAnimationVideo: FunctionComponent<GemmaDemoAnimationVideoProps> = () => {
	const [desktopRef, { width, height }] = useMeasure<HTMLDivElement>()
	const isDesktopVisible = width > 0 || height > 0

	return (
		<div className={styles.wrapper}>
			<Image
				src={gemmaStaticDemo.src}
				width={gemmaStaticDemo.width}
				height={gemmaStaticDemo.height}
				blurDataURL={gemmaStaticDemo.blurDataURL}
				alt=""
				className={styles.mobile}
			/>
			<div className={styles.desktop} ref={desktopRef}>
				<Container size="wide">
					<video
						preload={isDesktopVisible ? 'auto' : 'none'}
						autoPlay={isDesktopVisible}
						muted
						loop
						playsInline
						className={styles.video}>
						<source
							src="https://s3.eu-central-1.amazonaws.com/api.contember.mangoweb.cz/ceros/e45bee92-3723-4888-b6ee-faa3acfde831.mp4"
							type="video/mp4"
						/>
					</video>
				</Container>
			</div>
		</div>
	)
}
