import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { MediumFragment } from './MediumFragment'

export const MediumItemFragment = () =>
	ZeusSelect<ValueTypes['MediumItem']>()({
		id: true,
		medium: [{}, MediumFragment()],
	})

export type MediumItemResult = MapType<
	ModelTypes['MediumItem'],
	ReturnType<typeof MediumItemFragment>
>
