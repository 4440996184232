import type { FunctionComponent } from 'react'
import type { FeatureTileListResult } from '../data/content/FeatureTileListFragment'
import { FeatureTile } from './FeatureTile'
import style from './FeatureTiles.module.sass'

export type FeatureTilesProps = FeatureTileListResult

export const FeatureTiles: FunctionComponent<FeatureTilesProps> = ({ items }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.tiles}>
				{items.map((item) => (
					<div key={item.id} className={style.tile}>
						<FeatureTile {...item.tile} />
					</div>
				))}
			</div>
		</div>
	)
}
