import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const InternalLinkFragment = () =>
	ZeusSelect<ValueTypes['Linkable']>()({
		url: true,
	})

export type InternalLinkResult = MapType<
	ModelTypes['Linkable'],
	ReturnType<typeof InternalLinkFragment>
>
