import type { FunctionComponent } from 'react'
import type { ExtensionItemResult } from '../data/content/ExtensionItemFragment'
import { CardTile } from './CardTile'
import { CardTileContent } from './CardTileContent'
import styles from './ExtensionCardTile.module.sass'

export type ExtensionCardTileProps = ExtensionItemResult

export const ExtensionCardTile: FunctionComponent<ExtensionCardTileProps> = ({
	image,
	text,
	extension,
}) => {
	return (
		<div className={styles.wrapper}>
			<CardTile>
				<CardTileContent
					link={extension?.page?.link}
					image={image}
					title={extension?.page?.activeRevision?.name}
					text={text}
				/>
			</CardTile>
		</div>
	)
}
