import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { LogoFragment } from './LogoFragment'

export const LogoItemFragment = () =>
	ZeusSelect<ValueTypes['LogoItem']>()({
		id: true,
		logoItem: [{}, LogoFragment()],
	})

export type LogoItemResult = MapType<ModelTypes['LogoItem'], ReturnType<typeof LogoItemFragment>>
