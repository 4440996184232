import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import { lazyBoundary } from '../images/configuration'
import { Button } from './Button'
import styles from './FeaturedProductTile.module.sass'
import { Link } from './Link'
import type { ProductListPageProps } from './ProductListPage'

export type FeaturedProductTileProps = {
	data: NonNullable<ProductListPageProps['data']['featuredProduct']>
}

export const FeaturedProductTile: FunctionComponent<FeaturedProductTileProps> = ({
	data: { product, applicationImage, backgroundImage },
}) => {
	return (
		<Link link={product?.link} className={styles.wrapper}>
			<div className={styles.backgroundImage}>
				{backgroundImage && (
					<Image
						lazyBoundary={lazyBoundary}
						src={backgroundImage.url}
						alt={backgroundImage.alt ?? ''}
						layout="fill"
						objectFit="cover"
					/>
				)}
			</div>
			{product && (
				<div className={styles.in}>
					<div className={styles.content}>
						{product.name && <h2 className={styles.name}>{product.name}</h2>}
						{product.description && <div className={styles.description}>{product.description}</div>}
						<Button type="button" size="small" fill="dark">
							Open
						</Button>
					</div>
					<div className={styles.applicationImage}>
						{applicationImage && (
							<Image
								lazyBoundary={lazyBoundary}
								src={applicationImage.url}
								alt={applicationImage.alt ?? ''}
								layout="responsive"
								width={applicationImage.width ?? 1}
								height={applicationImage.height ?? 1}
							/>
						)}
					</div>
				</div>
			)}
		</Link>
	)
}
