import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'

export const PricingFAQTileItemFragment = () =>
	ZeusSelect<ValueTypes['FAQPricingItem']>()({
		id: true,
		tile: [
			{},
			{
				id: true,
				name: true,
				description: true,
			},
		],
	})

export type PricingFAQTileItemResult = MapType<
	ModelTypes['FAQPricingItem'],
	ReturnType<typeof PricingFAQTileItemFragment>
>
