import clsx from 'clsx'

import { Container } from './Container'

import { useWindowSize } from 'react-use'
import styles from './OurValueProps.module.sass'
import { WistiaEmbedPlayer } from './WistiaEmbedPlayer'

interface Props {
	items: any
}

export const OurValuePropsBlock = ({ items }: Props) => {
	const { width } = useWindowSize()

	const getShitDoneVideo = width < 768 ? 'ipdvkm61lg ' : '6tg87hw7c9'
	const aiVideo = width < 768 ? 'noajzp4ywm' : 'we7p42lezk'

	if (!items) return null

	return (
		<Container size="wide">
			<section className={styles.parent}>
				<div className={styles.div1} id={`playOnHover_${getShitDoneVideo}`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId={getShitDoneVideo} wrapper={'video1'} />
					</div>
					<div className={clsx(styles.textContent)} style={{ zIndex: 10 }}>
						<h4 style={{ color: 'white' }}>{items[0].tile.title}</h4>
						<p className={styles.fiftyWidth}>{items[0].tile.text}</p>
					</div>
				</div>
				<div className={styles.div2} id={`playOnHover_emklw7sqjq`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId="emklw7sqjq" wrapper={'video2'} />
					</div>
					<div className={styles.textContent} style={{ zIndex: 10 }}>
						<h4>{items[1].tile.title}</h4>
						<p className={styles.seventyWidth}>{items[1].tile.text}</p>
					</div>
				</div>
				<div className={styles.div3} id={`playOnHover_7he214hglb`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId="7he214hglb" wrapper={'video3'} />
					</div>
					<div className={clsx(styles.textContent)} style={{ zIndex: 10 }}>
						<h4>{items[2].tile.title}</h4>
						<p className={styles.seventyWidth}>{items[2].tile.text}</p>
					</div>
				</div>
				<div className={styles.div4} id={`playOnHover_${aiVideo}`}>
					<div className={styles.videoBackground}>
						<WistiaEmbedPlayer playOnHover videoId={aiVideo} wrapper={'video4'} />
					</div>
					<div className={clsx(styles.textContent)} style={{ zIndex: 10 }}>
						<h4 style={{ color: 'white' }}>{items[3].tile.title}</h4>
						<p className={styles.fiftyWidth}>{items[3].tile.text}</p>
					</div>
				</div>
			</section>
		</Container>
	)
}
