import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { MediumItemFragment } from './MediumItemFragment'

export const MediumListFragment = () =>
	ZeusSelect<ValueTypes['MediumList']>()({
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			MediumItemFragment(),
		],
	})

export type MediumListResult = MapType<
	ModelTypes['MediumList'],
	ReturnType<typeof MediumListFragment>
>
