import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { GridCardListResult } from '../data/content/GridCardListFragment'
import { CardWithIcon, CardWithIconProps } from './CardWithIcon'
import type { GridCardListProps } from './GridCardList'
import styles from './GridCardListIcon.module.sass'

export type GridCardListIconProps = {
	title?: GridCardListProps['title']
	items: CardWithIconProps[]
	maxColumnsInRow?: GridCardListResult['maxColumnsInRow']
}

export const GridCardListIcon: FunctionComponent<GridCardListIconProps> = ({
	title,
	items,
	maxColumnsInRow = 3,
}) => {
	return (
		<div className={styles.wrapper}>
			<div
				className={styles.list}
				style={{ '--GridCardListIcon-list-maxColumnsInRow': maxColumnsInRow }}>
				{title && (
					<div className={styles.item}>
						<h3 className={styles.title}>{title}</h3>
					</div>
				)}
				{items.map((item) => (
					<div
						key={item.id}
						className={clsx(styles.item, item.isMobileHidden && styles.is_mobileHidden)}>
						<CardWithIcon {...item} />
					</div>
				))}
			</div>
		</div>
	)
}
