import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Container } from './Container'
import styles from './GemmaCatchPhrase.module.sass'

export interface GemmaCatchPhraseProps {
	items: Array<{
		isActive: boolean
		title: string
		text: string
	}>
}

export const GemmaCatchPhrase: FunctionComponent<GemmaCatchPhraseProps> = ({ items }) => {
	return (
		<Container size="wide">
			<div className={styles.wrapper}>
				<div className={styles.in}>
					{items.map(({ title, text, isActive }, i) => (
						<div key={i} className={clsx(styles.content, isActive && styles.is_active)}>
							<Content title={title} text={text} />
						</div>
					))}
				</div>
			</div>
		</Container>
	)
}

const Content: FunctionComponent<{
	title: string
	text: string
}> = ({ title, text }) => {
	return (
		<div className={styles.content_in}>
			<h3 className={styles.title}>{title}</h3>
			<p className={styles.text}>
				<span className={styles.text_in}>{text}</span>
			</p>
		</div>
	)
}
