import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'
import { LinkFragment } from './LinkFragment'
import { ResourceCategoryFragment } from './ResourceCategoryFragment'

export const ResourceFragment = () =>
	ZeusSelect<ValueTypes['Resource']>()({
		id: true,
		title: true,
		leadParagraph: true,
		link: [{}, LinkFragment()],
		cardBigImage: [{}, ImageFragment()],
		cardSmallImage: [{}, ImageFragment()],
		categories: [{}, ResourceCategoryFragment()],
	})

export type ResourceResult = MapType<ModelTypes['Resource'], ReturnType<typeof ResourceFragment>>
