import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { Input } from './Input'
import { TextArea } from './TextArea'

export type HubspotFieldProps = NonNullable<
	NonNullable<
		NonNullable<
			NonNullable<CommonPageProps['hubspotForm']['list']>['results'][number]
		>['fieldGroups']
	>[number]['fields']
>[number]

export const HubspotField: FunctionComponent<HubspotFieldProps> = ({
	name,
	fieldType,
	placeholder,
	required,
	hidden,
	// @TODO: validation
	// validation,
}) => {
	let type = fieldType

	switch (fieldType) {
		case 'phone':
			type = 'tel'
			break
		case 'single_line_text':
			type = 'text'
			break
		case 'multi_line_text':
			type = 'textarea'
			break
		default:
			type = 'text'
	}

	return (
		<>
			{type === 'textarea' ? (
				<TextArea
					name={name}
					placeholder={placeholder ?? undefined}
					required={required}
					hidden={hidden}
					compact
				/>
			) : (
				<Input
					name={name}
					type={type}
					placeholder={placeholder ?? undefined}
					required={required}
					hidden={hidden}
					compact
				/>
			)}
		</>
	)
}
