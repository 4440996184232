import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { useEffect, useMemo } from 'react'
import { z } from 'zod'
import { Article } from '../app/components/Article'
import { GenericPage } from '../app/components/GenericPage'
import { InspirationDetail } from '../app/components/InspirationDetail'
import { InspirationGalleryPage } from '../app/components/InspirationGalleryPage'
import { LayoutGeneric } from '../app/components/LayoutGeneric'
import { LayoutWithSidebar } from '../app/components/LayoutWithSidebar'
import { Mixpanel } from '../app/components/Mixpanel'
import { newsPageParameterName } from '../app/components/NewsBlock'
import { OriginalsPage } from '../app/components/OriginalsPage'
import { ProductListPage } from '../app/components/ProductListPage'
import { resourceCategorySlugParameterName } from '../app/components/ResourceGridCardList'
import { Seo } from '../app/components/Seo'
import { ThemeProvider } from '../app/components/ThemeProvider'
import { HubspotFormsProvider } from '../app/contexts/HubspotFormsContext'
import { ArticleFragment } from '../app/data/content/ArticleFragment'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { GenericPageDetailFragment } from '../app/data/content/GenericPageDetailFragment'
import { GenericPageRevisionDetailFragment } from '../app/data/content/GenericPageRevisionFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { HeaderGemmaFragment } from '../app/data/content/HeaderGemmaFragment'
import { ImageFragment } from '../app/data/content/ImageFragment'
import { InspirationCategoryFragment } from '../app/data/content/InspirationCategoryFragment'
import { InspirationDetailFragment } from '../app/data/content/InspirationDetailFragment'
import { InspirationTileFragment } from '../app/data/content/InspirationTileFragment'
import { ProductFragment } from '../app/data/content/ProductFragment'
import { ProductGroupFragment } from '../app/data/content/ProductGroupFragment'
import { RedirectFragment } from '../app/data/content/RedirectFragment'
import { SelfPromotionFragment } from '../app/data/content/SelfPromotionFragment'
import { SeoFragment } from '../app/data/content/SeoFragment'
import { SiteFragment } from '../app/data/content/SiteFragment'
import { contemberLinkToHref } from '../app/utilities/contemberLinkToHref'
import { getHubspotFormList } from '../app/utilities/getHubspotFormList'
import { getPageNumberFromQuery } from '../app/utilities/getPageNumberFromQuery'
import { getStringParameterFromQuery } from '../app/utilities/getStringParameterFromQuery'
import { getTheme } from '../app/utilities/getTheme'
import { multisite } from '../app/utilities/multisite'
import { roundTheCurrentTimeToMinutes } from '../app/utilities/roundTheCurrentTimeToMinutes'
import {
	ArticleStatus,
	InspirationKind,
	NewsItemType,
	One,
	OrderDirection,
	Thunder,
} from '../generated/content'
import {
	RespondWithNotFound,
	RespondWithRedirect,
	serverPropsHandler,
} from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage({
	gemmaHeader,
	header,
	footer,
	page,
	hubspotForm,
	selfPromotion,
	layout,
	theme,
	navigation,
	seo: { canonicalUrl, seo },
}: CommonPageProps) {
	const inspirationLinks = useMemo(
		() => ({
			originalsLink: navigation.originalsPageUrl,
			inspirationGalleryLink: navigation.inspirationGalleryPageUrl,
			educationPageLink: navigation.educationPageUrl,
		}),
		[navigation.educationPageUrl, navigation.inspirationGalleryPageUrl, navigation.originalsPageUrl]
	)
	useEffect(() => {

		// iframe retain UTMs starts
		var current_page_path = window.location.pathname;
		if( current_page_path == '/try-ceros-editor-free/' ) {
			var url_utm_params: any = new URLSearchParams(window.location.search)
			var tmp = document.querySelector(".ceros-experience").src;

			if( url_utm_params && url_utm_params != "" ) {
				document.querySelector(".ceros-experience").src += '/?' + url_utm_params;
			}
		}
		// iframe retain UTMs end

		var singup_buttons = document.querySelectorAll('a')
		singup_buttons.forEach(function (button) {
			button.addEventListener('click', function (event: any) {
				var params: any = new URLSearchParams(window.location.search)
				var page_load_action = true
				if (event.target.onclick) {
					page_load_action = false
				}
				if (page_load_action) {
					var utm_query_string = ''
					for (let each_param of params) {
						each_param = each_param.toString()
						if (each_param) {
							var each_param_array = each_param.split(',')
							if (
								each_param_array[0].indexOf('gad_') > -1 ||
								each_param_array[0].indexOf('hsa_') > -1 ||
								each_param_array[0].indexOf('utm_') > -1 ||
								each_param_array[0].indexOf('gbraid') > -1
							) {
								if (utm_query_string != '') {
									utm_query_string = utm_query_string + '&'
								}
								utm_query_string =
									utm_query_string + each_param_array[0] + '=' + each_param_array[1]
							}
						}
					}
					if (utm_query_string != '') {
						var button_href = button.href
						var new_singup_button = button_href
						if (button_href.includes('?')) {
							new_singup_button = new_singup_button + '&'
						} else {
							var check_ending_slash = button_href.charAt(button_href.length - 1)
							if (check_ending_slash != '/') {
								new_singup_button = new_singup_button + '/'
							}
							new_singup_button = new_singup_button + '?'
						}
						new_singup_button = new_singup_button + utm_query_string
						event.preventDefault()
						// if (button.target != '_blank') {
						window.location.href = new_singup_button
						// } else {
						// window.open(new_singup_button, '_blank')
						// }
					}
				}
			})
		})
	}, [])

	const isDarkMain = Boolean(page.inspirationGalleryPage || page.originalsPage || page.inspiration)
	const hideNavigation = Boolean(
		page.inspiration && page.inspiration.kind === InspirationKind.client
	)

	const content = (
		<>
			{page.genericPage ? (
				<GenericPage {...page.genericPage} />
			) : page.productListPage ? (
				<ProductListPage data={page.productListPage} />
			) : page.inspirationGalleryPage ? (
				<InspirationGalleryPage
					inspirationLinks={inspirationLinks}
					data={page.inspirationGalleryPage}
				/>
			) : page.originalsPage ? (
				<OriginalsPage data={page.originalsPage} inspirationLinks={inspirationLinks} />
			) : page.inspiration ? (
				<InspirationDetail
					data={page.inspiration}
					inspirationGalleryPageUrl={navigation.inspirationGalleryPageUrl}
				/>
			) : page.article ? (
				<Article article={page.article} />
			) : page.educationPage ? (
				// @TODO: education page
				<>Education</>
			) : null}
		</>
	)

	return (
		<>
			<Head>
				<meta name="color-scheme" content={isDarkMain ? 'only dark' : 'only light'} />
			</Head>
			<Seo canonicalUrl={canonicalUrl} seo={seo} />

			<ThemeProvider theme={theme}>
				<Mixpanel />
				<HubspotFormsProvider value={hubspotForm}>
					{layout === 'generic' ? (
						<LayoutGeneric
							gemmaHeader={gemmaHeader}
							header={header}
							footer={footer}
							selfPromotion={selfPromotion}>
							{content}
						</LayoutGeneric>
					) : layout === 'withSidebar' ? (
						<LayoutWithSidebar
							// @TODO: rename "LayoutWithSidebar" to something better?
							navigation={navigation}
							darkMain={isDarkMain}
							hideNavigation={hideNavigation}>
							{content}
						</LayoutWithSidebar>
					) : null}
				</HubspotFormsProvider>
			</ThemeProvider>
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	context.res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=180')
	context.res.setHeader('X-Frame-Options', 'SAMEORIGIN')

	const content = getZeusConnection(Thunder, 'content')

	// remove ?search=params and trailing /
	const url = context.resolvedUrl.replace(/\?.*$/g, '').replace(/(.)\/$/g, '$1')
	const lookupUrl = multisite.linkableUrl(url)

	const currentNewsPage = getPageNumberFromQuery(context.query, newsPageParameterName)
	const referenceResourceCategorySlug = getStringParameterFromQuery(
		context.query,
		resourceCategorySlugParameterName
	)

	const previewGenericPageRevisionId = z.string().uuid().nullish().parse(context.query.preview)

	const dataFromContember = await content.query({
		...SiteFragment(),
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderFragment(),
		],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterFragment(),
		],
		getGenericPageRevision: previewGenericPageRevisionId
			? [
					{
						by: {
							id: previewGenericPageRevisionId,
						},
					},
					GenericPageRevisionDetailFragment(currentNewsPage, referenceResourceCategorySlug),
			  ]
			: undefined,
		getGenericPage: previewGenericPageRevisionId
			? [
					{
						by: {
							revisions: {
								id: previewGenericPageRevisionId,
							},
						},
					},
					GenericPageDetailFragment(currentNewsPage, referenceResourceCategorySlug),
			  ]
			: undefined,
		getLinkable: [
			{
				by: { url: lookupUrl },
				filter: multisite.linkableFilter,
			},
			{
				url: true,
				genericPage: [
					{},
					GenericPageDetailFragment(currentNewsPage, referenceResourceCategorySlug),
				],
				article: [
					{
						filter: {
							and: [
								{
									newsItem: {
										and: [
											{
												type: {
													eq: NewsItemType.article,
												},
											},
											{
												publishedAt: {
													lte: roundTheCurrentTimeToMinutes(),
												},
											},
										],
									},
								},
								{
									status: {
										eq: ArticleStatus.published,
									},
								},
							],
						},
					},
					ArticleFragment(currentNewsPage, referenceResourceCategorySlug),
				],
				productListPage: [
					{},
					{
						featuredProduct: [
							{},
							{
								product: [{}, ProductFragment()],
								applicationImage: [{}, ImageFragment()],
								backgroundImage: [{}, ImageFragment()],
							},
						],
						seo: [{}, SeoFragment()],
					},
				],
				inspirationGalleryPage: [
					{},
					{
						seo: [{}, SeoFragment()],
					},
				],
				originalsPage: [
					{},
					{
						featuredInspiration: [{}, InspirationTileFragment()],
						seo: [{}, SeoFragment()],
					},
				],
				inspiration: [{}, InspirationDetailFragment()],
				educationPage: [
					{},
					{
						seo: [{}, SeoFragment()],
					},
				],
				redirect: [{}, RedirectFragment()],
			},
		],
		listProductGroup: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			ProductGroupFragment(),
		],
		listSelfPromotion: [
			{
				limit: 1,
				orderBy: [{ publishedAt: OrderDirection.descNullsLast }],
				filter: {
					publishedAt: {
						lte: roundTheCurrentTimeToMinutes(),
					},
				},
			},
			SelfPromotionFragment(),
		],
		getHubspotSettings: [
			{
				by: {
					unique: One.One,
				},
			},
			{
				accessToken: true,
				portalId: true,
			},
		],
	})

	const redirectUrl = (() => {
		const target = dataFromContember.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {

		if( redirectUrl != 'editor-test' && redirectUrl != '/editor-test' && redirectUrl != '/editor-test/' ) {
			throw new RespondWithRedirect({ permanent: false, destination: redirectUrl })
		}
	}

	const previewGenericPage =
		dataFromContember.getGenericPageRevision && dataFromContember.getGenericPage
			? {
					...dataFromContember.getGenericPage,
					activeRevision: dataFromContember.getGenericPageRevision,
			  }
			: null

	const genericPage = previewGenericPage ?? dataFromContember.getLinkable?.genericPage ?? null

	const productListPage = await (async () => {
		const initial = dataFromContember.getLinkable?.productListPage
		if (!initial) {
			return null
		}
		const groupUrlKey = 'category'
		const groupFilterSlug = (() => {
			const raw = context.query[groupUrlKey]
			if (typeof raw === 'string') {
				return raw
			}
		})()
		const { listProductGroup } = await content.query({
			listProductGroup: [
				{
					orderBy: [{ order: OrderDirection.asc }],
				},
				ProductGroupFragment(groupFilterSlug),
			],
		})
		return {
			...initial,
			productGroups: {
				urlKey: groupUrlKey,
				filterApplied: groupFilterSlug !== undefined,
				items: listProductGroup,
			},
		}
	})()

	const inspirationGalleryPage = await (async () => {
		const initial = dataFromContember.getLinkable?.inspirationGalleryPage
		if (!initial) {
			return null
		}
		const categoryUrlKey = 'category'
		const industryUrlKey = 'industry'
		const typeUrlKey = 'type'
		const featureUrlKey = 'feature'
		const categoryFilterSlug = context.query[categoryUrlKey]
		const industryFilterSlug = context.query[industryUrlKey]
		const typeFilterSlug = context.query[typeUrlKey]
		const featureFilterSlug = context.query[featureUrlKey]
		const {
			listInspirationCategory,
			listInspirationIndustry,
			listInspirationType,
			listInspirationFeature,
			listInspiration: inspirations,
		} = await content.query({
			listInspirationIndustry: [
				{
					orderBy: [{ order: OrderDirection.asc }],
				},
				{
					slug: true,
					name: true,
				},
			],
			listInspirationType: [
				{
					orderBy: [{ order: OrderDirection.asc }],
				},
				{
					slug: true,
					name: true,
				},
			],
			listInspirationFeature: [
				{
					orderBy: [{ order: OrderDirection.asc }],
				},
				{
					slug: true,
					name: true,
				},
			],
			listInspirationCategory: [
				{
					orderBy: [{ order: OrderDirection.asc }],
				},
				InspirationCategoryFragment(),
			],
			listInspiration: [
				{
					filter: {
						kind: {
							eq: InspirationKind.client,
						},
						...(typeof categoryFilterSlug === 'string'
							? {
									categories: { slug: { eq: categoryFilterSlug } },
							  }
							: {}),
						...(typeof industryFilterSlug === 'string'
							? {
									industries: { slug: { eq: industryFilterSlug } },
							  }
							: {}),
						...(typeof typeFilterSlug === 'string'
							? {
									types: { slug: { eq: typeFilterSlug } },
							  }
							: {}),
						...(typeof featureFilterSlug === 'string'
							? {
									features: { slug: { eq: featureFilterSlug } },
							  }
							: {}),
					},
				},
				InspirationTileFragment(),
			],
		})
		return {
			...initial,
			inspirations,
			categories: {
				urlKey: categoryUrlKey,
				items: listInspirationCategory,
			},
			industries: {
				urlKey: industryUrlKey,
				items: listInspirationIndustry,
			},
			types: {
				urlKey: typeUrlKey,
				items: listInspirationType,
			},
			features: {
				urlKey: featureUrlKey,
				items: listInspirationFeature,
			},
		}
	})()

	const originalsPage = await (async () => {
		const initial = dataFromContember.getLinkable?.originalsPage
		if (!initial) {
			return null
		}
		const { listInspiration: inspirations } = await content.query({
			listInspiration: [
				{
					filter: {
						kind: {
							eq: InspirationKind.originals,
						},
						not: { originalsPageFeatured: { id: { isNull: false } } },
					},
				},
				InspirationTileFragment(),
			],
		})
		return {
			...initial,
			inspirations,
		}
	})()

	const educationPage = dataFromContember.getLinkable?.educationPage ?? null
	const inspiration = dataFromContember.getLinkable?.inspiration ?? null

	const article = dataFromContember.getLinkable?.article ?? null

	const page = {
		genericPage,
		article,
		productListPage,
		inspirationGalleryPage,
		originalsPage,
		educationPage,
		inspiration,
	}

	const currentPage = Object.values(page).find((page) => page !== null)

	const layout = page.genericPage || page.article ? ('generic' as const) : ('withSidebar' as const)

	if (!currentPage) {
		console.log(url)
		console.log(page)
		console.log(dataFromContember.getLinkable)
		throw new RespondWithNotFound('Page not found.')
	}

	if ('activeRevision' in currentPage && !currentPage.activeRevision) {
		throw new RespondWithNotFound('Page is missing active revision.')
	}

	// @TODO: rename "navigation" to smth else for "LayoutWithSidebar"
	const navigation = {
		originalsPageUrl: dataFromContember.getOriginalsPage?.link?.url ?? null,
		productListPageUrl: dataFromContember.getProductListPage?.link?.url ?? null,
		inspirationGalleryPageUrl: dataFromContember.getInspirationGalleryPage?.link?.url ?? null,
		educationPageUrl: dataFromContember.getEducationPage?.link?.url ?? null,
	}

	const canonicalUrl = (() => {
		const url = dataFromContember.getLinkable?.url
		if (!url) {
			return null
		}
		return multisite.realUrl(url, true)
	})()

	// @TODO: refactor - "getHubspotFormList" is called only if the hubspot embed or form is connected to hubspot is presented on page
	let hasHubspot = false
	JSON.stringify(dataFromContember, (key, value) => {
		if (key === 'type' && value === 'embedHubspotForm') {
			hasHubspot = true
		}
		if (key === 'hubspotFormId' && value) {
			hasHubspot = true
		}
		return value
	})
	// @TODO: performance
	const hubspotFormList = await (async () => {
		if (hasHubspot && dataFromContember.getHubspotSettings?.accessToken) {
			try {
				return await getHubspotFormList(dataFromContember.getHubspotSettings.accessToken)
			} catch (error) {
				// @TODO: don't cache the whole page
				console.error(error) // If something goes wrong, we don't want to break the page completely
			}
		}
		return null
	})()

	const theme = getTheme(url)

	const gemmaHeader =
		(await (async () => {
			if (theme.site !== 'gemma') {
				return
			}
			return (
				await content.query({
					getHeaderGemma: [
						{
							by: {
								unique: One.One,
							},
						},
						HeaderGemmaFragment(),
					],
				})
			).getHeaderGemma
		})()) ?? null

	return {
		props: {
			gemmaHeader,
			header: dataFromContember.getHeader,
			footer: dataFromContember.getFooter,
			selfPromotion: dataFromContember.listSelfPromotion[0] ?? null,
			page,
			layout,
			theme,
			navigation,
			hubspotForm: {
				list: hubspotFormList,
				portalId: dataFromContember.getHubspotSettings?.portalId ?? null,
			},
			seo: {
				canonicalUrl,
				seo: {
					...(dataFromContember.getSettings?.seo ?? {}),
					...Object.fromEntries(
						Object.entries(
							'activeRevision' in currentPage && currentPage.activeRevision?.seo
								? currentPage.activeRevision.seo
								: currentPage.seo ?? {}
						).filter(([_, value]) => Boolean(value))
					),
				},
			},
		},
	}
})
