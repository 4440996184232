import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const IconFragment = () =>
	ZeusSelect<ValueTypes['Icon']>()({
		id: true,
		icon: [{}, ImageFragment()],
	})

export type IconResult = MapType<ModelTypes['Icon'], ReturnType<typeof IconFragment>>
