import clsx from 'clsx'
import 'highlight.js/styles/stackoverflow-dark.css'
import dynamic from 'next/dynamic'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import type { HighlightProps } from 'react-highlight'
import { useHydrated } from '../../libs/next/hydratation'
import styles from './CodeSnippet.module.sass'
import { Container } from './Container'

const reactHighlightReadyRef = globalThis as { reactHighlightReady?: boolean }

const ReactHighlight = dynamic(
	() => {
		return import('react-highlight').then((module) => {
			const ReactHighlight = module.default

			const ReactHighlightProxy: FunctionComponent<HighlightProps & { onReady?: () => void }> = (
				props
			) => {
				const { onReady, ...restProps } = props

				reactHighlightReadyRef.reactHighlightReady = true
				useEffect(() => {
					onReady?.()
				}, [onReady])

				return <ReactHighlight {...restProps} />
			}

			return ReactHighlightProxy
		})
	},
	{
		ssr: false,
		loading: () => null,
	}
)

export type CodeSnippetProps = {
	text: string
	programmingLanguage?: string
}

export const CodeSnippet: FunctionComponent<CodeSnippetProps> = ({ text, programmingLanguage }) => {
	const hydrated = useHydrated()
	const [ready, setReady] = useState(reactHighlightReadyRef.reactHighlightReady ?? false)
	const handleReady = useCallback(() => setReady(true), [])
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.main}>
					{hydrated && (
						<ReactHighlight
							className={clsx(styles.code, programmingLanguage)}
							onReady={handleReady}>
							{text}
						</ReactHighlight>
					)}
					{!hydrated ||
						(!ready && (
							<pre>
								<code className={clsx(styles.code, styles.placeholder, programmingLanguage)}>
									{text}
								</code>
							</pre>
						))}
				</div>
			</Container>
		</div>
	)
}
