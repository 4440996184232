import { z } from 'zod'

export const WaitlistFormInputs = () =>
	z.object({
		firstname: z.string(),
		lastname: z.string(),
		email: z.string(),
		company: z.string(),
		jobtitle: z.string(),
	})

export type WaitlistFormInputsResult = z.infer<ReturnType<typeof WaitlistFormInputs>>
