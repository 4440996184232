import type { ParsedUrlQuery } from 'querystring'

export const getStringParameterFromQuery = (query: ParsedUrlQuery, parameterName: string) => {
	const raw = query[parameterName]
	const slug = typeof raw === 'string' && raw
	if (!slug) {
		return null
	}
	return slug
}
