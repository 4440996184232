import clsx from 'clsx'
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import { Icon } from './Icon'
import styles from './Share.module.sass'

export interface ShareProps {
	children?: ReactNode
	shareTitle?: string
}

export const Share: FunctionComponent<ShareProps> = ({ shareTitle, children }) => {
	const [isSupported, setIsSupported] = useState(false)
	useEffect(() => {
		if ('share' in navigator) {
			setIsSupported(true)
		}
	}, [])

	const share = useCallback(async () => {
		try {
			await navigator.share({
				title: shareTitle ?? document.title,
				url: window.location.href, // @TODO: respect canonical meta tag
			})
		} catch (error) {
			console.error(error)
		}
	}, [shareTitle])

	return (
		<div className={clsx(styles.wrapper, isSupported && styles.is_supported)}>
			<button className={styles.action} type="button" disabled={!isSupported} onClick={share}>
				{children}{' '}
				<span className={styles.icon}>
					<Icon name="share" />
				</span>
			</button>
		</div>
	)
}
