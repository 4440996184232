import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent, ReactNode } from 'react'
import { LinkType, VisualLineType } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { lazyBoundary } from '../images/configuration'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import styles from './VisualLine.module.sass'
import { VisualLineImages } from './VisualLineImages'
import { Wysiwyg } from './Wysiwyg'

export type VisualLineProps = {
	lineType: VisualLineType
	title: string
	text?: string
	link?: LinkResult
	images?: MediumListResult
}

export const VisualLine: FunctionComponent<VisualLineProps> = ({
	lineType,
	title,
	text,
	link,
	images,
}) => {
	const firstSectionImageMaxCount =
		lineType === VisualLineType.option1 ? 4 : lineType === VisualLineType.option2 ? 2 : 0

	const secondSectionImageMaxCount =
		lineType === VisualLineType.option1 ? 10 : lineType === VisualLineType.option2 ? 5 : 0
	return (
		<div className={clsx(styles.wrapper, styles[`is_${lineType}`])}>
			<VisualLineContainer type={lineType}>
				<div className={styles.main}>
					<div className={styles.backgroundLine}>
						{lineType === VisualLineType.option1 && (
							<>
								<Image
									lazyBoundary={lazyBoundary}
									src="/visualLine-vertical.svg"
									layout="fill"
									objectFit="cover"
									alt="visual line"
								/>
								{images && (
									<>
										<VisualLineImages
											lineType={lineType}
											items={images.items.slice(0, firstSectionImageMaxCount)}
											section="first"
											deviceVisibility="mobile"
										/>
										<VisualLineImages
											lineType={lineType}
											items={images.items.slice(4, secondSectionImageMaxCount)}
											section="second"
											deviceVisibility="mobile"
										/>
									</>
								)}
							</>
						)}
						{lineType === VisualLineType.option2 && (
							<>
								<div className={styles.is_desktop}>
									<Image
										lazyBoundary={lazyBoundary}
										// @TODO: replace for SVG
										src="/visualLine-horizontal-desktop.png"
										layout="fill"
										objectFit="cover"
										alt="visual line"
									/>
								</div>
								<div className={clsx(styles.is_mobile, styles.is_top)}>
									<Image
										lazyBoundary={lazyBoundary}
										// @TODO: replace for SVG
										src="/visualLine-horizontal-mobile-top.png"
										layout="fill"
										objectFit="cover"
										alt="visual line"
									/>
									{images && (
										<VisualLineImages
											lineType={lineType}
											items={images.items.slice(0, firstSectionImageMaxCount)}
											section="first"
											deviceVisibility="mobile"
										/>
									)}
								</div>
							</>
						)}
					</div>
					{images && (
						<VisualLineImages
							lineType={lineType}
							items={images.items.slice(0, firstSectionImageMaxCount)}
							section="first"
							deviceVisibility="desktop"
						/>
					)}
					<div className={styles.content}>
						<div className={styles.contentIn}>
							<h2 className={styles.title}>{title}</h2>
							<div className={styles.column}>
								{text && (
									<div className={styles.description}>
										<Wysiwyg source={text} />
									</div>
								)}
								{link && (
									<div className={styles.button}>
										<Button
											type="link"
											href={contemberLinkToHref(link)}
											fill="blue"
											target={
												link.type !== LinkType.scrollToTarget && link.isTargetBlank
													? '_blank'
													: undefined
											}>
											{link.title}
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className={clsx(styles.backgroundLine, styles[`is_${lineType}`])}>
						{lineType === VisualLineType.option2 && images && (
							<div className={clsx(styles.is_mobile, styles.is_bottom)}>
								<Image
									lazyBoundary={lazyBoundary}
									// @TODO: replace for SVG
									src="/visualLine-horizontal-mobile-bottom.png"
									layout="fill"
									objectFit="cover"
									alt="visual line"
								/>
								<VisualLineImages
									lineType={lineType}
									items={images.items.slice(firstSectionImageMaxCount, secondSectionImageMaxCount)}
									section="second"
									deviceVisibility="mobile"
								/>
							</div>
						)}
					</div>
					{images && (
						<VisualLineImages
							lineType={lineType}
							items={images.items.slice(firstSectionImageMaxCount, secondSectionImageMaxCount)}
							section="second"
							deviceVisibility="desktop"
						/>
					)}
				</div>
			</VisualLineContainer>
		</div>
	)
}

const VisualLineContainer: FunctionComponent<{
	type: VisualLineProps['lineType']
	children: ReactNode
}> = ({ type, children }) => {
	if (type === VisualLineType.option1) {
		return <Container>{children}</Container>
	} else if (type === VisualLineType.option2) {
		return <>{children}</>
	}

	return <></>
}
