import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo } from 'react'
import styles from './Filter.module.sass'

export type FilterProps = {
	title: string
	data: {
		urlKey: string
		items: Array<{
			slug: string
			name?: string
		}>
	}
	isOpen: boolean
	requestOpen: () => void
	requestClose: () => void
}

export const Filter: FunctionComponent<FilterProps> = ({
	isOpen,
	requestOpen,
	requestClose,
	title,
	data: { urlKey, items },
}) => {
	return (
		<div className={clsx(styles.wrapper, isOpen && styles.is_open)}>
			<button
				onClick={() => {
					if (isOpen) {
						requestClose()
					} else {
						requestOpen()
					}
				}}
				type="button"
				className={styles.label}>
				{title}
			</button>
			<div className={styles.in}>
				<div className={styles.content}>
					<div className={styles.contentIn}>
						<ul className={styles.list}>
							{items.map((item) => (
								<FilterItem urlKey={urlKey} item={item} key={item.slug} />
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

const FilterItem: FunctionComponent<{
	urlKey: FilterProps['data']['urlKey']
	item: FilterProps['data']['items'][number]
}> = ({ urlKey, item }) => {
	const { query, pathname } = useRouter()

	const newQuery = useMemo(
		() =>
			Object.fromEntries(
				Object.entries({
					...query,
					[urlKey]: item.slug === query[urlKey] ? null : item.slug,
				})
					.filter(([_, value]) => value !== null)
					.sort(([a], [b]) => (a > b ? 1 : -1))
			),
		[item.slug, query, urlKey]
	)

	return (
		<li
			key={item.slug}
			className={clsx(styles.item, item.slug === query[urlKey] && styles.is_active)}>
			<Link
				href={{
					pathname,
					query: newQuery,
				}}
				className={styles.itemLink}>
				{item.name}
			</Link>
		</li>
	)
}
