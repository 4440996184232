import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, FunctionComponent } from 'react'
import type { ResourceResult } from '../data/content/ResourceFragment'
import { CardTile } from './CardTile'
import { CardTileContent } from './CardTileContent'
import styles from './ResourceCardTile.module.sass'
import { resourceCategorySlugParameterName } from './ResourceGridCardList'

export type ResourceCardTileProps = ResourceResult

export const ResourceCardTile: FunctionComponent<ResourceCardTileProps> = ({
	title,
	leadParagraph,
	cardSmallImage,
	link,
	categories,
}) => {
	return (
		<div className={styles.wrapper}>
			<CardTile>
				<CardTileContent
					link={link}
					image={cardSmallImage}
					title={title}
					text={leadParagraph}
					subtitleChildren={
						categories.length > 0 ? <ResourceCardTileTags categories={categories} /> : null
					}
				/>
			</CardTile>
		</div>
	)
}

type ResourceCardTileTags = {
	categories: ResourceCardTileProps['categories']
}

export const ResourceCardTileTags: FunctionComponent<ResourceCardTileTags> = ({ categories }) => {
	const { pathname, query } = useRouter()
	return (
		<div className={styles.categoryList}>
			{categories.map((item, index) => (
				<Fragment key={item.id}>
					<Link
						href={{
							pathname,
							query: { ...query, [resourceCategorySlugParameterName]: item.slug },
						}}
						scroll={false}
						className={styles.categoryLink}>
						<span className={styles.categoryName}>{item.name}</span>
					</Link>
					{index + 1 !== categories.length && <span>, </span>}
				</Fragment>
			))}
		</div>
	)
}
