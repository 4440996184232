import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { FormFragment } from './FormFragment'
import { IconFragment } from './IconFragment'
import { ImageFragment } from './ImageFragment'
import { LinkFragment } from './LinkFragment'
import { LinkListFragment } from './LinkListFragment'

export const GridCardListFragment = () =>
	ZeusSelect<ValueTypes['GridCardList']>()({
		type: true,
		maxColumnsInRow: true,
		title: true,
		items: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			{
				id: true,
				isMobileHidden: true,
				iconTitleLayout: true,
				title: true,
				link: [{}, LinkFragment()],
				linkList: [{}, LinkListFragment()],
				form: [{}, FormFragment()],
				rating: [
					{},
					{
						id: true,
						isRating: true,
						type: true,
						percentage: true,
						source: true,
					},
				],
				icon: [{}, IconFragment()],
				text: true,
				image: [{}, ImageFragment()],
				quote: true,
				name: true,
				position: true,
				company: true,
			},
		],
	})

export type GridCardListResult = MapType<
	ModelTypes['GridCardList'],
	ReturnType<typeof GridCardListFragment>
>
