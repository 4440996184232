import type { FunctionComponent } from 'react'
import { LinkType } from '../../generated/content'
import type { PricingPlansResult } from '../data/content/PricingPlansFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import styles from './PricingPlansBlock.module.sass'

export type PricingPlansBlockProps = PricingPlansResult

export const PricingPlansBlock: FunctionComponent<PricingPlansBlockProps> = ({ items }) => {
	const cardColors: Record<any, any> = {
		blue: '#E2F1FD',
		green: '#DFFA9E',
		orange: '#FBE9E7',
	}
	const dividerColors: Record<any, any> = {
		blue: '#99D2FB',
		green: '#BFF130',
		orange: '#FFB59E',
	}
	const buttonColors: Record<any, any> = {
		blue: '#1B00FB',
		green: '#759A0E',
		orange: '#FE5621',
	}

	return (
		<Container size="normal">
			<div className={styles.plansWrapper}>
				{items &&
					items.map((s) => {
						return (
							<div
								key={s.id}
								className={styles.cardContainer}
								style={{ backgroundColor: cardColors[s.backgroundColor as any] }}>
								<div className={styles.contentWrapper}>
									{s.mainTitle && <p className={styles.mainTitle}>{s.mainTitle}</p>}
									{s.title && (
										<p className={styles.title}>
											<CerosRichTextRenderer source={s.title} />
										</p>
									)}
									<div
										className={styles.divider}
										style={{
											backgroundColor: dividerColors[s.backgroundColor as any],
										}}></div>
									{s.checkmarkOne && (
										<span className={styles.listItems}>
											<span className={styles.checkmark}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="15"
													height="11"
													viewBox="0 0 15 11"
													fill="none">
													<path
														d="M5.62419 9.91485L1.07419 5.50719C1.00918 5.44423 0.957615 5.36947 0.922432 5.28719C0.887249 5.20492 0.869141 5.11674 0.869141 5.02768C0.869141 4.93863 0.887249 4.85044 0.922432 4.76817C0.957615 4.68589 1.00918 4.61114 1.07419 4.54817L2.06419 3.58914C2.12919 3.52616 2.20636 3.47621 2.2913 3.44213C2.37623 3.40804 2.46726 3.3905 2.55919 3.3905C2.65112 3.3905 2.74215 3.40804 2.82709 3.44213C2.91202 3.47621 2.98919 3.52616 3.05419 3.58914L6.11919 6.55825L12.6842 0.198635C12.7492 0.135661 12.8264 0.0857057 12.9113 0.0516236C12.9962 0.0175416 13.0873 0 13.1792 0C13.2711 0 13.3622 0.0175416 13.4471 0.0516236C13.532 0.0857057 13.6092 0.135661 13.6742 0.198635L14.6642 1.15766C14.7292 1.22063 14.7808 1.29539 14.816 1.37766C14.8511 1.45994 14.8692 1.54812 14.8692 1.63718C14.8692 1.72623 14.8511 1.81442 14.816 1.89669C14.7808 1.97897 14.7292 2.05372 14.6642 2.11669L6.61419 9.91485C6.54919 9.97782 6.47202 10.0278 6.38709 10.0619C6.30215 10.0959 6.21112 10.1135 6.11919 10.1135C6.02726 10.1135 5.93623 10.0959 5.8513 10.0619C5.76636 10.0278 5.68919 9.97782 5.62419 9.91485Z"
														style={{ fill: buttonColors[s.backgroundColor as any] }}
													/>
												</svg>
											</span>
											{s.checkmarkOne}
										</span>
									)}
									{s.checkmarkTwo && (
										<span className={styles.listItems}>
											<span className={styles.checkmark}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="15"
													height="11"
													viewBox="0 0 15 11"
													fill="none">
													<path
														d="M5.62419 9.91485L1.07419 5.50719C1.00918 5.44423 0.957615 5.36947 0.922432 5.28719C0.887249 5.20492 0.869141 5.11674 0.869141 5.02768C0.869141 4.93863 0.887249 4.85044 0.922432 4.76817C0.957615 4.68589 1.00918 4.61114 1.07419 4.54817L2.06419 3.58914C2.12919 3.52616 2.20636 3.47621 2.2913 3.44213C2.37623 3.40804 2.46726 3.3905 2.55919 3.3905C2.65112 3.3905 2.74215 3.40804 2.82709 3.44213C2.91202 3.47621 2.98919 3.52616 3.05419 3.58914L6.11919 6.55825L12.6842 0.198635C12.7492 0.135661 12.8264 0.0857057 12.9113 0.0516236C12.9962 0.0175416 13.0873 0 13.1792 0C13.2711 0 13.3622 0.0175416 13.4471 0.0516236C13.532 0.0857057 13.6092 0.135661 13.6742 0.198635L14.6642 1.15766C14.7292 1.22063 14.7808 1.29539 14.816 1.37766C14.8511 1.45994 14.8692 1.54812 14.8692 1.63718C14.8692 1.72623 14.8511 1.81442 14.816 1.89669C14.7808 1.97897 14.7292 2.05372 14.6642 2.11669L6.61419 9.91485C6.54919 9.97782 6.47202 10.0278 6.38709 10.0619C6.30215 10.0959 6.21112 10.1135 6.11919 10.1135C6.02726 10.1135 5.93623 10.0959 5.8513 10.0619C5.76636 10.0278 5.68919 9.97782 5.62419 9.91485Z"
														style={{ fill: buttonColors[s.backgroundColor as any] }}
													/>
												</svg>
											</span>
											{s.checkmarkTwo}
										</span>
									)}
									{s.checkmarkThree && (
										<span className={styles.listItems}>
											<span className={styles.checkmark}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="15"
													height="11"
													viewBox="0 0 15 11"
													fill="none">
													<path
														d="M5.62419 9.91485L1.07419 5.50719C1.00918 5.44423 0.957615 5.36947 0.922432 5.28719C0.887249 5.20492 0.869141 5.11674 0.869141 5.02768C0.869141 4.93863 0.887249 4.85044 0.922432 4.76817C0.957615 4.68589 1.00918 4.61114 1.07419 4.54817L2.06419 3.58914C2.12919 3.52616 2.20636 3.47621 2.2913 3.44213C2.37623 3.40804 2.46726 3.3905 2.55919 3.3905C2.65112 3.3905 2.74215 3.40804 2.82709 3.44213C2.91202 3.47621 2.98919 3.52616 3.05419 3.58914L6.11919 6.55825L12.6842 0.198635C12.7492 0.135661 12.8264 0.0857057 12.9113 0.0516236C12.9962 0.0175416 13.0873 0 13.1792 0C13.2711 0 13.3622 0.0175416 13.4471 0.0516236C13.532 0.0857057 13.6092 0.135661 13.6742 0.198635L14.6642 1.15766C14.7292 1.22063 14.7808 1.29539 14.816 1.37766C14.8511 1.45994 14.8692 1.54812 14.8692 1.63718C14.8692 1.72623 14.8511 1.81442 14.816 1.89669C14.7808 1.97897 14.7292 2.05372 14.6642 2.11669L6.61419 9.91485C6.54919 9.97782 6.47202 10.0278 6.38709 10.0619C6.30215 10.0959 6.21112 10.1135 6.11919 10.1135C6.02726 10.1135 5.93623 10.0959 5.8513 10.0619C5.76636 10.0278 5.68919 9.97782 5.62419 9.91485Z"
														style={{ fill: buttonColors[s.backgroundColor as any] }}
													/>
												</svg>
											</span>
											{s.checkmarkThree}
										</span>
									)}
									{s.checkmarkFour && (
										<span className={styles.listItems}>
											<span className={styles.checkmark}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="15"
													height="11"
													viewBox="0 0 15 11"
													fill="none">
													<path
														d="M5.62419 9.91485L1.07419 5.50719C1.00918 5.44423 0.957615 5.36947 0.922432 5.28719C0.887249 5.20492 0.869141 5.11674 0.869141 5.02768C0.869141 4.93863 0.887249 4.85044 0.922432 4.76817C0.957615 4.68589 1.00918 4.61114 1.07419 4.54817L2.06419 3.58914C2.12919 3.52616 2.20636 3.47621 2.2913 3.44213C2.37623 3.40804 2.46726 3.3905 2.55919 3.3905C2.65112 3.3905 2.74215 3.40804 2.82709 3.44213C2.91202 3.47621 2.98919 3.52616 3.05419 3.58914L6.11919 6.55825L12.6842 0.198635C12.7492 0.135661 12.8264 0.0857057 12.9113 0.0516236C12.9962 0.0175416 13.0873 0 13.1792 0C13.2711 0 13.3622 0.0175416 13.4471 0.0516236C13.532 0.0857057 13.6092 0.135661 13.6742 0.198635L14.6642 1.15766C14.7292 1.22063 14.7808 1.29539 14.816 1.37766C14.8511 1.45994 14.8692 1.54812 14.8692 1.63718C14.8692 1.72623 14.8511 1.81442 14.816 1.89669C14.7808 1.97897 14.7292 2.05372 14.6642 2.11669L6.61419 9.91485C6.54919 9.97782 6.47202 10.0278 6.38709 10.0619C6.30215 10.0959 6.21112 10.1135 6.11919 10.1135C6.02726 10.1135 5.93623 10.0959 5.8513 10.0619C5.76636 10.0278 5.68919 9.97782 5.62419 9.91485Z"
														style={{ fill: buttonColors[s.backgroundColor as any] }}
													/>
												</svg>
											</span>
											{s.checkmarkFour}
										</span>
									)}

									{s.link && (
										<div className={styles.buttonWrapper}>
											<Button
												style={{
													width: '100%',
												}}
												type="link"
												href={contemberLinkToHref(s.link)}
												fill="blue"
												target={
													s.link.type !== LinkType.scrollToTarget && s.link.isTargetBlank
														? '_blank'
														: undefined
												}>
												{s.link.title}
											</Button>
										</div>
									)}
									{s.description && <p className={styles.description}>{s.description}</p>}
								</div>
							</div>
						)
					})}
			</div>
		</Container>
	)
}
