import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { ExtensionItemFragment } from './ExtensionItemFragment'

export const ExtensionListFragment = () =>
	ZeusSelect<ValueTypes['ExtensionList']>()({
		items: [{ orderBy: [{ order: OrderDirection.asc }] }, ExtensionItemFragment()],
	})

export type ExtensionListResult = MapType<
	ModelTypes['ExtensionList'],
	ReturnType<typeof ExtensionListFragment>
>
