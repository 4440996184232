import clsx from 'clsx'
import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react'
import { BoxBackgroundColorType, BoxType, LinkType } from '../../generated/content'
import type { BoxResult } from '../data/content/BoxFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './Box.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { LogoList } from './LogoList'

export type BoxProps = {
	title: string
	text?: string
	link?: LinkResult
	box: BoxResult
}

const colors: Record<number, string> = {
	1: '#FE5621',
	2: '#BFF130',
	3: '#C5E7FF',
	4: '#FFCDE5',
	5: '#907EFF',
}

export const Box: FunctionComponent<BoxProps> = ({ title, text, link, box }) => {
	const boxBackgroundColor = box.backgroundColor ?? BoxBackgroundColorType.transparent
	const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)
	const [visibleCharacterCount, setVisibleCharacterCount] = useState(0)
	const highlightedTitle = useMemo(
		() => box.highlightedTitleList.find((_, i) => i === currentPhraseIndex),
		[box.highlightedTitleList, currentPhraseIndex]
	)

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | undefined
		let isPhraseLengthIncrementing = true
		const loop = () => {
			if (isPhraseLengthIncrementing) {
				setVisibleCharacterCount((visibleCharacterCount) => {
					if (visibleCharacterCount - 5 === highlightedTitle?.title?.length) {
						isPhraseLengthIncrementing = false
					}
					return visibleCharacterCount + 1
				})
			} else {
				setVisibleCharacterCount((visibleCharacterCount) => {
					if (visibleCharacterCount === 0) {
						isPhraseLengthIncrementing = true
						setCurrentPhraseIndex((currentPhraseIndex + 1) % box.highlightedTitleList.length)
					}
					return visibleCharacterCount - 1
				})
			}

			timer = setTimeout(
				() => {
					loop()
				},
				isPhraseLengthIncrementing ? 130 : 40
			)
		}
		loop()

		return () => {
			if (timer) {
				clearInterval(timer)
			}
		}
	}, [box.highlightedTitleList.length, currentPhraseIndex, highlightedTitle?.title?.length])

	return (
		<Container size="normal">
			<div
				className={clsx(
					styles.box,
					styles[`is_${boxBackgroundColor}`],
					box.type === BoxType.cropped && styles.boxCropped
				)}>
				<div
					className={clsx(
						styles.titleContainer,
						box.type === BoxType.cropped && styles.titleContainerCropped
					)}>
					<h2 className={clsx(styles.title, box.type === BoxType.cropped && styles.titleCropped)}>
						{/* <BreakableContent breakableFrom="992" text={title} /> */}
						{title} {currentPhraseIndex === 0 && 'are'}
						{/* <BreakableContent breakableFrom="992"> */}
						{box.type === BoxType.cropped && box.highlightedTitleList.length > 0 && (
							<div
								className={styles.highlightedTitles}
								style={{ color: colors[currentPhraseIndex + 1] || 'inherit' }}>
								<span
									className={clsx(styles.highlightedTitle, styles.is_visible)}
									key={currentPhraseIndex}>
									{highlightedTitle?.title?.split('').map((character, index, characters) => (
										<span
											key={index}
											className={clsx(
												styles.character,
												index < visibleCharacterCount && styles.is_visible,
												index + 1 === Math.min(visibleCharacterCount, characters.length) &&
													styles.is_lastVisible
											)}>
											{character}
										</span>
									))}
								</span>
								<div className={styles.highlightedTitlesLayer}>
									{box.highlightedTitleList.map((item) => (
										<span key={item.id} className={styles.highlightedTitle}>
											{item.title}
										</span>
									))}
								</div>
							</div>
						)}
						{/* </BreakableContent> */}
					</h2>
					<div
						className={clsx(styles.textWrapper, box.type === BoxType.cropped && styles.is_cropped)}>
						<p className={styles.text}>{text}</p>
						{link && (
							<div className={styles.button}>
								<Button
									type="link"
									href={contemberLinkToHref(link)}
									fill={
										boxBackgroundColor === BoxBackgroundColorType.transparent ||
										boxBackgroundColor === BoxBackgroundColorType.black
											? 'blue'
											: boxBackgroundColor === BoxBackgroundColorType.blue
											? 'dark'
											: undefined
									}
									target={
										link.type !== LinkType.scrollToTarget && link.isTargetBlank
											? '_blank'
											: undefined
									}>
									{link.title}
								</Button>
							</div>
						)}
					</div>
				</div>
				{box.type === BoxType.cropped && (
					<div className={styles.imageWrapper}>
						{/* <Image lazyBoundary={lazyBoundary} src={wave} layout="responsive" alt="" /> */}
					</div>
				)}
				{box.type === BoxType.image && box.image && (
					<BoxContent>
						<ContemberImage image={box.image} layout="responsive" objectFit="cover" />
					</BoxContent>
				)}
				{box.type === BoxType.imageWithoutBottomSpace && box.image && (
					<BoxContent>
						<div className={styles.imageWithoutBottomSpace}>
							<ContemberImage image={box.image} layout="responsive" objectFit="cover" />
						</div>
					</BoxContent>
				)}
				{box.type === BoxType.imageWithoutSideSpace && box.image && (
					<BoxContent boxType={BoxType.imageWithoutSideSpace}>
						<div className={styles.imageWithoutSideSpace}>
							<ContemberImage image={box.image} layout="responsive" objectFit="cover" />
						</div>
					</BoxContent>
				)}
				{box.type === BoxType.text && (
					<BoxContent>
						<div className={styles.textsWrapper}>
							{box.items.map((item, i) => (
								<div key={i} className={styles.texts}>
									<p className={styles.contentTitle}>{item.title}</p>
									<p className={styles.contentText}>{item.text}</p>
								</div>
							))}
						</div>
					</BoxContent>
				)}
				{box.type === BoxType.icons && box.logoList && (
					<div className={styles.icons}>
						<LogoList
							{...box.logoList}
							maxLogosInRow={box.logoList.maxLogosInRow ?? 7}
							baseSize={70}
							imageMaxArea={50}
							view="box"
						/>
					</div>
				)}
			</div>
		</Container>
	)
}

type BoxContentProps = {
	children: ReactNode
	boxType?: BoxProps['box']['type']
}

const BoxContent: FunctionComponent<BoxContentProps> = ({ children, boxType }) => {
	return (
		<div
			className={styles.content}
			style={{
				'--Box-inner-horizontal-spacing': boxType === BoxType.imageWithoutSideSpace ? 0 : null,
			}}>
			{children}
		</div>
	)
}
