import clsx from 'clsx'
import Image from 'next/legacy/image'
import Script from 'next/script'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { InspirationKind } from '../../generated/content'
import type { InspirationDetailResult } from '../data/content/InspirationDetailFragment'
import { lazyBoundary } from '../images/configuration'
import { iframeSandbox } from '../utilities/iframeSandbox'
import { useAddLike } from '../utilities/useAddLike'
import { useAddView } from '../utilities/useAddViewOrLike'
import { CloseButton } from './CloseButton'
import { Icon } from './Icon'
import styles from './InspirationDetail.module.sass'

export type InspirationDetailProps = {
	data: InspirationDetailResult
	inspirationGalleryPageUrl: string | null
}

export const InspirationDetail: FunctionComponent<InspirationDetailProps> = ({
	inspirationGalleryPageUrl,
	data: {
		title,
		description,
		id,
		frameUrl,
		frameAspectRatio,
		kind,
		categories,
		features,
		industries,
		types,
		views,
		likes,
	},
}) => {
	useAddView(id)
	const { isLoading, addLike } = useAddLike(id)

	const tags = useMemo(
		() => [...categories, ...features, ...industries, ...types],
		[categories, features, industries, types]
	)

	const [optimisticLikes, setOptimisticLikes] = useState(likes)

	useEffect(() => {
		// Reset count on update
		setOptimisticLikes(likes)
	}, [likes])

	const [emulateMobile, setEmulateMobile] = useState(false)

	const currentFrameAspectRatio = useMemo(
		() => (emulateMobile ? undefined : frameAspectRatio),
		[emulateMobile, frameAspectRatio]
	)

	return (
		<div className={styles.wrapper}>
			{kind === InspirationKind.client && (
				<div className={styles.sidebar}>
					{inspirationGalleryPageUrl && (
						<div className={styles.close}>
							<CloseButton type="link" link={inspirationGalleryPageUrl} />
						</div>
					)}
					<div className={styles.header}>
						<h2 className={styles.title}>{title}</h2>
						{description && <div className={styles.lead}>{description}</div>}
						<div className={styles.emulation}>
							<button
								type="button"
								onClick={() => {
									setEmulateMobile(false)
								}}
								aria-label="Desktop"
								className={clsx(styles.emulationOption, !emulateMobile && styles.is_active)}>
								<Icon name="desktop" />
							</button>
							<button
								type="button"
								onClick={() => {
									setEmulateMobile(true)
								}}
								aria-label="Mobile"
								className={clsx(styles.emulationOption, emulateMobile && styles.is_active)}>
								<Icon name="mobile" />
							</button>
						</div>
					</div>

					<div className={styles.stats}>
						<div className={styles.views}>
							<span className={styles.icon}>
								<Icon name="eye" />
							</span>{' '}
							{views}
						</div>
						<button
							type="button"
							className={styles.likes}
							disabled={isLoading}
							onClick={() => {
								setOptimisticLikes(optimisticLikes + 1)
								addLike()
							}}>
							<span className={styles.icon}>
								<Icon name="thumbUp" />
							</span>{' '}
							{optimisticLikes}
						</button>
					</div>
					<div className={styles.more}>
						{tags.length > 0 && (
							<div className={styles.tags}>
								{tags.map(
									(tag) =>
										tag.name && (
											<span className={styles.tag} key={tag.id}>
												{tag.name}
											</span>
										)
								)}
							</div>
						)}
						{description && <div className={styles.description}>{description}</div>}
					</div>
				</div>
			)}
			<div className={clsx(styles.content, emulateMobile && styles.is_emulateMobile)}>
				<div className={styles.contentIn}>
					{emulateMobile && (
						<Image
							lazyBoundary={lazyBoundary}
							src="/phone.png"
							alt=""
							layout="fill"
							objectFit="cover"
						/>
					)}
					{frameUrl && (
						<>
							<iframe
								allowFullScreen
								sandbox={iframeSandbox}
								className={clsx(
									styles.frame,
									currentFrameAspectRatio && styles.is_forceAspectRatio,
									'ceros-experience'
								)}
								src={frameUrl}
								frameBorder="0"
								title={title}
								style={
									currentFrameAspectRatio
										? {
												['--InspirationDetail-aspectRatio']: currentFrameAspectRatio,
										  }
										: undefined
								}
							/>
							<Script
								id="scroll-proxy.min.js"
								src="//view.ceros.com/scroll-proxy.min.js"
								data-ceros-origin-domains="view.ceros.com"
								strategy="afterInteractive"
							/>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
