import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { BackgroundType } from '../../generated/content'
import type { LogoListResult } from '../data/content/LogoListFragment'
import styles from './BannerLogos.module.sass'
import { Container } from './Container'
import { LogoList } from './LogoList'
import { Wysiwyg } from './Wysiwyg'

export type BannerLogosProps = {
	title?: string
	subtitle?: string
	logoList: LogoListResult
	background?: BackgroundType
}

export const BannerLogos: FunctionComponent<BannerLogosProps> = ({
	title,
	subtitle,
	logoList,
	background,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={clsx(styles.main, styles[`is_background_${background}`])}>
					<div className={styles.content}>
						{title && <h2 className={styles.title}>{title}</h2>}
						{subtitle && <Wysiwyg source={subtitle} />}
					</div>
					<div className={styles.logos}>
						<LogoList
							{...logoList}
							maxLogosInRow={logoList.maxLogosInRow ?? 5}
							baseSize={90}
							imageMaxArea={80}
							view="bannerLogos"
						/>
					</div>
				</div>
			</Container>
		</div>
	)
}
