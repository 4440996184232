import type { FunctionComponent } from 'react'
import type { ContentReferenceResourceResult } from '../data/content/ContentReferenceResourceFragment'
import { Container } from './Container'
import { ResourceCardTile } from './ResourceCardTile'
import styles from './ResourceGridCardList.module.sass'
import {
	ResourceGridCardListCategories,
	ResourceGridCardListCategoriesProps,
} from './ResourceGridCardListCategories'

export type ResourceGridCardListProps = {
	items: ContentReferenceResourceResult[]
} & ResourceGridCardListCategoriesProps

export const resourceCategorySlugParameterName = 'category'

export const ResourceGridCardList: FunctionComponent<ResourceGridCardListProps> = ({
	items,
	categories,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.filters}>
					<ResourceGridCardListCategories categories={categories} />
				</div>
				<div className={styles.list}>
					{items.map(
						(item) =>
							item.item && (
								<div key={item.id} className={styles.item}>
									<ResourceCardTile {...item.item} />
								</div>
							)
					)}
				</div>
			</Container>
		</div>
	)
}
