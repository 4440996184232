import clsx from 'clsx'
import Image from 'next/image'
import { forwardRef, useEffect, useState } from 'react'
import styles from './ImageBoxPlaceholder.module.sass'

export interface ImageBoxPlaceholderProps {
	imageSrc?: string | null
}

export const ImageBoxPlaceholder = forwardRef<HTMLDivElement, ImageBoxPlaceholderProps>(
	({ imageSrc = null }, ref) => {
		const [lastKnownImageSrc, setLastKnownImageSrc] = useState(imageSrc)
		const isImageVisibile = imageSrc !== null

		useEffect(() => {
			if (imageSrc !== null) {
				setLastKnownImageSrc(imageSrc)
			}
		}, [imageSrc])

		return (
			<div ref={ref} className={clsx(styles.wrapper, isImageVisibile && styles.is_imageVisibile)}>
				<svg
					className={styles.cross}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100"
					preserveAspectRatio="none">
					<line x1="0" y1="0" x2="100" y2="100" vectorEffect="non-scaling-stroke" />
					<line x1="0" y1="100" x2="100" y2="0" vectorEffect="non-scaling-stroke" />
				</svg>
				<div className={styles.image}>
					{lastKnownImageSrc && (
						<Image className={styles.image_in} src={lastKnownImageSrc} fill alt="" />
					)}
				</div>
			</div>
		)
	}
)

ImageBoxPlaceholder.displayName = 'ImageBoxPlaceholder'
